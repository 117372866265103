
import { defineComponent, reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons-vue';
import { event_obj } from '@/components/page-model2/cerp/cerp-invoice-config.ts';
import { useForm } from 'ant-design-vue/es/form';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import {
  create_customer,
  create_receipt,
  query_customer_list,
  query_receipt_contract_list,
  query_receipt_template_list,
  update_receipt,
} from '@/api/receipt-model';
import {
  get_cc_bank_account,
  query_c_c_email_list,
  query_cc_company_list,
  query_recuitment_company_list,
} from '@/api/baseinfo-model';
import { query_adviser_list } from '@/api/cerp-model';
import modalBox from '@/components/form-modal/modal-tools';

export default defineComponent({
  name: 'ReceiptModel',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const { t } = useI18n();
    const customfield_num: any = ref(0);
    const service_month: any = ref(null);
    const template_data_list: Array<any> = reactive([]);

    if (props.type == 'update') {
      customfield_num.value = props.obj.receipt_template__customfield_num;
      service_month.value = moment(props.obj.service_month).format('YYYY-MM');
      Object.keys(props.obj.data).map((key: string) => {
        template_data_list.push({
          name: key,
          value: props.obj.data[key],
        });
      });
    }
    const confirmLoading = ref<boolean>(false);
    const modal: any = reactive({
      org_id: current_org?.id,
      is_active: true,
      cc_company_id:
        props.type == 'update' ? Number(props.obj.cc_company_id) : Number(props.obj.cc_company_id),
      receipt_id: props.type == 'update' ? props.obj.id : null,
      receipt_type_id: props.type == 'update' ? props.obj.receipt_type_id : null,
      receipt_contract_id: props.type == 'update' ? props.obj.receipt_contract_id : props.obj.id,
      customer_id: props.type == 'update' ? props.obj.customer_id : props.obj.customer_id,
      receipt_kind: props.type == 'update' ? props.obj.receipt_kind : 1,
      receipt_date: props.type == 'update' ? props.obj.receipt_date : moment().format('YYYY-MM-DD'),
      receipt_template_id: props.type == 'update' ? props.obj.receipt_template_id : null,
      service_month:
        props.type == 'update' ? props.obj.service_month : moment().format('YYYY-MM-DD'),
      currency_id: props.type == 'update' ? props.obj.currency_id : null,
      data: props.type == 'update' ? template_data_list : null,
      cc_email_id: props.type == 'update' ? props.obj.cc_email_id : null,
      remark: props.type == 'update' ? props.obj.remark : null,
      receipt_email_template_id: props.type == 'update' ? props.obj.receipt_email_template_id : 29,
      cc_bank_account_id:
        props.type == 'update' ? props.obj.cc_bank_account_id : props.obj.cc_bank_account_id,
    });
    const modifyModal = reactive({
      // title: props.title,
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [9, 8],
        },
        fields: [
          {
            type: 'remoteselect',
            name: 'customer_id',
            label: '客户',
            label_i18n: t('客户'),
            disabled: false,
            placeholder: '请选择客户',
            placeholder_i18n: t('请选择客户'),
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            labelKeyList: ['adviser__employee_number', 'name'],
            labelSpace: '#',
            valueKey: 'id',
            modalType: 'receipt-query_customer_list',
            init_model: { pageSize: 5000 },
            cb: () => {
              const p = new Promise(function (resolve) {
                const is_adviser: any = ref(true);
                const is_recuitment_company: any = ref(true);
                const is_cc_company: any = ref(true);
                const editModal = reactive({
                  visible: true,
                  title: t('新建客户'),
                  loading: false,
                  form: {
                    settings: {
                      labelAlign: 'right',
                      layout: 'vertical',
                      col: [6, 6],
                    },
                    fields: [
                      {
                        type: 'select',
                        name: 'customer_type',
                        label: '客户类型',
                        label_i18n: t('客户类型'),
                        placeholder: '请选择客户类型',
                        placeholder_i18n: t('请选择客户类型'),
                        mode: 'default',
                        disabled: false,
                        defaultValue: '',
                        labelKey: 'name',
                        valueKey: 'value',
                        datasource: 'customer_type',
                      },
                      {
                        type: 'remotemodal',
                        name: 'adviser_id',
                        label: '隶属顾问',
                        label_i18n: t('隶属顾问'),
                        disabled: is_adviser,
                        defaultValue: '',
                        labelKey: 'full_name',
                        valueKey: 'id',
                        placeholder: '隶属顾问',
                        show_search: false,
                        maxTagCount: 5,
                        mode: 'default',
                        title: t('选择隶属顾问'),
                        modalType: 'cerp-adviser',
                      },
                      {
                        type: 'remoteselect',
                        name: 'recuitment_company_id',
                        label: '顾问公司',
                        label_i18n: t('顾问公司'),
                        disabled: is_recuitment_company,
                        placeholder: '请选择顾问公司',
                        placeholder_i18n: t('请选择顾问公司'),
                        datasourceType: 'remote',
                        mode: 'default',
                        allowClear: true,
                        labelKey: 'company_name',
                        valueKey: 'id',
                        modalType: 'baseinfo-recuitmentcompany',
                      },
                      {
                        type: 'remoteselect',
                        name: 'cc_company_id',
                        label: 'CC公司',
                        label_i18n: t('CC公司'),
                        disabled: is_cc_company,
                        placeholder: '请选择CC公司',
                        placeholder_i18n: t('请选择CC公司'),
                        datasourceType: 'remote',
                        mode: 'default',
                        allowClear: true,
                        modalType: 'baseinfo-cccompany',
                      },
                      {
                        type: 'input',
                        name: 'name',
                        label: '客户名称',
                        label_i18n: t('客户名称'),
                        disabled: false,
                        placeholder: '请填写客户名称',
                        placeholder_i18n: t('请填写客户名称'),
                        datasourceType: 'remote',
                        allowClear: true,
                      },
                      // {
                      //   type: 'attributetable',
                      //   name: 'data',
                      //   label: '额外数据',
                      //   disabled: false,
                      //   allowClear: true,
                      //   inputType: 'text',
                      //   defaultValue: '',
                      //   placeholder: '请填写额外数据',
                      // },
                      {
                        type: 'radio',
                        name: 'customer_attr',
                        label: '客户属性',
                        label_i18n: t('客户属性'),
                        placeholder: '请选择客户属性',
                        placeholder_i18n: t('请选择客户属性'),
                        mode: 'default',
                        disabled: false,
                        defaultValue: '',
                        labelKey: 'name',
                        valueKey: 'value',
                        datasource: 'customer_attr',
                      },
                      {
                        type: 'input',
                        name: 'address',
                        label: '地址',
                        label_i18n: t('地址'),
                        disabled: false,
                        placeholder: '请填写地址',
                        placeholder_i18n: t('请填写地址'),
                        datasourceType: 'remote',
                        allowClear: true,
                      },
                      {
                        type: 'input',
                        name: 'phone',
                        label: '电话',
                        label_i18n: t('电话'),
                        disabled: false,
                        placeholder: '请填写电话',
                        placeholder_i18n: t('请填写电话'),
                        datasourceType: 'remote',
                        allowClear: true,
                      },
                      {
                        type: 'input',
                        name: 'email',
                        label: '邮箱',
                        label_i18n: t('邮箱'),
                        disabled: false,
                        placeholder: '请填写邮箱',
                        placeholder_i18n: t('请填写邮箱'),
                        datasourceType: 'remote',
                        allowClear: true,
                      },
                      {
                        type: 'remoteselect',
                        name: 'payment_email_template_id',
                        label: '付款凭证邮件模板',
                        label_i18n: t('付款凭证邮件模板'),
                        disabled: false,
                        placeholder: '请选择付款凭证邮件模板',
                        placeholder_i18n: t('请选择付款凭证邮件模板'),
                        datasourceType: 'remote',
                        mode: 'default',
                        allowClear: true,
                        modalType: 'baseinfo-emailtemplate',
                      },
                      {
                        type: 'radio',
                        name: 'is_auto_send',
                        label: '是否自动发送付款凭证',
                        label_i18n: t('是否自动发送付款凭证'),
                        mode: 'default',
                        disabled: false,
                        defaultValue: '',
                        labelKey: 'name',
                        valueKey: 'value',
                        placeholder: '请选择是否自动发送付款凭证',
                        placeholder_i18n: t('请选择是否自动发送付款凭证'),
                        datasource: 'is_auto_send',
                      },
                      {
                        type: 'textarea',
                        name: 'remark',
                        label: '备注',
                        label_i18n: t('备注'),
                        disabled: false,
                        allowClear: true,
                        inputType: 'text',
                        maxlength: 200,
                        defaultValue: '',
                        placeholder: '请填写备注',
                        placeholder_i18n: t('请填写备注'),
                      },
                    ],
                    rules: {
                      name: [{ required: true, message: t('请填写客户名称') }],
                    },
                    model: reactive({
                      org_id: current_org?.id,
                      customer_type: null,
                      adviser_id: null,
                      recuitment_company_id: null,
                      cc_company_id: null,
                      name: null,
                      address: null,
                      phone: null,
                      email: null,
                      data: null,
                      mark: null,
                      customer_attr: null,
                      payment_email_template_id: null,
                      is_auto_send: true,
                    }),
                    watch: {
                      adviser_id: (data1: any) => {
                        if (data1.adviser_id) {
                          query_adviser_list({
                            org_id: current_org?.id,
                            ids: data1.adviser_id,
                          }).then((res: any) => {
                            if (res && res.data && res.data.length > 0) {
                              data1.address = res.data[0].work_address__display_name
                                ? res.data[0].work_address__display_name
                                : res.data[0].home_address__display_name;
                              data1.name = res.data[0].full_name;
                              data1.phone = res.data[0].phone;
                              data1.email = res.data[0].personal_email;
                            }
                          });
                        }
                      },
                      recuitment_company_id: (data1: any) => {
                        if (data1.recuitment_company_id) {
                          query_recuitment_company_list({
                            org_id: current_org?.id,
                            ids: data1.recuitment_company_id,
                          }).then((res: any) => {
                            if (res && res.data && res.data.length > 0) {
                              data1.address = res.data[0].address;
                              data1.name = res.data[0].company_name;
                            }
                          });
                        }
                      },
                      cc_company_id: (data1: any) => {
                        if (data1.cc_company_id) {
                          query_cc_company_list({
                            org_id: current_org?.id,
                            ids: data1.cc_company_id,
                          }).then((res: any) => {
                            if (res && res.data && res.data.length > 0) {
                              data1.address = res.data[0].address;
                              data1.name = res.data[0].name;
                            }
                          });
                        }
                      },
                      customer_type: (data1: any) => {
                        data1.cc_company_id = null;
                        data1.recuitment_company_id = null;
                        data1.adviser_id = null;
                        data1.address = null;
                        data1.phone = null;
                        data1.email = null;
                        data1.name = null;
                        if (data1.customer_type == 0) {
                          is_adviser.value = false;
                          is_recuitment_company.value = true;
                          is_cc_company.value = true;
                          data1.customer_attr = 0;
                        } else if (data1.customer_type == 1) {
                          is_adviser.value = true;
                          is_recuitment_company.value = false;
                          is_cc_company.value = true;
                          data1.customer_attr = 1;
                        } else if (data1.customer_type == 2) {
                          is_adviser.value = true;
                          is_recuitment_company.value = true;
                          is_cc_company.value = false;
                          data1.customer_attr = 1;
                        } else if (data1.customer_type == 3) {
                          is_adviser.value = true;
                          is_recuitment_company.value = true;
                          is_cc_company.value = true;
                          data1.customer_attr = 1;
                        }
                      },
                    },
                  },
                  options: {
                    customer_type: [
                      { value: 0, name: t('顾问') },
                      { value: 1, name: t('顾问公司') },
                      { value: 2, name: t('CC公司') },
                      { value: 3, name: t('其他') },
                    ],
                    customer_attr: [
                      { value: 0, name: t('个人') },
                      { value: 1, name: t('公司') },
                    ],
                    is_auto_send: [
                      { value: true, name: t('是') },
                      { value: false, name: t('否') },
                    ],
                  },
                  ok: (payload: any) => {
                    return new Promise(resolve1 => {
                      editModal.loading = true;
                      create_customer({
                        ...payload,
                      })
                        .then((res: any) => {
                          message.success(t('新建成功'));
                          resolve(res.id as number);
                          resolve1(null);
                        })
                        .finally(() => {
                          editModal.loading = false;
                        });
                    });
                  },
                });
                modalBox(editModal);
              });
              return p;
            },
          },
          {
            type: 'remoteselect',
            name: 'cc_company_id',
            label: 'CC公司',
            label_i18n: t('CC公司'),
            placeholder_i18n: t('请选择CC公司'),
            disabled: false,
            placeholder: '请选择CC公司',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-cccompany',
          },
          {
            type: 'remoteselect',
            name: 'receipt_type_id',
            label: '票据种类',
            label_i18n: t('票据种类'),
            disabled: false,
            placeholder: '请选择票据种类',
            placeholder_i18n: t('请选择票据种类'),
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            labelKey: 'name',
            valueKey: 'id',
            modalType: 'receipt-query_receipt_type_list',
          },
          {
            type: 'remoteselect',
            name: 'receipt_contract_id',
            label: '票据合同',
            label_i18n: t('票据合同'),
            disabled: false,
            placeholder: '请选择票据合同',
            placeholder_i18n: t('请选择票据合同'),
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            labelKeyList: [
              'start_date',
              'end_date',
              'customer__name',
              'customer__adviser__employee_number',
            ],
            labelSpace: '_',
            valueKey: 'id',
            modalType: 'receipt-query_receipt_contract_list1',
          },
          {
            type: 'remoteselect',
            name: 'currency_id',
            labelKeyList: ['code', 'currency'],
            label: '币种',
            label_i18n: t('币种'),
            disabled: false,
            placeholder: '请选择币种',
            placeholder_i18n: t('请选择币种'),
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-country',
          },
          {
            type: 'remoteselect',
            name: 'receipt_template_id',
            label: '票据模板',
            label_i18n: t('票据模板'),
            disabled: false,
            placeholder: '请选择票据模板',
            placeholder_i18n: t('请选择票据模板'),
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            labelKey: 'name',
            valueKey: 'id',
            modalType: 'receipt-query_receipt_template_list',
          },
          {
            type: 'remoteselect',
            name: 'receipt_email_template_id',
            label: '发票邮件模板',
            label_i18n: t('发票邮件模板'),
            disabled: false,
            placeholder: '请选择发票邮件模板',
            placeholder_i18n: t('请选择发票邮件模板'),
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-emailtemplate',
          },
          {
            type: 'remoteselect',
            name: 'cc_email_id',
            label: 'CC邮箱',
            label_i18n: t('CC邮箱'),
            disabled: false,
            placeholder: '请选择CC邮箱',
            placeholder_i18n: t('请选择CC邮箱'),
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-ccemail',
          },
          {
            type: 'textarea',
            name: 'remark',
            label: '备注',
            label_i18n: t('备注'),
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写备注',
            placeholder_i18n: t('请填写备注'),
          },
          {
            type: 'radio',
            name: 'receipt_kind',
            label: '发票类型',
            label_i18n: t('发票类型'),
            mode: 'default',
            disabled: false,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'receipt_kind',
          },
          {
            type: 'datepicker',
            name: 'receipt_date',
            label: '开票日期',
            label_i18n: t('开票日期'),
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            placeholder: '请选择开票日期',
            placeholder_i18n: t('请选择开票日期'),
          },
          {
            type: 'monthpicker',
            name: 'service_month',
            label: '服务月份',
            label_i18n: t('服务月份'),
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            format: 'YYYY-MM',
            placeholder: '请选择服务月份',
            placeholder_i18n: t('请选择服务月份'),
          },
          {
            type: 'remoteselect',
            name: 'cc_bank_account_id',
            label: 'CC银行账户',
            label_i18n: t('CC银行账户'),
            placeholder_i18n: t('请选择CC银行账户'),
            disabled: false,
            placeholder: '请选择CC银行账户',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            labelKey: 'account_flag',
            valueKey: 'id',
            modalType: 'baseinfo-ccbankaccountscc',
          },
          {
            type: 'attributetable',
            name: 'data',
            label: '发票数据',
            label_i18n: t('发票数据'),
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写发票数据',
            placeholder_i18n: t('请填写发票数据'),
          },
        ],
        rules: {
          receipt_date: [{ required: true, message: t('请选择开票日期') }],
          customer_id: [{ required: true, message: t('请选择客户') }],
          receipt_type_id: [{ required: true, message: t('请选择票据种类') }],
          // receipt_template_id: [{ required: true, message: t('请选择票据模板') }],
          cc_bank_account_id: [{ required: true, message: t('请选择CC银行账户') }],
          cc_company_id: [{ required: true, message: t('请选择CC公司') }],
          service_month: [{ required: true, message: t('请选择服务月份') }],
          currency_id: [{ required: true, message: t('请选择币种') }],
        },
        model: modal,
        watch: {
          receipt_template_id: (data1: any) => {
            if (data1.receipt_template_id) {
              data1.data = [];
              query_receipt_template_list({
                org_id: current_org?.id,
                ids: data1.receipt_template_id,
              }).then((res1: any) => {
                if (res1 && res1.data && res1.data.length > 0) {
                  customfield_num.value = res1.data[0].customfield_num;
                  if (res1.data[0].data && res1.data[0].data.length > 0) {
                    res1.data[0].data.forEach((item: any) => {
                      data1.data.push({
                        name: item.value,
                        value: null,
                      });
                    });
                  }
                }
              });
            }
          },
          receipt_contract_id: (data1: any) => {
            if (data1.receipt_contract_id) {
              query_receipt_contract_list({
                org_id: current_org?.id,
                ids: data1.receipt_contract_id,
              }).then((res1: any) => {
                if (res1 && res1.data && res1.data.length > 0) {
                  if (res1.data[0].cc_bank_account_id) {
                    data1.cc_bank_account_id = res1.data[0].cc_bank_account_id;
                  }
                }
              });
            }
          },
          customer_id: (data1: any) => {
            data1.receipt_contract_id = null;
            if (data1.customer_id) {
              data1.currency_id = null;
              query_customer_list({
                org_id: current_org?.id,
                ids: data1.customer_id,
              }).then((res1: any) => {
                if (res1 && res1.data && res1.data.length > 0) {
                  if (
                    res1.data[0].customer_type == 0 &&
                    res1.data[0].customer_attr == 0 &&
                    res1.data[0].contract__currency_id != null
                  ) {
                    data1.currency_id = res1.data[0].contract__currency_id;
                  }
                }
              });
            }
          },
          service_month: (data1: any) => {
            if (data1.service_month) {
              service_month.value = moment(data1.service_month).format('YYYY-MM');
            }
          },
          cc_company_id: (data1: any) => {
            if (data1.cc_company_id) {
              query_c_c_email_list({
                org_id: current_org?.id,
                cc_company_id: data1.cc_company_id,
              }).then((res1: any) => {
                if (res1 && res1.data && res1.data.length > 0) {
                  if (res1.data[0].id) {
                    data1.cc_email_id = res1.data[0].id;
                  }
                }
              });
            }
            if (data1.cc_company_id && data1.currency_id) {
              get_cc_bank_account({
                org_id: current_org?.id,
                cc_company_id: data1.cc_company_id,
                currency_id: data1.currency_id,
              }).then((res1: any) => {
                if (res1) {
                  if (res1.cc_bank_account_id) {
                    data1.cc_bank_account_id = res1.cc_bank_account_id;
                  }
                }
              });
            }
          },
          currency_id: (data1: any) => {
            if (data1.cc_company_id && data1.currency_id) {
              get_cc_bank_account({
                org_id: current_org?.id,
                cc_company_id: data1.cc_company_id,
                currency_id: data1.currency_id,
              }).then((res1: any) => {
                if (res1) {
                  if (res1.cc_bank_account_id) {
                    data1.cc_bank_account_id = res1.cc_bank_account_id;
                  }
                }
              });
            }
          },
        },
      },
      options: {
        receipt_kind: [
          { value: 0, name: t('收取发票') },
          { value: 1, name: t('开具发票') },
        ],
      },
    });
    const { resetFields, validate, validateInfos } = useForm(modal, modifyModal.form.rules);
    if (props.type != 'update') {
      if (props.obj.customer_id) {
        modal.currency_id = null;
        query_customer_list({
          org_id: current_org?.id,
          ids: props.obj.customer_id,
        }).then((res1: any) => {
          if (res1 && res1.data && res1.data.length > 0) {
            if (
              res1.data[0].customer_type == 0 &&
              res1.data[0].customer_attr == 0 &&
              res1.data[0].contract__currency_id != null
            ) {
              modal.currency_id = res1.data[0].contract__currency_id;
            }
          }
        });
      }
    }
    const handleCancel = () => {
      emit('cancel');
    };
    const handleOk = () => {
      validate().then(() => {
        const data: any = {};
        if (modal.data && modal.data.length > 0) {
          modal.data.forEach((item: any) => {
            data[item.name] = item.value;
          });
        }
        if (props.type == 'update') {
          confirmLoading.value = true;
          return new Promise(function (resolve) {
            update_receipt({
              ...modifyModal.form.model,
              data: JSON.stringify(data),
            })
              .then((res: any) => {
                if (res.message) {
                  message.error(res.message);
                } else {
                  message.success(t('修改成功'));
                }
                emit('ok');
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                confirmLoading.value = false;
              });
          });
        } else {
          confirmLoading.value = true;
          return new Promise(function (resolve) {
            create_receipt({
              ...modifyModal.form.model,
              data: JSON.stringify(data),
            })
              .then((res: any) => {
                if (res.message) {
                  message.error(res.message);
                } else {
                  message.success(t('创建成功'));
                }
                resolve(null);
                const modifyModal1: any = reactive({
                  visible: false,
                  title: t('新增发票信息'),
                  type: 'create',
                  component: 'UpdateReceiptModal',
                  obj: {
                    ...res,
                  },
                });
                modifyModal1.visible = true;
                modifyModal1.ok = () => {
                  emit('ok');
                };
                modifyModal1.cancel = () => {
                  emit('cancel');
                };
                return new Promise(resolve1 => {
                  modalBox(modifyModal1);
                  resolve1(null);
                });
              })
              .finally(() => {
                confirmLoading.value = false;
              });
          });
        }
      });
    };
    return {
      message,
      t,
      modifyModal,
      confirmLoading,
      validateInfos,
      handleCancel,
      handleOk,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
  },
});
