export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password（admin/ant.design)',
  'pages.login.username.placeholder': 'Username: admin or user',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password: ant.design',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.welcome.advancedComponent': 'Advanced Component',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.advancedLayout': 'Advanced Layout',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
  'pages.searchTable.createForm.newRule': 'New Rule',
  'pages.searchTable.updateForm.ruleConfig': 'Rule configuration',
  'pages.searchTable.updateForm.basicConfig': 'Basic Information',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Rule Name',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Please enter the rule name!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Rule Description',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Please enter at least five characters',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Please enter a rule description of at least five characters!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configure Properties',
  'pages.searchTable.updateForm.object': 'Monitoring Object',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Rule Template',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Rule Type',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Set Scheduling Period',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Starting Time',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Please choose a start time!',
  'pages.searchTable.titleDesc': 'Description',
  'pages.searchTable.ruleName': 'Rule name is required',
  'pages.searchTable.titleCallNo': 'Number of Service Calls',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'running',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Last Scheduled at',
  'pages.searchTable.exception': 'Please enter the reason for the exception!',
  'pages.searchTable.titleOption': 'Option',
  'pages.searchTable.config': 'Configuration',
  'pages.searchTable.subscribeAlert': 'Subscribe to alerts',
  'pages.searchTable.title': 'Enquiry Form',
  'pages.searchTable.new': 'New',
  'pages.searchTable.chosen': 'chosen',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Total Number of Service Calls',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'bacth deletion',
  'pages.searchTable.batchApproval': 'batch approval',

  'page.baseinfo.bankmanage.column.title.action': 'Action', // '操作',
  'page.baseinfo.bankmanage.column.title.english_name': 'Name', // '英文名称',
  'page.baseinfo.bankmanage.column.title.id': 'ID', // 'ID',
  'page.baseinfo.bankmanage.column.title.index': 'Index', // '序号',
  'page.baseinfo.bankmanage.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.bankmanage.column.title.name': 'Name', // '姓名',
  'page.baseinfo.bankmanage.column.title.swift_code': 'SWIFT Code', // 'swift_code',
  'page.baseinfo.bankmanage.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.bankmanage.form.label.english_name': 'Name', // '英文名称',
  'page.baseinfo.bankmanage.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.bankmanage.form.label.name': 'Name', // '名称',
  'page.baseinfo.paymentmethod.form.label.search_text': 'Search Text', // '搜索文本',
  'page.baseinfo.paymentmethod.form.placeholder.search_text': 'Search Text', // '请填写搜索文本',
  'page.baseinfo.bankmanage.form.label.swift_code': 'SWIFT Code', // 'swift_code',
  'page.baseinfo.bankmanage.form.placeholder.english_name':
    'Please fill in the bank name in English', // '请填写英文名称',
  'page.baseinfo.bankmanage.form.placeholder.is_active': 'Please choose if active', // '请选择是否可用',
  'page.baseinfo.bankmanage.form.placeholder.name': 'Please fill in bank name', // '请填写名称',
  'page.baseinfo.bankmanage.form.placeholder.swift_code': 'Please fill in SWIFT code', // '请填写swift_code',
  'page.baseinfo.ccbankaccountscc.column.title.account_name': 'Account Name', // '账户名',
  'page.baseinfo.ccbankaccountscc.column.title.account_number': 'Account Number', // '账号',
  'page.baseinfo.ccbankaccountscc.column.title.action': 'Action', // '操作',
  'page.baseinfo.ccbankaccountscc.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.ccbankaccountscc.column.title.id': 'ID', // 'ID',
  'page.baseinfo.ccbankaccountscc.column.title.index': 'Index', // '序号',
  'page.baseinfo.ccbankaccountscc.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.ccbankaccountscc.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.ccbankaccountscc.form.label.account_name': 'Account Name', // '账户名',
  'page.baseinfo.ccbankaccountscc.form.label.account_number': 'Account Number', // '账号',
  'page.baseinfo.ccbankaccountscc.form.placeholder.account_name': 'Please fill in account name', // '请填写账户名',
  'page.baseinfo.ccbankaccountscc.form.placeholder.account_number': 'Please fill in account number', // '请填写账号',
  'page.baseinfo.cccompany.column.title.action': 'Action', // '操作',
  'page.baseinfo.cccompany.column.title.address': 'Address', // '地址',
  'page.baseinfo.cccompany.column.title.contact_number': 'Contact Number', // '联系方式',
  'page.baseinfo.cccompany.column.title.id': 'ID', // 'ID',
  'page.baseinfo.cccompany.column.title.index': 'Index', // '序号',
  'page.baseinfo.cccompany.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.cccompany.column.title.name': 'Company Name', // '公司名称',
  'page.baseinfo.cccompany.column.title.abbr': 'Company Abbr', // '公司名称',
  'page.baseinfo.cccompany.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.cccompany.form.label.address': 'Address', // '地址',
  'page.baseinfo.cccompany.form.label.contact_number': 'Contact Number', // '联系方式',
  'page.baseinfo.cccompany.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.cccompany.form.label.name': 'Company Name', // '公司名称',
  'page.baseinfo.cccompany.form.label.abbr': 'Company Abbr', // '公司缩写',
  'page.baseinfo.cccompany.form.placeholder.address': 'Please fill in address info', // '请填写地址',
  'page.baseinfo.cccompany.form.placeholder.contact_number': 'Please fill in contact number', // '请填写联系方式',
  'page.baseinfo.cccompany.form.placeholder.is_active': 'Please choose if active', // '请选择是否可用',
  'page.baseinfo.cccompany.form.placeholder.name': 'Please fill in the account name', // '请填写账户名',
  'page.baseinfo.cccompany.form.placeholder.abbr': 'Please fill in the company abbr', // '请填写账户名',
  'page.baseinfo.ccemail.column.title.action': 'Action', // '操作',
  'page.baseinfo.ccemail.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.ccemail.column.title.email': 'Email', // '邮箱地址',
  'page.baseinfo.ccemail.column.title.id': 'ID', // 'ID',
  'page.baseinfo.ccemail.column.title.index': 'Index', // '序号',
  'page.baseinfo.ccemail.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.ccemail.column.title.name': 'Account Name', // '账号',
  'page.baseinfo.ccemail.column.title.password': 'Password', // '邮箱密码',
  'page.baseinfo.ccemail.column.title.port': 'Port', // '服务器端口号',
  'page.baseinfo.ccemail.column.title.smtp': 'SMTP server', // '邮箱服务器',
  'page.baseinfo.ccemail.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.ccemail.form.label.email': 'Email', // '邮箱地址',
  'page.baseinfo.ccemail.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.ccemail.form.label.name': 'Account Name', // '账号',
  'page.baseinfo.ccemail.form.label.password': 'Password', // '邮箱密码',
  'page.baseinfo.ccemail.form.label.port': 'Port', // '服务器端口号',
  'page.baseinfo.ccemail.form.label.smtp': 'SMTP server', // '邮箱服务器',
  'page.baseinfo.ccemail.form.placeholder.email': 'Please fill in Email address', // '请填写邮箱地址',
  'page.baseinfo.ccemail.form.placeholder.is_active': 'Please choose if active', // '请选择状态',
  'page.baseinfo.ccemail.form.placeholder.name': 'Please fill in account number', // '请填写账号',
  'page.baseinfo.ccemail.form.placeholder.password': 'Please fill in password', // '请填写邮箱密码',
  'page.baseinfo.ccemail.form.placeholder.port': 'Please fill in the port number', // '请填写服务器端口号',
  'page.baseinfo.ccemail.form.placeholder.smtp': 'Please fill in the SMTP server info', // '请填写邮箱服务器',
  'page.baseinfo.contracttemplate.column.title.action': 'Action', // '操作',
  'page.baseinfo.contracttemplate.column.title.business_id': 'Qiyuesuo Business ID', // '契约锁业务分类ID',
  'page.baseinfo.contracttemplate.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.contracttemplate.column.title.description': 'Description', // '描述',
  'page.baseinfo.contracttemplate.column.title.id': 'ID', // 'ID',
  'page.baseinfo.contracttemplate.column.title.index': 'Index', // '序号',
  'page.baseinfo.contracttemplate.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.contracttemplate.column.title.modal': 'Template', // '合同模板',
  'page.baseinfo.contracttemplate.column.title.modal1': 'Sign Template', // '签章合同模板',
  'page.baseinfo.contracttemplate.column.title.name': 'Name', // '名称',
  'page.baseinfo.contracttemplate.column.title.template_id': 'Qiyuesuo Template ID', // '契约锁模板ID',
  'page.baseinfo.contracttemplate.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.contracttemplate.form.label.business_id': 'Qiyuesuo Business ID', // '契约锁业务分类ID',
  'page.baseinfo.contracttemplate.form.label.description': 'Description', // '描述',
  'page.baseinfo.contracttemplate.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.contracttemplate.form.label.name': 'Name', // '名称',
  'page.baseinfo.contracttemplate.form.label.search_text': 'Search text', // '模糊搜索',
  'page.baseinfo.contracttemplate.form.label.template_id': 'Qiyuesuo template ID', // '契约锁模板ID',
  'page.baseinfo.contracttemplate.form.placeholder.business_id':
    'Please fill in Qiyuesuo business ID', // '请填写契约锁业务分类ID',
  'page.baseinfo.contracttemplate.form.placeholder.description': 'Please filll= in', // '请填写描述',
  'page.baseinfo.contracttemplate.form.placeholder.is_active': 'Please choose if active', // '请选择状态',
  'page.baseinfo.contracttemplate.form.placeholder.name': 'Please fill in contact name', // '请填写名称',
  'page.baseinfo.contracttemplate.form.placeholder.search_text': 'Please fill in search text', // '请填写搜索内容',
  'page.baseinfo.contracttemplate.form.placeholder.template_id':
    'Please fill in Qiyuesuo template ID', // '请填写契约锁模板ID',
  'page.baseinfo.country.column.title.action': 'Action', // '操作',
  'page.baseinfo.country.column.title.code': 'Country code', // '国家代码',
  'page.baseinfo.country.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.country.column.title.currency': 'Currency', // '币种',
  'page.baseinfo.country.column.title.id': 'ID', // 'ID',
  'page.baseinfo.country.column.title.index': 'Index', // '序号',
  'page.baseinfo.country.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.country.column.title.name': 'Country', // '国家名称',
  'page.baseinfo.country.column.title.nationality': 'Nationality', // '国籍',
  'page.baseinfo.country.column.title.symbol': 'Currency Sign', // '符号',
  'page.baseinfo.country.column.title.sort': 'Sort', // '排序',
  'page.baseinfo.country.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.country.form.label.code': 'Country Code', // '国家代码',
  'page.baseinfo.country.form.label.currency': 'Currency', // '币种',
  'page.baseinfo.country.form.label.name': 'Country', // '国家名称',
  'page.baseinfo.country.form.label.nationality': 'Nationality', // '国籍',
  'page.baseinfo.country.form.label.symbol': 'Currency Sign', // '符号',
  'page.baseinfo.country.form.label.sort': 'Sort', // '排序',
  'page.baseinfo.country.form.placeholder.code': 'Please fill in country code', // '请填写国家代码',
  'page.baseinfo.country.form.placeholder.currency': 'Please fill in currency', // '请填写币种',
  'page.baseinfo.country.form.placeholder.name': 'Please fill in country name', // '请填写国家名称',
  'page.baseinfo.country.form.placeholder.nationality': 'Please fill in nationality', // '请填写国籍',
  'page.baseinfo.country.form.placeholder.symbol': 'Please fill in currency sign', // '请填写符号',
  'page.baseinfo.country.form.placeholder.sort': 'Please fill in sort', // '请填写顺序号',
  'page.baseinfo.emailaddress.column.title.action': 'Action', // '操作',
  'page.baseinfo.emailaddress.column.title.email': 'Email Address', // '邮箱地址',
  'page.baseinfo.emailaddress.column.title.id': 'ID', // 'ID',
  'page.baseinfo.emailaddress.column.title.index': 'Index', // '序号',
  'page.baseinfo.emailaddress.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.emailaddress.column.title.name': 'Email Name', // '邮箱名称',
  'page.baseinfo.emailaddress.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.emailaddress.form.label.email': 'Email Address', // '邮箱地址',
  'page.baseinfo.emailaddress.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.emailaddress.form.label.name': 'Email Name', // '邮箱名称',
  'page.baseinfo.emailaddress.form.placeholder.email': 'Please fill in email address', // '请填写邮箱地址',
  'page.baseinfo.emailaddress.form.placeholder.is_active': 'Please choose if active', // '请选择是否可用',
  'page.baseinfo.emailaddress.form.placeholder.name': 'Please fill in email name', // '请填写邮箱名称',
  'page.baseinfo.emailtemplate.column.title.action': 'Action', // '操作',
  'page.baseinfo.emailtemplate.column.title.appendix_json_list': 'Attachments', // '附件',
  'page.baseinfo.emailtemplate.column.title.content': 'Contnet', // '内容',
  'page.baseinfo.emailtemplate.column.title.content_type': 'Content Type', // '内容类型',
  'page.baseinfo.emailtemplate.column.title.id': 'ID', // 'ID',
  'page.baseinfo.emailtemplate.column.title.image': 'Image', // '图片',
  'page.baseinfo.emailtemplate.column.title.index': 'Index', // '序号',
  'page.baseinfo.emailtemplate.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.emailtemplate.column.title.name': 'Name', // '名称',
  'page.baseinfo.emailtemplate.column.title.title': 'Title', // '标题',
  'page.baseinfo.emailtemplate.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.emailtemplate.form.label.content': 'Content', // '内容',
  'page.baseinfo.emailtemplate.form.label.content_type': 'Content Type', // '内容类型',
  'page.baseinfo.emailtemplate.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.emailtemplate.form.label.name': 'Name', // '名称',
  'page.baseinfo.emailtemplate.form.label.search_text': 'Search Text', // '模糊搜索',
  'page.baseinfo.emailtemplate.form.label.title': 'Title', // '标题',
  'page.baseinfo.emailtemplate.form.placeholder.content': 'Please fill in content', // '请填写内容',
  'page.baseinfo.emailtemplate.form.placeholder.content_type': 'Please choose content type', // '请选择内容类型',
  'page.baseinfo.emailtemplate.form.placeholder.is_active': 'Please fill in if active', // '请选择状态',
  'page.baseinfo.emailtemplate.form.placeholder.name': 'Please fill in template name', // '请填写名称',
  'page.baseinfo.emailtemplate.form.placeholder.search_text': 'Please fill in search text', // '请填写搜索内容',
  'page.baseinfo.emailtemplate.form.placeholder.title': 'Please fill in title', // '请填写标题',
  'page.baseinfo.endclient.column.title.action': 'Action', // '操作',
  'page.baseinfo.endclient.column.title.address': 'Address', // '地址',
  'page.baseinfo.endclient.column.title.contact_number': 'Contact Number', // '联系方式',
  'page.baseinfo.endclient.column.title.id': 'ID', // 'ID',
  'page.baseinfo.endclient.column.title.index': 'Index', // '序号',
  'page.baseinfo.endclient.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.endclient.column.title.name': 'Name', // '名称',
  'page.baseinfo.endclient.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.endclient.form.label.address': 'Address', // '地址',
  'page.baseinfo.endclient.form.label.contact_number': 'Contact Number', // '联系方式',
  'page.baseinfo.endclient.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.endclient.form.label.name': 'Name', // '名称',
  'page.baseinfo.endclient.form.placeholder.address': 'Please fill in address', // '请填写地址',
  'page.baseinfo.endclient.form.placeholder.contact_number': 'Please fill in contact number', // '请填写联系方式',
  'page.baseinfo.endclient.form.placeholder.is_active': 'Please select if active', // '请选择是否可用',
  'page.baseinfo.endclient.form.placeholder.name': 'Please fill in name', // '请填写名称',
  'page.baseinfo.exchangerate.column.title.action': 'Action', // '操作',
  'page.baseinfo.exchangerate.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.exchangerate.column.title.currency_from': 'Currency From', // '原币种',
  'page.baseinfo.exchangerate.column.title.currency_to': 'Currency To', // '目标币种',
  'page.baseinfo.exchangerate.column.title.index': 'Index', // '序号',
  'page.baseinfo.exchangerate.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.exchangerate.column.title.rate': 'Exchange Rate', // '汇率',
  'page.baseinfo.exchangerate.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.exchangerate.form.label.currency_from': 'Currency From', // '原币种',
  'page.baseinfo.exchangerate.form.label.currency_to': 'Currency To', // '目标币种',
  'page.baseinfo.exchangerate.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.exchangerate.form.label.rate': 'Exchange Rate', // '汇率',
  'page.baseinfo.exchangerate.form.label.search_text': 'Search Text', // '模糊搜索',
  'page.baseinfo.exchangerate.form.placeholder.currency_from':
    'Please fill in the currency needs to be exchange from', // '请填写原币种',
  'page.baseinfo.exchangerate.form.placeholder.currency_to':
    'Please fill in the currency needs to be exchanged to ', // '请填写目标币种',
  'page.baseinfo.exchangerate.form.placeholder.is_active': 'Please choose if active', // '请选择状态',
  'page.baseinfo.exchangerate.form.placeholder.rate': 'Please fill in the exchange rate', // '请填写汇率',
  'page.baseinfo.exchangerate.form.placeholder.search_text': 'Please fill in the search text', // '请填写搜索内容',
  'page.baseinfo.idtype.column.title.action': 'Action', // '操作',
  'page.baseinfo.idtype.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.idtype.column.title.description': 'Description', // '描述',
  'page.baseinfo.idtype.column.title.id': 'ID', // 'ID',
  'page.baseinfo.idtype.column.title.index': 'Index', // '序号',
  'page.baseinfo.idtype.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.idtype.column.title.name': 'ID Type', // '证件类别名',
  'page.baseinfo.idtype.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.idtype.form.label.description': 'Description', // '描述',
  'page.baseinfo.idtype.form.label.name': 'ID Type', // '证件类别名',
  'page.baseinfo.idtype.form.placeholder.description': 'Please fill in ID Type Description', // '请填写描述',
  'page.baseinfo.idtype.form.placeholder.name': 'Please fill in ID Type Name', // '请填写证件类别名',
  'page.baseinfo.insurancecompany.column.title.action': 'Action', // '操作',
  'page.baseinfo.insurancecompany.column.title.address': 'Address', // '地址',
  'page.baseinfo.insurancecompany.column.title.agent': 'Agent', // '保险经纪人',
  'page.baseinfo.insurancecompany.column.title.agent_contact': 'Agent Contact', // '经纪人联系方式',
  'page.baseinfo.insurancecompany.column.title.content': 'Contact', // '理赔流程',
  'page.baseinfo.insurancecompany.column.title.company_name': 'Company Name', // '公司名称',
  'page.baseinfo.insurancecompany.column.title.contact': 'Contact', // '联系人',
  'page.baseinfo.insurancecompany.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.insurancecompany.column.title.email': 'Email', // '邮箱',
  'page.baseinfo.insurancecompany.column.title.id': 'ID', // 'ID',
  'page.baseinfo.insurancecompany.column.title.index': 'Index', // '序号',
  'page.baseinfo.insurancecompany.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.insurancecompany.column.title.remark': 'Note', // '备注',
  'page.baseinfo.insurancecompany.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.insurancecompany.form.label.address': 'Address', // '地址',
  'page.baseinfo.insurancecompany.form.label.agent': 'Agent', // '保险经纪人',
  'page.baseinfo.insurancecompany.form.label.agent_contact': 'Agent Contact', // '经纪人联系方式',
  'page.baseinfo.insurancecompany.form.label.company_name': 'Company Name', // '公司名称',
  'page.baseinfo.insurancecompany.form.label.contact': 'Contact', // '联系人',
  'page.baseinfo.insurancecompany.form.label.email': 'Email', // '邮箱',
  'page.baseinfo.insurancecompany.form.label.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.insurancecompany.form.label.remark': 'Note', // '备注',
  'page.baseinfo.insurancecompany.form.placeholder.address': 'Please fill in address', // '请填写地址',
  'page.baseinfo.insurancecompany.form.placeholder.agent': 'Please fill in agent info', // '请填写保险经纪人',
  'page.baseinfo.insurancecompany.form.placeholder.agent_contact':
    'Please fill in agent contact info', // '请填写经纪人联系方式',
  'page.baseinfo.insurancecompany.form.placeholder.company_name': 'Please fill in company name', // '请填写公司名称',
  'page.baseinfo.insurancecompany.form.placeholder.contact': 'Please fill in contact info', // '请填写联系人',
  'page.baseinfo.insurancecompany.form.placeholder.email': 'Please fill in email', // '请填写邮箱',
  'page.baseinfo.insurancecompany.form.placeholder.remark': 'Please fill in note', // '请填写备注',
  'page.baseinfo.invoicetype.column.title.action': 'Action', // '操作',
  'page.baseinfo.invoicetype.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.invoicetype.column.title.description': 'Description', // '描述',
  'page.baseinfo.invoicetype.column.title.id': 'ID', // 'ID',
  'page.baseinfo.invoicetype.column.title.index': 'Index', // '序号',
  'page.baseinfo.invoicetype.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.invoicetype.column.title.name': 'Invoice Type Name', // '证件类别名',
  'page.baseinfo.invoicetype.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.invoicetype.form.label.description': 'Description', // '描述',
  'page.baseinfo.invoicetype.form.label.name': 'Invoice Type Name', // '证件类别名',
  'page.baseinfo.invoicetype.form.placeholder.description': 'Please fill in description', // '请填写描述',
  'page.baseinfo.invoicetype.form.placeholder.name': 'Please fill in invoice type name', // '请填写证件类别名',
  'page.baseinfo.managementfeeformula.column.title.action': 'Action', // '操作',
  'page.baseinfo.managementfeeformula.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.managementfeeformula.column.title.description': 'Description', // '描述',
  'page.baseinfo.managementfeeformula.column.title.formula': 'Formula', // '公式',
  'page.baseinfo.managementfeeformula.column.title.id': 'ID', // 'ID',
  'page.baseinfo.managementfeeformula.column.title.index': 'Index', // '序号',
  'page.baseinfo.managementfeeformula.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.managementfeeformula.column.title.name': 'Formula Name', // '公式名称',
  'page.baseinfo.managementfeeformula.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.managementfeeformula.form.label.description': 'Description', // '描述',
  'page.baseinfo.managementfeeformula.form.label.formula': 'Formula', // '公式',
  'page.baseinfo.managementfeeformula.form.label.is_active': 'Is Active', // '状态',
  'page.baseinfo.managementfeeformula.form.label.name': 'Formula Name', // '名称',
  'page.baseinfo.managementfeeformula.form.placeholder.description': 'Please fill in description', // '请填写描述',
  'page.baseinfo.managementfeeformula.form.placeholder.formula': 'Please fill in formula', // '请填写公式',
  'page.baseinfo.managementfeeformula.form.placeholder.is_active': 'Please choose if active', // '请选择状态',
  'page.baseinfo.managementfeeformula.form.placeholder.name': 'Please fill in formula name', // '请填写公式名称',
  'page.baseinfo.notetype.column.title.action': 'Action', // '操作',
  'page.baseinfo.notetype.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.notetype.column.title.description': 'Description', // '描述',
  'page.baseinfo.notetype.column.title.id': 'ID', // 'ID',
  'page.baseinfo.notetype.column.title.index': 'Index', // '序号',
  'page.baseinfo.notetype.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.notetype.column.title.name': 'Note Type Name', // '记录类型',
  'page.baseinfo.notetype.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.notetype.form.label.description': 'Description', // '描述',
  'page.baseinfo.notetype.form.label.flag': 'Flag', // '标记',
  'page.baseinfo.notetype.form.label.name': 'Note Type Name', // '记录类型',
  'page.baseinfo.notetype.form.placeholder.description': 'Please fill in note type description', // '请填写描述',
  'page.baseinfo.notetype.form.placeholder.flag': 'Please fill in note type flag', // '请填写标记',
  'page.baseinfo.notetype.form.placeholder.name': 'Please fill in note type name', // '请填写记录类型',
  'page.baseinfo.persontype.column.title.action': 'Action', // '操作',
  'page.baseinfo.persontype.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.persontype.column.title.description': 'Description', // '描述',
  'page.baseinfo.persontype.column.title.id': 'ID', // 'ID',
  'page.baseinfo.persontype.column.title.index': 'Index', // '序号',
  'page.baseinfo.persontype.column.title.is_active': 'Is Action', // '是否可用',
  'page.baseinfo.persontype.column.title.name': 'Person Type Name', // '名称',
  'page.baseinfo.persontype.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.persontype.form.label.description': 'Description', // '描述',
  'page.baseinfo.persontype.form.label.name': 'Person Type Name', // '名称',
  'page.baseinfo.persontype.form.placeholder.description': 'Please fill in person type description', // '请填写描述',
  'page.baseinfo.persontype.form.placeholder.name': 'Please fill in person type name', // '请填写名称',
  'page.baseinfo.recuitmentcompany.column.title.action': 'Action', // '操作',
  'page.baseinfo.recuitmentcompany.column.title.address': 'Address', // '顾问公司地址',
  'page.baseinfo.recuitmentcompany.column.title.client_id': 'ID', // 'ID',
  'page.baseinfo.recuitmentcompany.column.title.company_name': 'Company Name', // '顾问公司名',
  'page.baseinfo.recuitmentcompany.column.title.contact_number': 'Contact Number', // '顾问公司联系方式',
  'page.baseinfo.recuitmentcompany.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.recuitmentcompany.column.title.id': 'ID', // 'ID',
  'page.baseinfo.recuitmentcompany.column.title.index': 'Index', // '序号',
  'page.baseinfo.recuitmentcompany.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.recuitmentcompany.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.recuitmentcompany.column.title.vat_no': 'VAT Number', // 'VATNo',
  'page.baseinfo.recuitmentcompany.column.title.bank_account_list': 'CC Bank', // 'CC银行账户',
  'page.baseinfo.recuitmentcompany.form.label.address': 'Address', // '顾问公司地址',
  'page.baseinfo.recuitmentcompany.form.label.client_id': 'ID', // '顾问公司ID',
  'page.baseinfo.recuitmentcompany.form.label.company_name': 'Company Name', // '顾问公司名',
  'page.baseinfo.recuitmentcompany.form.label.contact_number': 'Contact Number', // '顾问公司联系方式',
  'page.baseinfo.recuitmentcompany.form.label.vat_no': 'VAT Number', // 'VATNo',
  'page.baseinfo.recuitmentcompany.form.placeholder.address':
    'Please fill in Recruitment Company Address', // '请填写顾问公司地址',
  'page.baseinfo.recuitmentcompany.form.placeholder.client_id':
    'Please fill in Recruitment Company ID', // '请填写顾问公司ID',
  'page.baseinfo.recuitmentcompany.form.placeholder.company_name':
    'Please fill in Recruitment Company Name', // '请填写顾问公司名',
  'page.baseinfo.recuitmentcompany.form.placeholder.contact_number':
    'Please fill in Recruitment Company Contact Number', // '请填写顾问公司联系方式',
  'page.baseinfo.recuitmentcompany.form.placeholder.vat_no': 'Please fill in VAT Number', // '请填写VATNo',
  'page.baseinfo.recuitmentcompanyperson.column.title.action': 'Action', // '操作',
  'page.baseinfo.recuitmentcompanyperson.column.title.contact_number': 'Contact Number', // '联系方式',
  'page.baseinfo.recuitmentcompanyperson.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.recuitmentcompanyperson.column.title.id': 'ID', // 'ID',
  'page.baseinfo.recuitmentcompanyperson.column.title.index': 'Index', // '序号',
  'page.baseinfo.recuitmentcompanyperson.column.title.invoice_email_to': 'Invoice Email To', // '请款单发送邮箱',
  'page.baseinfo.recuitmentcompanyperson.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.recuitmentcompanyperson.column.title.name': 'Recruitment Company Contact Name', // '名称',
  'page.baseinfo.recuitmentcompanyperson.column.title.recuitment_company__company_name':
    'Recruitment Company Name', // '顾问公司',
  'page.baseinfo.recuitmentcompanyperson.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.recuitmentcompanyperson.form.label.contact_number': 'Contact Number', // '联系方式',
  'page.baseinfo.recuitmentcompanyperson.form.label.invoice_email_to': 'Invoice Email To', // '请款单发送邮箱',
  'page.baseinfo.recuitmentcompanyperson.form.label.name': 'Recruitment Company Contact Name', // '名称',
  'page.baseinfo.recuitmentcompanyperson.form.label.recuitment_company_id':
    'Recruitment Company Name', // '顾问公司',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.contact_number':
    'Please fill in recruitment company person contact number', // '请填写联系方式',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.invoice_email_to':
    'Please fill in invoice email to address', // '请填写请款单发送邮箱',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.name':
    'Please fill in recruitment company contact person name', // '请填写名称',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.recuitment_company_id':
    'Please fill in recruitment company name', // '请选择顾问公司',
  'page.baseinfo.referencenumbertype.column.title.action': 'Action', // '操作',
  'page.baseinfo.referencenumbertype.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.referencenumbertype.column.title.description': 'Description', // '类别描述',
  'page.baseinfo.referencenumbertype.column.title.id': 'ID', // 'ID',
  'page.baseinfo.referencenumbertype.column.title.index': 'Index', // '序号',
  'page.baseinfo.referencenumbertype.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.referencenumbertype.column.title.is_adviser_select': 'Is adviser select', // '是否顾问端可选择',
  'page.baseinfo.referencenumbertype.column.title.name': 'Reference Number Type Name', // '类别名称',
  'page.baseinfo.referencenumbertype.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.referencenumbertype.form.label.description': 'Description', // '类别描述',
  'page.baseinfo.referencenumbertype.form.label.is_adviser_select': 'Is adviser select', // '是否顾问端可选择',
  'page.baseinfo.referencenumbertype.form.label.name': 'Reference Number Type', // '类别名称',
  'page.baseinfo.referencenumbertype.form.placeholder.description': 'Description', // '请填写类别描述',
  'page.baseinfo.referencenumbertype.form.placeholder.name':
    'Please enter reference number type name', // '请填写类别名称',
  'page.baseinfo.status.column.title.action': 'Action', // '操作',
  'page.baseinfo.status.column.title.create_time': 'Timestamp', // '创建时间',
  'page.baseinfo.status.column.title.description': 'Description', // '描述',
  'page.baseinfo.status.column.title.id': 'ID', // 'ID',
  'page.baseinfo.status.column.title.index': 'Index', // '序号',
  'page.baseinfo.status.column.title.is_active': 'Is Active', // '是否可用',
  'page.baseinfo.status.column.title.name': 'Status', // '记录类型',
  'page.baseinfo.status.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.status.form.label.description': 'Description', // '描述',
  'page.baseinfo.status.form.label.name': 'Status', // '记录类型',
  'page.baseinfo.status.form.placeholder.description': 'Please fill in status description', // '请填写描述',
  'page.baseinfo.status.form.placeholder.name': 'Please fill in status name', // '请填写记录类型',
  'page.baseinfo.to_do_event_priority.form.label.name': 'Priority Name', // '描述'
  'page.baseinfo.to_do_event_priority.form.label.priority': 'Priority', // '优先等级'
  'page.baseinfo.to_do_event_priority.form.placeholder.priority': 'Please fill in Priority',
  'page.baseinfo.to_do_event_priority.form.placeholder.name': 'Please fill in Priority name',
  'page.cerp.address.column.title.action': 'Action', // '操作',
  'page.cerp.address.column.title.address_type': 'Address Type', // '地址类型',
  'page.cerp.address.column.title.adviser__full_name': 'Consultant Name', // '隶属顾问',
  'page.cerp.address.column.title.country__name': 'Country', // '国家名称',
  'page.cerp.address.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.address.column.title.display_name': 'Address', // '地址',
  'page.cerp.address.column.title.id': 'ID', // 'ID',
  'page.cerp.address.column.title.index': 'Index', // '序号',
  'page.cerp.address.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.address.column.title.postcode': 'Postcode', // '邮编',
  'page.cerp.address.column.title.update_time': 'Timestamp', // '更新时间',
  'page.baseinfo.to_do_event_priority.column.title.index': 'Index', // '序号',
  'page.baseinfo.to_do_event_priority.column.title.id': 'ID', // 'ID',
  'page.baseinfo.to_do_event_priority.column.title.name': 'Priority Name', // '描述'
  'page.baseinfo.to_do_event_priority.column.title.priority': 'Priority', // '优先等级'
  'page.baseinfo.to_do_event_priority.column.title.is_active': 'Is Active', // '是否可用'
  'page.baseinfo.to_do_event_priority.column.title.action': 'Action', // '操作',
  'page.cerp.address.form.label.address_type': 'Address Type', // '地址类型',
  'page.cerp.address.form.label.adviser__full_name': 'Consultant Name', // '顾问',
  'page.cerp.address.form.label.adviser_id': 'Consultant ID', // '隶属顾问',
  'page.cerp.address.form.label.city': 'City', // '城市',
  'page.cerp.address.form.label.country_id': 'Country', // '国家',
  'page.cerp.address.form.label.display_name': 'Display Name', // '显示名称',
  'page.cerp.address.form.label.postcode': 'Postcode', // '邮编',
  'page.cerp.address.form.label.state': 'County', // '州/省',
  'page.cerp.address.form.label.street': 'Street', // '街道',
  'page.cerp.address.form.placeholder.adviser__full_name':
    'Please select the consultant to attach to', // '请选择隶属顾问',
  'page.cerp.address.form.placeholder.adviser_id':
    'Please fill in the consultant this address is attached to', // '隶属顾问',
  'page.cerp.address.form.placeholder.city': 'Please fill in city', // '请填写城市',
  'page.cerp.address.form.placeholder.country_id': 'Please fill in country', // '请选择国家',
  'page.cerp.address.form.placeholder.display_name': 'Please fill in display name', // '请填写显示名称',
  'page.cerp.address.form.placeholder.postcode': 'Please fill in post code', // '请填写邮编',
  'page.cerp.address.form.placeholder.state': 'Please fill in county', // '请填写州/省',
  'page.cerp.address.form.placeholder.street': 'Please fill in street', // '请填写街道',
  'page.cerp.adviser.editModal.column.title.action': 'Action', // '操作',
  'page.cerp.adviser.editModal.column.title.birth_date': 'Date of Birth', // '出生日期',
  'page.cerp.adviser.editModal.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.adviser.editModal.column.title.tag__name': 'Tag', // '标签',
  'page.cerp.adviser.editModal.column.title.person_note': 'person_note', // '备注',
  'page.cerp.adviser.editModal.column.title.work_email': 'Work Email', // '工作邮箱',
  'page.cerp.adviser.form.label.creater_id': 'Created by', // '创建人',
  'page.cerp.adviser.form.label.employee_number': 'Employee ID', // '顾问工号',
  'page.cerp.adviser.form.label.first_name': 'First Name', // '名字',
  'page.cerp.adviser.form.label.home_address_id': 'Home Address', // '家庭地址',
  'page.cerp.adviser.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.adviser.form.label.is_asc': 'Is Asc', // '排序',
  'page.cerp.adviser.form.label.last_name': 'Surname', // '姓',
  'page.cerp.adviser.form.label.mobile': 'Mobile (start with 00, e.g. 00447545xxxxxx）', // '手机',
  'page.cerp.adviser.form.label.nationality_id': 'Nationality ID', // '国籍',
  'page.cerp.adviser.form.label.other_name': 'Other Name', // '其他名字',
  'page.cerp.adviser.form.label.person_type_id': 'Person type ID', // '个人类别',
  'page.cerp.adviser.form.label.personal_email': 'Personal email', // '个人邮箱',
  'page.cerp.adviser.form.label.phone': 'Phone', // '电话',
  'page.cerp.adviser.form.label.qq': 'QQ', // 'QQ账号',
  'page.cerp.adviser.form.label.search_text': 'Search Text', // '模糊搜索',
  'page.cerp.adviser.form.label.skypelid': 'Skype ID', // 'Skype ID',
  'page.cerp.adviser.form.label.status': 'Status', // '个人状态',
  'page.cerp.adviser.form.label.title': 'Salutation', // '称谓',
  'page.cerp.adviser.form.label.wechat': 'WeChat', // '微信账号',
  'page.cerp.adviser.form.label.whats_app': 'Whats app', // 'WhatsApp账号',
  'page.cerp.adviser.form.label.work_address_id': 'Work Address', // '工作地址',
  'page.cerp.adviser.form.label.tag_id': 'Tag', // '标签',
  'page.cerp.adviser.form.label.work_email': 'Work Email', // '工作邮箱',
  'page.cerp.adviser.form.placeholder.creater_id': 'Please select creator', // '请选择创建人',
  'page.cerp.adviser.form.placeholder.employee_number': 'Please fill in employee ID', // '请填写顾问工号',
  'page.cerp.adviser.form.placeholder.first_name': 'Please fill in first name', // '请填写名字',
  'page.cerp.adviser.form.placeholder.home_address_id': 'Please fill in home address ID', // '请选择家庭地址',
  'page.cerp.adviser.form.placeholder.last_name': 'Please enter Surname', // '请填写姓',
  'page.cerp.adviser.form.placeholder.mobile': 'Please fill in mobile number', // '请填写手机',
  'page.cerp.adviser.form.placeholder.nationality_id': 'Please select country', // '请选择国籍',
  'page.cerp.adviser.form.placeholder.other_name': 'Please fill in other name', // '请填写其他名字',
  'page.cerp.adviser.form.placeholder.person_type_id': 'Please fill in person type', // '请选择个人类别',
  'page.cerp.adviser.form.placeholder.personal_email': 'Please fill in personal email', // '请填写个人邮箱',
  'page.cerp.adviser.form.placeholder.phone': 'Please fill in phone number', // '请填写电话',
  'page.cerp.adviser.form.placeholder.qq': 'Please fill in QQ account', // '请填写QQ账号',
  'page.cerp.adviser.form.placeholder.search_text': 'Please fill in search text', // '请填写搜索内容',
  'page.cerp.adviser.form.placeholder.skypelid': 'Please fill in SkypeID', // '请填写Skype ID',
  'page.cerp.adviser.form.placeholder.status': 'Please select person status', // '请选择个人状态',
  'page.cerp.adviser.form.placeholder.title': 'Please select salutation', // '请选择称谓',
  'page.cerp.adviser.form.placeholder.wechat': 'Please fill in WeChat account', // '请填写微信账号',
  'page.cerp.adviser.form.placeholder.whats_app': 'Please fill in WhatsApp account', // '请填写WhatsApp账号',
  'page.cerp.adviser.form.placeholder.work_address_id': 'Please fill in work address', // '请选择工作地址',
  'page.cerp.adviser.form.placeholder.tag_id': 'Please fill in Tag', // '请选择标签',
  'page.cerp.adviser.form.placeholder.work_email': 'Please fill in work email', // '请填写工作邮箱',
  'page.cerp.bankaccount.column.title.account_holder': 'Account Holder', // '账户持有人',
  'page.cerp.bankaccount.column.title.account_number': 'Account Number', // '账号',
  'page.cerp.bankaccount.column.title.action': 'Action', // '操作',
  'page.cerp.bankaccount.column.title.adviser__full_name': 'Consultant', // '隶属顾问',
  'page.cerp.bankaccount.column.title.bank_address': 'Bank Address', // '开户行地址',
  'page.cerp.bankaccount.column.title.bank_name': 'Bank Name', // '银行名称',
  'page.cerp.bankaccount.column.title.bic_or_swift_code': 'BIC or SWIFT code', // 'bic_or_swift_code',
  'page.cerp.bankaccount.column.title.cnaps': 'CNAPS', // 'cnaps',
  'page.cerp.bankaccount.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.bankaccount.column.title.english_name': 'Bank Name (English)', // '银行英文名称',
  'page.cerp.bankaccount.column.title.iban': 'IBAN', // 'iban',
  'page.cerp.bankaccount.column.title.id': 'ID', // 'ID',
  'page.cerp.bankaccount.column.title.index': 'Index', // '序号',
  'page.cerp.bankaccount.column.title.insurance_company__company_name': 'Insurance Company Name', // '保险公司',
  'page.cerp.bankaccount.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.bankaccount.column.title.is_current': 'Is Current', // '是否当前账号',
  'page.cerp.bankaccount.column.title.note': 'Note', // '备注',
  'page.cerp.bankaccount.column.title.sort_code_or_branch_code': 'Sort Code or Branch Code', // 'sort_code_or_branch_code',
  'page.cerp.bankaccount.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.bankaccount.form.label.account_holder': 'Account Holder', // '账户持有人',
  'page.cerp.bankaccount.form.label.account_number': 'Account Number', // '账号',
  'page.cerp.bankaccount.form.label.adviser__full_name': 'Consultant', // '顾问',
  'page.cerp.bankaccount.form.label.adviser_id': 'Employee ID', // '隶属顾问',
  'page.cerp.bankaccount.form.label.bank_address': 'Bank Address', // '开户行地址',
  'page.cerp.bankaccount.form.label.bank_name': 'Bank Name', // '银行名称',
  'page.cerp.bankaccount.form.label.bic_or_swift_code': 'BIC or SWIFT Code', // 'bic_or_swift_code',
  'page.cerp.bankaccount.form.label.cnaps': 'cnaps', // 'cnaps',
  'page.cerp.bankaccount.form.label.english_name': 'Bank Name (English)', // '银行英文名称',
  'page.cerp.bankaccount.form.label.iban': 'IBAN', // 'iban',
  'page.cerp.bankaccount.form.label.insurance_company_id': 'Insurance Company', // '保险公司',
  'page.cerp.bankaccount.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.bankaccount.form.label.is_current': 'Is Current', // '是否当前账号',
  'page.cerp.bankaccount.form.label.kind': 'Account Type', // '账号种类',
  'page.cerp.bankaccount.form.label.sort_code_or_branch_code': 'Sort Code or Branch Code', // 'sort_code_or_branch_code',
  'page.cerp.bankaccount.form.placeholder.account_holder': 'Please fill in Account Holder', // '请填写账户持有人',
  'page.cerp.bankaccount.form.placeholder.account_number': 'Please fill in Account Number', // '请填写账号',
  'page.cerp.bankaccount.form.placeholder.adviser__full_name': 'Please select consultant', // '请选择隶属顾问',
  'page.cerp.bankaccount.form.placeholder.adviser_id': 'Please select consultant', // '隶属顾问',
  'page.cerp.bankaccount.form.placeholder.bank_address': 'Please fill in bank address', // '请填写开户行地址',
  'page.cerp.bankaccount.form.placeholder.bank_name': 'Please fill in bank name', // '请填写银行名称',
  'page.cerp.bankaccount.form.placeholder.bic_or_swift_code': 'Please fill in BIC or SWIFT code', // '请填写bic_or_swift_code',
  'page.cerp.bankaccount.form.placeholder.cnaps': 'Please fill in cnaps', // '请填写cnaps',
  'page.cerp.bankaccount.form.placeholder.english_name': 'Please fill in bank in English', // '请填写银行英文名称',
  'page.cerp.bankaccount.form.placeholder.iban': 'Please fill in IBAN', // '请填写iban',
  'page.cerp.bankaccount.form.placeholder.insurance_company_id': 'Please select insurance company', // '请选择保险公司',
  'page.cerp.bankaccount.form.placeholder.is_current': 'Please fill in if it is current account', // '请填写是否当前账号',
  'page.cerp.bankaccount.form.placeholder.sort_code_or_branch_code':
    'Please fill in sort code or branch code', // '请填写sort_code_or_branch_code',
  'page.cerp.bankaccount1.column.title.account_holder': 'Account Holder2', // '账户持有人',
  'page.cerp.bankaccount1.column.title.account_number': 'Account Number2', // '账号',
  'page.cerp.bankaccount1.column.title.action': 'Action', // '操作',
  'page.cerp.bankaccount1.column.title.bank_address': 'Bank Address2', // '开户行地址',
  'page.cerp.bankaccount1.column.title.bank_name': 'Bank Name2', // '银行名称',
  'page.cerp.bankaccount1.column.title.bic_or_swift_code': 'BIC or SWIFT Code2', // 'bic_or_swift_code',
  'page.cerp.bankaccount1.column.title.cnaps': 'cnaps', // 'cnaps2',
  'page.cerp.bankaccount1.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.bankaccount1.column.title.english_name': 'Bank Name (English)2', // '银行英文名称',
  'page.cerp.bankaccount1.column.title.iban': 'IBAN2', // 'iban',
  'page.cerp.bankaccount1.column.title.id': 'ID2', // 'ID',
  'page.cerp.bankaccount1.column.title.index': 'Index2', // '序号',
  'page.cerp.bankaccount1.column.title.insurance_company__company_name': 'Insurance Company2', // '保险公司',
  'page.cerp.bankaccount1.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.bankaccount1.column.title.is_current': 'Is Current', // '是否当前账号',
  'page.cerp.bankaccount1.column.title.note': 'Note', // '备注',
  'page.cerp.bankaccount1.column.title.sort_code_or_branch_code': 'Sort Code or Branch Code2', // 'sort_code_or_branch_code',
  'page.cerp.bankaccount1.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.bankaccount1.form.label.account_holder': 'Account Holder2', // '账户持有人',
  'page.cerp.bankaccount1.form.label.account_number': 'Account Number2', // '账号',
  'page.cerp.bankaccount1.form.label.adviser_id': 'Consultant', // '隶属顾问',
  'page.cerp.bankaccount1.form.label.bank_address': 'Bank Address2', // '开户行地址',
  'page.cerp.bankaccount1.form.label.bank_name': 'Bank Name2', // '银行名称',
  'page.cerp.bankaccount1.form.label.bic_or_swift_code': 'BIC or SWIFT Code2', // 'bic_or_swift_code',
  'page.cerp.bankaccount1.form.label.cnaps': 'cnaps', // 'cnaps',
  'page.cerp.bankaccount1.form.label.english_name': 'Bank Name2 (English)', // '银行英文名称',
  'page.cerp.bankaccount1.form.label.iban': 'IBAN2', // 'iban',
  'page.cerp.bankaccount1.form.label.insurance_company_id': 'Insurance Company2', // '保险公司',
  'page.cerp.bankaccount1.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.bankaccount1.form.label.is_current': 'Is Current', // '是否当前账号',
  'page.cerp.bankaccount1.form.label.kind': 'Account Type', // '账号种类',
  'page.cerp.bankaccount1.form.label.sort_code_or_branch_code': 'Sort Code or Branch Code', // 'sort_code_or_branch_code',
  'page.cerp.bankaccount1.form.placeholder.account_holder': 'Please fill in account holder', // '请填写账户持有人',
  'page.cerp.bankaccount1.form.placeholder.account_number': 'Please fill in account number', // '请填写账号',
  'page.cerp.bankaccount1.form.placeholder.adviser_id': 'Consultant', // '隶属顾问',
  'page.cerp.bankaccount1.form.placeholder.bank_address': 'Please fill in bank address', // '请填写开户行地址',
  'page.cerp.bankaccount1.form.placeholder.bank_name': 'Please fill in bank name', // '请填写银行名称',
  'page.cerp.bankaccount1.form.placeholder.bic_or_swift_code': 'Please fill in BIC or SWIFT Code', // '请填写bic_or_swift_code',
  'page.cerp.bankaccount1.form.placeholder.cnaps': 'Please fill in cnaps', // '请填写cnaps',
  'page.cerp.bankaccount1.form.placeholder.english_name': 'Please fill in bank name in English', // '请填写银行英文名称',
  'page.cerp.bankaccount1.form.placeholder.iban': 'Please fill in IBAN', // '请填写iban',
  'page.cerp.bankaccount1.form.placeholder.insurance_company_id':
    'Please fill in insurance company', // '请选择保险公司',
  'page.cerp.bankaccount1.form.placeholder.is_current':
    'Please indicate if this is the current account', // '请填写是否当前账号',
  'page.cerp.bankaccount1.form.placeholder.sort_code_or_branch_code':
    'Please fill in sort code or branch code', // '请填写sort_code_or_branch_code',
  'page.cerp.banktransaction.column.title.action': 'Action', // '操作',
  'page.cerp.banktransaction.column.title.amount': 'Amount', // '金额',
  'page.cerp.banktransaction.column.title.adviser__full_name': 'Adviser Full Name', // '顾问',
  'page.cerp.banktransaction.column.title.adviser__employee_number': 'Employee Number', // '顾问工号',
  'page.cerp.banktransaction.column.title.cc_bank_account__account_flag': 'CC Bank Account Name', // '付款方式',
  'page.cerp.banktransaction.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.banktransaction.column.title.date': 'Transaction Date', // '日期',
  'page.cerp.banktransaction.column.title.description': 'Description', // '备注',
  'page.cerp.banktransaction.column.title.id': 'ID', // 'ID',
  'page.cerp.banktransaction.column.title.index': 'Index', // '序号',
  'page.cerp.banktransaction.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.banktransaction.column.title.name': 'Name', // '名称',
  'page.cerp.banktransaction.column.title.transaction_type': 'Transaction Type', // '流水种类',
  'page.cerp.banktransaction.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.banktransaction.form.label.amount': 'Amount', // '金额',
  'page.cerp.banktransaction.form.label.cc_bank_account_id': 'CC Bank Account', // 'CC银行账户',
  'page.cerp.banktransaction.form.label.date': 'Date', // '日期',
  'page.cerp.banktransaction.form.label.description': 'Description', // '备注',
  'page.cerp.banktransaction.form.label.name': 'Name', // '名称',
  'page.cerp.banktransaction.form.label.transaction_type': 'Transaction Type', // '流水种类',
  'page.cerp.banktransaction.form.placeholder.amount': 'Please fill in amount', // '请填写金额',
  'page.cerp.banktransaction.form.placeholder.cc_bank_account_id': 'Please select CC bank account', // '请选择CC银行账户',
  'page.cerp.banktransaction.form.placeholder.date': 'Please select date', // '请填写日期',
  'page.cerp.banktransaction.form.placeholder.description': 'Please fill in description', // '请填写备注',
  'page.cerp.banktransaction.form.placeholder.name': 'Please fill in name', // '请填写名称',
  'page.cerp.banktransaction.form.placeholder.transaction_type': 'Please fill in transaction type', // '请选择流水种类',
  'page.cerp.beneficiary.column.title.action': 'Action', // '操作',
  'page.cerp.beneficiary.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.beneficiary.column.title.date_of_birth': 'Date of Birth', // '出生日期',
  'page.cerp.beneficiary.column.title.gender': 'Gender', // '性别',
  'page.cerp.beneficiary.column.title.id': 'ID', // 'ID',
  'page.cerp.beneficiary.column.title.id_expiry_date': 'Expiry Date', // '证件有效期',
  'page.cerp.beneficiary.column.title.ratio': 'Ratio', // '受益比例',
  'page.cerp.beneficiary.column.title.relation': 'Relation', // '与被保险人关系',
  'page.cerp.beneficiary.column.title.id_number': 'ID Number', // '证件号',
  'page.cerp.beneficiary.column.title.id_type__name': 'ID Type Name', // '证件类别名',
  'page.cerp.beneficiary.column.title.index': 'Index', // '序号',
  'page.cerp.beneficiary.column.title.insurance__policy_no': 'Insurance Policy Number', // '保险单号',
  'page.cerp.beneficiary.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.beneficiary.column.title.name': 'Name', // '姓名',
  'page.cerp.beneficiary.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.beneficiary.form.label.adviser_id': 'Consultant', // '隶属顾问',
  'page.cerp.beneficiary.form.label.date_of_birth': 'Date of Birth', // '出生日期',
  'page.cerp.beneficiary.form.label.gender': 'Gender', // '性别',
  'page.cerp.beneficiary.form.label.id_expiry_date': 'ID Expiry Date', // '证件有效期',
  'page.cerp.beneficiary.form.label.relation': 'Relation', // '与被保险人关系',
  'page.cerp.beneficiary.form.label.ratio': 'Ratio', // '受益比例',
  'page.cerp.beneficiary.form.label.id_number': 'ID Number', // '证件号',
  'page.cerp.beneficiary.form.label.id_type_id': 'ID Type', // '证件类别',
  'page.cerp.beneficiary.form.label.name': 'Name', // '姓名',
  'page.cerp.beneficiary.form.placeholder.adviser_id': 'Consultant', // '隶属顾问',
  'page.cerp.beneficiary.form.placeholder.date_of_birth': 'Please fill in date of birth', // '请填写出生日期',
  'page.cerp.beneficiary.form.placeholder.id_expiry_date': 'Please fill in ID expiry date', // '请填写证件有效期',
  'page.cerp.beneficiary.form.placeholder.relation': 'Please fill in Relation', // '请填写与被保险人关系',
  'page.cerp.beneficiary.form.placeholder.ratio': 'Please fill in Ratio', // '请填写受益比例',
  'page.cerp.beneficiary.form.placeholder.id_number': 'Please fill in ID Number', // '请填写证件号',
  'page.cerp.beneficiary.form.placeholder.id_type_id': 'Please select ID Type', // '请选择证件类别',
  'page.cerp.beneficiary.form.placeholder.name': 'Please fill in name', // '请填写姓名',
  'page.cerp.changehistory.column.title.action': 'Action', // '操作',
  'page.cerp.changehistory.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.changehistory.column.title.id': 'ID', // 'ID',
  'page.cerp.changehistory.column.title.object_id': 'Data ID', // '数据id',
  'page.cerp.changehistory.column.title.index': 'Index', // '序号',
  'page.cerp.changehistory.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.changehistory.column.title.model_name': 'Model Name', // 'model名称',
  'page.cerp.changehistory.column.title.model_str': 'Model String', // 'model标记',
  'page.cerp.changehistory.column.title.new_json': 'New JSON', // '新数据json',
  'page.cerp.changehistory.column.title.user_phone': 'User Phone', // '手机号',
  'page.cerp.changehistory.column.title.user_name': 'User Name', // '操作人',
  'page.cerp.changehistory.column.title.old_json': 'Old JSON', // '旧数据json',
  'page.cerp.changehistory.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.changehistory.form.label.model_name': 'Model Name', // 'model名称',
  'page.cerp.changehistory.form.label.model_str': 'Model String', // 'model标记',
  'page.cerp.changehistory.form.placeholder.model_name': 'Please fill in Model Name', // '请填写model名称',
  'page.cerp.changehistory.form.placeholder.model_str': 'Please fill in Model String', // '请填写model标记',
  'page.cerp.contract.colums.column.title.accommodation_rate': 'Accommodation Rate', // '住宿费率',
  'page.cerp.contract.colums.column.title.action': 'Action', // '操作',
  'page.cerp.contract.colums.column.title.adviser__full_name': 'Consultant', // '隶属顾问',
  'page.cerp.contract.colums.column.title.adviser__employee_number': 'Employee Number', // '工号',
  'page.cerp.contract.colums.column.title.appendix_json_list': 'Attachments', // '附件',
  'page.cerp.contract.colums.column.title.wechat_appendix_json_list': 'Wechat Attachments', // '附件',
  'page.cerp.contract.colums.column.title.insurance__effective_date_time': 'Insurance Time', // '保险起保日期',
  'page.cerp.contract.colums.column.title.insurance__expiry_date_time': 'Insurance Time', // '保险停保日期',
  'page.cerp.contract.colums.column.title.bank_charge': 'Bank Charge', // '银行转账费',
  'page.cerp.contract.colums.column.title.consultant_bank_charge': 'Consultant Bank Charge', // '顾问转账费',
  'page.cerp.contract.colums.column.title.company_bank_charge': 'Company Bank Charge', // '顾问公司转账费',
  'page.cerp.contract.colums.column.title.basic_rate': 'Basic Rate', // '基本单位薪资',
  'page.cerp.contract.colums.column.title.cc_email__name': 'CC Email', // 'CC邮箱',
  'page.cerp.contract.colums.column.title.cc_management_fee': 'CC Management Fee', // 'cc管理费',
  'page.cerp.contract.colums.column.title.cc_management_fee_rate': 'CC Management Fee Rate (%)', // 'cc管理费率（%）',
  'page.cerp.contract.colums.column.title.founder': 'Founder', // '创建人',
  'page.cerp.contract.colums.column.title.reviser': 'Reviser', // '修改人',
  'page.cerp.contract.colums.column.title.income_tax': 'PAY INDIVIDUAL INCOME TAX', // '个税代缴',
  'page.cerp.contract.colums.column.title.social_insurance': 'SOCIAL SECURITY PAYMENT', // '社保代缴',
  'page.cerp.contract.colums.column.title.fte': 'full time equivalent number', // '全时约当数',
  'page.cerp.contract.colums.column.title.annual_leave': 'number_of_annual_leave', // '年假天数',
  'page.cerp.contract.colums.column.title.work_hour_template_id':
    'time confirmation email template', // '工时确认邮箱模板',
  'page.cerp.contract.colums.column.title.solution_type': 'Solution Type', // '是否运用本地解决方案',
  'page.cerp.contract.colums.column.title.creater': 'Creator', // '当前合同负责人',
  'page.cerp.contract.colums.column.title.currency__currency': 'Currency', // '薪资币种',
  'page.cerp.contract.colums.column.title.pay_currency__currency': 'Pay Currency', // '付款币种',
  'page.cerp.contract.colums.column.title.email_record__log': 'Email log', // '邮件发送日志',
  'page.cerp.contract.colums.column.title.email_record__status': 'Email Status', // '邮件发送状态',
  'page.cerp.contract.colums.column.title.email_record__create_time': 'Email sent time', // '邮件发送时间',
  'page.cerp.contract.colums.column.title.expiration_email_record__log': 'Expiration Email log', // '邮件发送日志',
  'page.cerp.contract.colums.column.title.expiration_email_record__status':
    'Expiration Email Status', // '邮件发送状态',
  'page.cerp.contract.colums.column.title.expiration_email_record__create_time':
    'Expiration Email sent time', // '邮件发送时间',
  'page.cerp.contract.colums.column.title.sign_email_record__log': 'Sign Email log', // '邮件发送日志',
  'page.cerp.contract.colums.column.title.sign_email_record__status': 'Sign Email Status', // '邮件发送状态',
  'page.cerp.contract.colums.column.title.sign_email_record__create_time': 'Sign Email sent time', // '邮件发送时间',
  'page.cerp.contract.colums.column.title.confirm_date': 'confirm Date', //'顾问签署时间',
  'page.cerp.contract.colums.column.title.cc_sign_date': 'CC Sign Date', //'CC签署时间',
  'page.cerp.contract.colums.column.title.end_client__name': 'End Client', // '终端客户',
  'page.cerp.contract.colums.column.title.end_date': 'Contract End Date', // '合同结束日期',
  'page.cerp.contract.colums.column.title.id': 'ID', // 'ID',
  'page.cerp.contract.colums.column.title.index': 'Index', // '序号',
  'page.cerp.contract.colums.column.title.insurance_type': 'Insurance Type', // '保险类别',
  'page.cerp.contract.colums.column.title.invoice_email_template__name': 'Invoice Email Template', // '请款单邮箱模板',
  'page.cerp.contract.colums.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.contract.colums.column.title.is_bank_charge_payment': 'Bank Charge Payment Method', // '银行转账费支付方式',
  'page.cerp.contract.colums.column.title.is_current': 'Is Current Contract', // '是否当前合同',
  'page.cerp.contract.colums.column.title.is_insurance_payment': 'Insurance Payment Method', // '保险费支付方式',
  'page.cerp.contract.colums.column.title.local_deduct_fee': 'Local Deduction Total (IT+SS etc)', // '本地扣减费',
  'page.cerp.contract.colums.column.title.local_salary': 'Local Salary', // '本地发薪数值',
  'page.cerp.contract.colums.column.title.local_management_fee': 'Local Management Fee', // '本地管理费',
  'page.cerp.contract.colums.column.title.local_management_fee_fixed': 'Local Management Fixed', // '本地发薪工资',
  'page.cerp.contract.colums.column.title.local_pay_currency__currency': 'Local Pay Currency', // '本地发薪币种',
  'page.cerp.contract.colums.column.title.local_management_fee_rate':
    'Local Management Fee Rate (%)', // '本地管理费率（%）',
  'page.cerp.contract.colums.column.title.management_fee_formula__name': 'Management Fee Formula', // '管理费计算方式',
  'page.cerp.contract.colums.column.title.management_fee_payment_flag':
    'Management Fee Payment Method', // '管理费支付方式',
  'page.cerp.contract.colums.column.title.overtime_rate': 'Overtime Rate', // '加班费率',
  'page.cerp.contract.colums.column.title.is_three_upload': 'Three Upload', // '三方合同上传',
  'page.cerp.contract.colums.column.title.is_double_upload': 'Double Upload', // '双方合同上传',
  'page.cerp.contract.colums.column.title.payslip_email_template__name': 'Payslip Email Template', // '工资单邮箱模板',
  'page.cerp.contract.colums.column.title.tip_day': 'Tip Day', // '合同到期提醒天数',
  'page.cerp.contract.colums.column.title.expiration_email_template__name':
    'Expiration Email Template', // '合同到期提醒邮件模板',
  'page.cerp.contract.colums.column.title.sign_email_template__name': 'Sign Email Template', // '签署合同确认邮件模板',
  'page.cerp.contract.colums.column.title.qiyuesuo_contract_id': 'Qiyuesuo Contract ID', // '契约锁合同ID',
  'page.cerp.contract.colums.column.title.qiyuesuo_status': 'Contract Status', // '契约锁合同状态',
  'page.cerp.contract.colums.column.title.rate_type': 'Payment Type', // '薪资种类',
  'page.cerp.contract.colums.column.title.recuitment_company__company_name': 'Recruitment Company', // '顾问公司',
  'page.cerp.contract.colums.column.title.recuitment_company__vat_no': 'VAT Number', // 'VATNo',
  'page.cerp.contract.colums.column.title.remark': 'Note', // '备注',
  'page.cerp.contract.colums.column.title.tip__name': 'Important tips', // '重要提示',
  'page.cerp.contract.colums.column.title.start_date': 'Contract Start Date', // '合同起始日期',
  'page.cerp.contract.colums.column.title.termination_date': 'Contract End Date', // '终止日期',
  'page.cerp.contract.colums.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.contract.colums.column.title.work_location__name': 'Work Location', // '工作地点',
  'page.cerp.contract.form.label.address': 'Address', // '地址',
  'page.cerp.contract.form.label.adviser_id': 'Consultant', // '隶属顾问',
  'page.cerp.contract.form.label.cc_company_id': 'CC Company', // 'CC公司',
  'page.cerp.contract.form.label.cc_email_id': 'CC Email', // 'CC邮箱',
  'page.cerp.contract.form.label.contact_number': 'Contact Number', // '联系方式',
  'page.cerp.contract.form.label.contact_person_id': 'Contact Person', // '顾问公司联系人',
  'page.cerp.contract.form.label.contract_template_id': 'Contract Template ID', // '合同模板',
  'page.cerp.contract.form.label.currency_id': 'Currency', // '薪资币种',
  'page.cerp.contract.form.label.end_client_id': 'End Client', // '终端用户',
  'page.cerp.contract.form.label.insurance_type': 'Insurance Type', // '保险类型',
  'page.cerp.contract.form.label.invoice_email_template_id': 'Invoice Email Template', // '请款单邮箱模板',
  'page.cerp.contract.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.contract.form.label.is_bank_charge_payment': 'Bank Charge Payment Method', // '银行转账费支付方式',
  'page.cerp.contract.form.label.is_current': 'Is Current Contract', // '是否当前合同',
  'page.cerp.contract.form.label.solution_type': 'whether there is a local solution', // '是否有本地解决方案',
  'page.cerp.contract.form.label.is_insurance_payment': 'Insurance Payment Method', // '保险费支付方式',
  'page.cerp.contract.form.label.management_fee_formula_id': 'Management Fee Formula', // '管理费计算方式',
  'page.cerp.contract.form.label.management_fee_payment_flag': 'Management Fee Payment Method', // '管理费支付方式',
  'page.cerp.contract.form.label.name': 'Name', // '名称',
  'page.cerp.contract.form.label.payslip_email_template_id': 'Payslip Email Template', // '工资单邮箱模板',
  'page.cerp.contract.form.label.rate_type': 'Payment Type', // '薪资种类',
  'page.cerp.contract.form.label.recuitment_company_id': 'Recruitment Company', // '顾问公司',
  'page.cerp.contract.form.label.recuitment_company_person_id':
    'Recruitment Company Contact Person', // '请款邮箱地址',
  'page.cerp.contract.form.label.remark': 'Note', // '备注',
  'page.cerp.contract.form.label.search_text': 'Search Text', // '模糊搜索',
  'page.cerp.contract.form.label.work_location_id': 'Work Location', // '工作地点',
  'page.cerp.contract.form.placeholder.address': 'Please fill in address', // '请填写地址',
  'page.cerp.contract.form.placeholder.adviser_id': 'Consultant', // '隶属顾问',
  'page.cerp.contract.form.placeholder.cc_company_id': 'Please select CC Company', // '请选择CC公司',
  'page.cerp.contract.form.placeholder.cc_email_id': 'Please select CC Email', // '请选择CC邮箱',
  'page.cerp.contract.form.placeholder.contact_number': 'Please fill in contact number', // '请填写联系方式',
  'page.cerp.contract.form.placeholder.contact_person_id':
    'Please select recruitment company contact person', // '请选择顾问公司联系人',
  'page.cerp.contract.form.placeholder.contract_template_id': 'Please select contract template', // '请选择合同模板',
  'page.cerp.contract.form.placeholder.currency_id': 'Please select payment currency', // '请选择薪资币种',
  'page.cerp.contract.form.placeholder.end_client_id': 'Please select end client', // '请选择终端用户',
  'page.cerp.contract.form.placeholder.insurance_type': 'Please select insurance type', // '请选择保险类型',
  'page.cerp.contract.form.placeholder.invoice_email_template_id':
    'Please select invoice email template', // '请选择请款单邮箱模板',
  'page.cerp.contract.form.placeholder.management_fee_formula_id':
    'Please select management fee formula', // '请选择管理费计算方式',
  'page.cerp.contract.form.placeholder.name': 'Please fill in name', // '请填写名称',
  'page.cerp.contract.form.placeholder.payslip_email_template_id':
    'Please select payslip email template', // '请选择工资单邮箱模板',
  'page.cerp.contract.form.placeholder.rate_type': 'Please select payment type', // '请选择薪资种类',
  'page.cerp.contract.form.placeholder.recuitment_company_id': 'Please select recruitment company', // '请选择顾问公司',
  'page.cerp.contract.form.placeholder.recuitment_company_person_id':
    'Please select invoice contact person', // '请选择请款邮箱地址',
  'page.cerp.contract.form.placeholder.remark': 'Please fill in note', // '请填写备注',
  'page.cerp.contract.form.placeholder.search_text': 'Please fill in search text', // '请填写搜索内容',
  'page.cerp.contract.form.placeholder.work_location_id': 'Please fill in work location', // '请选择工作地点',
  'page.cerp.customfield.column.title.action': 'Action', // '操作',
  'page.cerp.customfield.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.customfield.column.title.custom_field_name': 'Custom Field Name', // '自定义项描述',
  'page.cerp.customfield.column.title.custom_field_value': 'Custom Field Value', // '自定义项值',
  'page.cerp.customfield.column.title.id': 'ID', // 'ID',
  'page.cerp.customfield.column.title.index': 'Index', // '序号',
  'page.cerp.customfield.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.customfield.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.customfield.form.label.custom_field_name': 'Custom Field Name', // '自定义项名字',
  'page.cerp.customfield.form.label.custom_field_set_id': 'Custom Field Set ID', // '自定义项集',
  'page.cerp.customfield.form.label.custom_field_value': 'Custom Field Value', // '自定义项值',
  'page.cerp.customfield.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.customfield.form.label.search_text': 'Search Text', // '模糊搜索',
  'page.cerp.customfield.form.placeholder.custom_field_name': 'Please fill in custom field name', // '请填写自定义项名字',
  'page.cerp.customfield.form.placeholder.custom_field_set_id':
    'Please fill in custom field set ID', // '请选择自定义项集',
  'page.cerp.customfield.form.placeholder.custom_field_value': 'Please fill in custom field value', // '请填写自定义项值',
  'page.cerp.customfield.form.placeholder.is_active': 'Please indicate if active', // '请选择是否可用',
  'page.cerp.customfield.form.placeholder.search_text': 'Please fill in search text', // '请填写搜索内容',
  'page.cerp.customfieldset.column.title.action': 'Action', // '操作',
  'page.cerp.customfieldset.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.customfieldset.column.title.description': 'Description', // '描述',
  'page.cerp.customfieldset.column.title.id': 'ID', // 'ID',
  'page.cerp.customfieldset.column.title.index': 'Index', // '序号',
  'page.cerp.customfieldset.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.customfieldset.column.title.name': 'Custom Field Set', // '证件类别名',
  'page.cerp.customfieldset.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.customfieldset.form.label.description': 'Description', // '描述',
  'page.cerp.customfieldset.form.label.name': 'Custom Field Set Name', // '证件类别名',
  'page.cerp.customfieldset.form.placeholder.description': 'Please fill in description', // '请填写描述',
  'page.cerp.customfieldset.form.placeholder.name': 'Please fill in name', // '请填写证件类别名',
  'page.cerp.insurance.column.title.action': 'Action', // '操作',
  'page.cerp.insurance.column.title.adviser.full_name': 'Consultant', // '隶属顾问',
  'page.cerp.insurance.column.title.appendix_json': 'Policy', // '保单',
  'page.cerp.insurance.column.title.other_appendix_json': 'Other Attachment', // '附件',
  'page.cerp.insurance.column.title.beneficiaries': 'Beneficialry', // '受益人',
  'page.cerp.insurance.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.insurance.column.title.effective_date_time': 'Start Date', // '起保日期时间',
  'page.cerp.insurance.column.title.expiry_date_time': 'Expiry Date', // '停保日期时间',
  'page.cerp.insurance.column.title.id': 'ID', // 'ID',
  'page.cerp.insurance.column.title.index': 'Index', // '序号',
  'page.cerp.insurance.column.title.insurance_company.company_name': 'Insurance Company Name', // '保险公司',
  'page.cerp.insurance.column.title.insurance_fee': 'Insurance Fee', // '保费',
  'page.cerp.insurance.column.title.insurance_type': 'Insurance Type', // '保险类型',
  'page.cerp.insurance.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.insurance.column.title.is_current': 'Is Current', // '当前保险',
  'page.cerp.insurance.column.title.month_insurance_fee': 'Monthly insurance fee', // '每月保费',
  'page.cerp.insurance.column.title.remark': 'Remark', // '备注',
  'page.cerp.insurance.column.title.policy_no': 'Insurance Policy Number', // '保险单号',
  'page.cerp.insurance.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.insurance.form.label.adviser__full_name': 'Consultant Name', // '顾问',
  'page.cerp.insurance.form.label.adviser_id': 'Consultant ID', // '隶属顾问',
  'page.cerp.insurance.form.label.effective_date_time': 'Start Date and Time', // '起保日期时间',
  'page.cerp.insurance.form.label.expiry_date_time': 'Expiry date and time', // '停保日期时间',
  'page.cerp.insurance.form.label.insurance_company_id': 'Insurance Company', // '保险公司',
  'page.cerp.insurance.form.label.insurance_fee': 'Insurance Fee', // '保费',
  'page.cerp.insurance.form.label.is_current': 'Is Current', // '当前保险',
  'page.cerp.insurance.form.label.month_insurance_fee': 'Monthly Insurance Fee', // '每月保费',
  'page.cerp.insurance.form.label.policy_no': 'Policy Number', // '保险单号',
  'page.cerp.insurance.form.placeholder.adviser__full_name': 'Please select consultant name', // '请选择隶属顾问',
  'page.cerp.insurance.form.placeholder.adviser_id': 'Please select consultant', // '隶属顾问',
  'page.cerp.insurance.form.placeholder.effective_date_time': 'Please fill in Insurance start date', // '请填写起保日期时间',
  'page.cerp.insurance.form.placeholder.expiry_date_time': 'Please fill in insurance end date', // '请填写停保日期时间',
  'page.cerp.insurance.form.placeholder.insurance_company_id': 'Please select insurance company', // '请选择保险公司',
  'page.cerp.insurance.form.placeholder.insurance_fee': 'Please fill in insurance fee', // '请填写保费',
  'page.cerp.insurance.form.placeholder.is_current': 'Please indicate if this is count', // '请填写当前保险',
  'page.cerp.insurance.form.placeholder.month_insurance_fee':
    'Please fill in monthly insurance fed', // '请填写每月保费',
  'page.cerp.insurance.form.placeholder.policy_no': 'Please fill in the policy number', // '请填写保险单号',
  'page.cerp.invoice.column.title.accommodation_amount': 'Total Accommodation Amount', // '住宿总额',
  'page.cerp.invoice.column.title.accommodation_unit': 'Accomodation rate ', // '宿款单位',
  'page.cerp.invoice.column.title.action': 'Action', // '操作',
  'page.cerp.invoice.column.title.adviser__full_name': 'Consutltnat', // '顾问',
  'page.cerp.invoice.column.title.adviser__employee_number': 'Employee Number', // '工号',
  'page.cerp.invoice.column.title.adviser__end_date': 'Adviser End Date', // '顾问结束日期',
  'page.cerp.invoice.column.title.adviser__status': 'Adviser Status', // '顾问个人状态',
  'page.cerp.invoice.column.title.bank_account__account_name': 'Bank Account name', // 'CC收款账户',
  'page.cerp.invoice.column.title.founder': 'Founder', // '创建人',
  'page.cerp.invoice.column.title.reviser': 'Reviser', // '修改人',
  'page.cerp.invoice.column.title.appendix_json': 'working hours', // '工时证明文件',
  'page.cerp.invoice.column.title.payment_status': 'Payment Status', // '付款状态',
  'page.cerp.invoice.column.title.bank_account__account_number': 'Account Number', // '账号',
  'page.cerp.invoice.column.title.bank_charge_cost': 'Bank Charge', // '银行转账费',
  'page.cerp.invoice.column.title.basic_amount': 'Basic Pay', // '基本工资总额',
  'page.cerp.invoice.column.title.contract__end_date': 'Contract End Date', // '合同结束日期',
  'page.cerp.invoice.column.title.contract__start_date': 'Contract Start Date', // '合同起始日期',
  'page.cerp.invoice.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.invoice.column.title.date_submitted': 'Date Submitted', // '提交日期',
  'page.cerp.invoice.column.title.email_record__log': 'Email Log', // '邮件发送日志',
  'page.cerp.invoice.column.title.email_record__status': 'Email Status', // '邮件发送状态',
  'page.cerp.invoice.column.title.email_record__create_time': 'Email Sent Timestamp', // '邮件发送时间',
  'page.cerp.invoice.column.title.email_record__update_time': 'Email Update Timestamp', // '邮件更新时间',
  'page.cerp.invoice.column.title.excel_files_json': 'Excel', // 'Excel',
  'page.cerp.payslip.column.title.exchange_rate': 'Exchange Rate', // '汇率',
  'page.cerp.invoice.column.title.files_json': 'PDF', // 'PDF',
  'page.cerp.invoice.column.title.id': 'ID', // 'ID',
  'page.cerp.invoice.column.title.index': 'Index', // '序号',
  'page.cerp.invoice.column.title.insurance_fee': 'Insurance Fee', // '保险费',
  'page.cerp.invoice.column.title.invoice_end_date': 'Invoice End Date', // '请款截止日期',
  'page.cerp.invoice.column.title.invoice_no': 'Invoice Number', // '请款单号',
  'page.cerp.invoice.column.title.invoice_start_date': 'Invoice Start Date', // '请款起始日期',
  'page.cerp.invoice.column.title.invoice_type': 'Invoice Type', // '请款类型',
  'page.cerp.invoice.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.invoice.column.title.management_fee': 'Management Fee', // '管理费',
  'page.cerp.invoice.column.title.overtime': 'Overtime Unit', // '加班单位',
  'page.cerp.invoice.column.title.overtime_amount': 'Overtime Amount', // '加班总额',
  'page.cerp.invoice.column.title.payment_term': 'Payment Term', // '付款周期（天）',
  'page.cerp.invoice.column.title.remark': 'Note', // '备注',
  'page.cerp.invoice.column.title.remittance__amount': 'Remittance Amount', // '汇款金额',
  'page.cerp.invoice.column.title.remittance__remittance_set__remittance_name':
    'Remittance Item Name', // '汇款凭证项',
  'page.cerp.invoice.column.title.service_month': 'Invoice Month', // '服务月份',
  'page.cerp.invoice.column.title.status': 'Status', // '状态',
  'page.cerp.invoice.column.title.total_amount': 'Total Amount', // '总金额',
  'page.cerp.invoice.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.invoice.column.title.work_unit': 'Work Unit', // '普通工时单位',
  'page.cerp.invoice.form.label.adviser_id': 'Consultant', // '隶属顾问',
  'page.cerp.invoice.form.label.bank_account_id': 'Bank Account', // '银行账户',
  'page.cerp.invoice.form.label.invoice_type': 'Invoice Type', // '请款类型',
  'page.cerp.invoice.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.invoice.form.label.is_remittance': 'Is Remittance', // '是否创建汇款凭证项',
  'page.cerp.invoice.form.label.payment_method': 'Payment Method', // '付款方式',
  'page.cerp.invoice.form.label.service_month': 'Service Month', // '服务月份',
  'page.cerp.invoice.form.label.status': 'Status', // '状态',
  'page.cerp.invoice.form.placeholder.adviser_id': 'Please select consultant', // '隶属顾问',
  'page.cerp.invoice.form.placeholder.bank_account_id': 'Please select bank account', // '请选择银行账户',
  'page.cerp.invoice.form.placeholder.invoice_type': 'Please select invoice type', // '请选择请款类型',
  'page.cerp.invoice.form.placeholder.is_active': 'Please indicate if active', // '请选择是否可用',
  'page.cerp.invoice.form.placeholder.payment_method': 'Please fill in payment method', // '请填写payment_method',
  'page.cerp.invoice.form.placeholder.service_month': 'Please fill in service month', // '请填写开始时间',
  'page.cerp.invoice.form.placeholder.status': 'Please fill in status', // '请选择状态',
  'page.cerp.nextofkin.column.title.action': 'Action', // '操作',
  'page.cerp.nextofkin.column.title.adviser__full_name': 'Consultant', // '隶属顾问',
  'page.cerp.nextofkin.column.title.contact_number': 'Contact Number', // '联系电话',
  'page.cerp.nextofkin.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.nextofkin.column.title.email': 'Email', // '联系邮箱',
  'page.cerp.nextofkin.column.title.id': 'ID', // 'ID',
  'page.cerp.nextofkin.column.title.index': 'Index', // '序号',
  'page.cerp.nextofkin.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.nextofkin.column.title.name': 'Name', // '姓名',
  'page.cerp.nextofkin.column.title.releationship': 'Relationship', // '关系',
  'page.cerp.nextofkin.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.nextofkin.form.label.adviser__full_name': 'Consultant Name', // '隶属顾问',
  'page.cerp.nextofkin.form.label.adviser_id': 'Consultant ID', // '隶属顾问',
  'page.cerp.nextofkin.form.label.contact_number': 'Contact Number', // '联系电话',
  'page.cerp.nextofkin.form.label.email': 'Email', // '联系邮箱',
  'page.cerp.nextofkin.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.nextofkin.form.label.name': 'Name', // '姓名',
  'page.cerp.nextofkin.form.label.releationship': 'Relationship', // '关系',
  'page.cerp.nextofkin.form.label.search_text': 'Search Text', // '模糊搜索',
  'page.cerp.nextofkin.form.placeholder.adviser_id': 'Please select consultant', // '隶属顾问',
  'page.cerp.nextofkin.form.placeholder.contact_number': 'Please fill in contact number', // '请填写联系电话',
  'page.cerp.nextofkin.form.placeholder.email': 'Please fill in email', // '请填写联系邮箱',
  'page.cerp.nextofkin.form.placeholder.is_active': 'Please indicate if active', // '请选择是否可用',
  'page.cerp.nextofkin.form.placeholder.name': 'Please fill in name', // '请填写姓名',
  'page.cerp.nextofkin.form.placeholder.releationship': 'Please fill in relationship', // '请填写关系',
  'page.cerp.nextofkin.form.placeholder.search_text': 'Please fill in search text', // '请填写搜索内容',
  'page.cerp.note.column.title.action': 'Action', // '操作',
  'page.cerp.note.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.note.column.title.date': 'Date', // '日期',
  'page.cerp.note.column.title.id': 'ID', // 'ID',
  'page.cerp.note.column.title.index': 'Index', // '序号',
  'page.cerp.note.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.note.column.title.note': 'Note', // '记录',
  'page.cerp.note.column.title.note_type__name': 'Note Type', // '记录类型',
  'page.cerp.note.column.title.note_type_id': 'Note Type ID', // '记录类型',
  'page.cerp.note.column.title.object_id': 'Description', // '描述',
  'page.cerp.note.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.note.form.label.date': 'Date', // '日期',
  'page.cerp.note.form.label.note': 'Note', // '记录',
  'page.cerp.note.form.label.note_type_id': 'Note Type ID', // '记录类型',
  'page.cerp.note.form.label.object_id': 'Description', // '描述',
  'page.cerp.note.form.placeholder.date': 'Please fill in date', // '请填写日期',
  'page.cerp.note.form.placeholder.note': 'Please fill in note', // '请填写记录',
  'page.cerp.note.form.placeholder.note_type_id': 'Please select note type', // '请选择记录类型',
  'page.cerp.note.form.placeholder.object_id': 'Please fill in description', // '请填写描述',
  'page.cerp.payslip.column.title.action': 'Action', // '操作',
  'page.cerp.payslip.column.title.adviser__full_name': 'Consultant', // '顾问',
  'page.cerp.payslip.column.title.bank_charge_cost': 'Bank Charge', // '银行转账费',
  'page.cerp.payslip.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.payslip.column.title.to_do_num': 'To do num', // '代办未完成',
  'page.cerp.payslip.column.title.pay_together_status': 'Pay Together Status', // '合并付款状态',
  'page.cerp.payslip.column.title.date': 'Date', // '收到日期',
  'page.cerp.payslip.column.title.email_record__log': 'Email Log', // '邮件发送日志',
  'page.cerp.payslip.column.title.email_record__status': 'Email Status', // '邮件发送状态',
  'page.cerp.payslip.column.title.email_record__create_time': 'Email sent time', // '邮件发送时间',
  'page.cerp.payslip.column.title.check_value': 'Check Value', // '核验',
  'page.cerp.remittanceset.column.title.recuitment_company__company_name': 'Company Name',
  'page.cerp.remittanceset.column.title.bank_charge__name': 'Bank Charge Name',
  'page.cerp.remittanceset.column.title.bank_charge__amount': 'Bank Charge Amount',
  'page.cerp.payslip.column.title.invoice__remittance__remittance_set__remittance_name':
    'Set Remittance Name', // '所属remittance',
  'page.cerp.payslip.column.title.excel_files_json': 'Excel', // 'Excel',
  'page.cerp.payslip.column.title.expenses': 'Expenses', // '报销金额',
  'page.cerp.payslip.column.title.files_json': 'PDF', // 'PDF',
  'page.cerp.payslip.column.title.id': 'ID', // 'ID',
  'page.cerp.payslip.column.title.index': 'Index', // '序号',
  'page.cerp.payslip.column.title.insurance_fee': 'Insurance', // '保险费',
  'page.cerp.payslip.column.title.invoice__invoice_no': 'Invoice Number', // '请款单',
  'page.cerp.payslip.column.title.invoice_type': 'Invoice Type', // '请款类型',
  'page.cerp.payslip.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.payslip.column.title.payment_status': 'Payment Status', // '付款状态',
  'page.cerp.payslip.column.title.is_valid': 'Is Valid', // '是否有效',
  'page.cerp.payslip.column.title.management_fee': 'Management Fee', // '管理费',
  'page.cerp.payslip.column.title.payment_method': 'Payment Method', // '付款方式',
  'page.cerp.payslip.column.title.referral_fees': 'Referral Fee', // '推荐费',
  'page.cerp.payslip.column.title.remark': 'Note', // '工资单备注',
  'page.cerp.payslip.column.title.adviser__person_note': 'Adviser Note', // '顾问备注',
  'page.cerp.payslip.column.title.status': 'Status', // '状态',
  'page.cerp.payslip.column.title.total_amount': 'Total Amount', // '总金额',
  'page.cerp.payslip.column.title.founder': 'Founder', // '创建人',
  'page.cerp.payslip.column.title.reviser': 'Reviser', // '修改人',
  'page.cerp.payslip.column.title.transaction__name': 'Transaction', // '银行流水',
  'page.cerp.payslip.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.payslip.column.title.invoice__contract__currency__currency': 'Contract Currency', //'合同薪资币种',
  'page.cerp.payslip.column.title.pay_currency__currency': 'Payment Currency', //'付款币种',
  'page.cerp.payslip.column.title.invoice__contract__local_pay_currency__currency':
    'Local Payment Currency', // '本地币种',
  'page.cerp.payslip.column.title.cc_bank_account__account_flag': 'Payment Bank Account', //'CC付款账户',
  'page.cerp.payslip.form.label.adviser__full_name': 'Consultant', // '顾问',
  'page.cerp.payslip.form.label.adviser_id': 'Consultant ID', // '隶属顾问',
  'page.cerp.payslip.form.label.bank_charge_cost': 'Bank Charge Cost', // '银行转账费',
  'page.cerp.payslip.form.label.custom_4': 'Custom4', // '自定义4',
  'page.cerp.payslip.form.label.date': 'Date', // '收到日期',
  'page.cerp.payslip.form.label.deduct_details': 'Deduction Details', // '额外抵扣细节',
  'page.cerp.payslip.form.label.deduct_value': 'Deduction Amount', // '额外抵扣金额',
  'page.cerp.payslip.form.label.eraning_details': 'Earning Details', // '额外收入细节',
  'page.cerp.payslip.form.label.eraning_value': 'Earning Amount', // '额外收入金额',
  'page.cerp.payslip.form.label.expenses': 'Expenses', // '报销金额',
  'page.cerp.payslip.form.label.insurance_fee': 'Insurance Fee', // '保险费',
  'page.cerp.payslip.form.label.invoice_id': 'Invoice ID', // '请款单',
  'page.cerp.payslip.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.payslip.form.label.payment_method': 'Payment Method', // '付款方式',
  'page.cerp.payslip.form.label.referral_fees': 'Referral Fee', // '推荐费',
  'page.cerp.payslip.form.label.remark': 'Note', // '备注',
  'page.cerp.payslip.form.label.status': 'Status', // '状态',
  'page.cerp.payslip.form.label.transaction_id': 'Transaction ID', // '银行流水',
  'page.cerp.payslip.form.placeholder.adviser__full_name': 'Please select consultant', // '请选择隶属顾问',
  'page.cerp.payslip.form.placeholder.adviser_id': 'Please select consultant', // '请选择隶属顾问',
  'page.cerp.payslip.form.placeholder.bank_charge_cost': 'Bank charge', // '请填写银行转账费',
  'page.cerp.payslip.form.placeholder.custom_4': 'Custom4', // '请填写自定义4',
  'page.cerp.payslip.form.placeholder.date': 'please fill in date', // '请填写收到日期',
  'page.cerp.payslip.form.placeholder.deduct_details': 'Please fill in details of deductions', // '请填写额外抵扣细节',
  'page.cerp.payslip.form.placeholder.deduct_value': 'Please enter additional deduction', // '请填写额外抵扣金额',
  'page.cerp.payslip.form.placeholder.eraning_details': 'Please fill in extra earning for', // '请填写额外收入细节',
  'page.cerp.payslip.form.placeholder.eraning_value': 'Please oil in the expo', // '请填写额外收入金额',
  'page.cerp.payslip.form.placeholder.expenses': 'Please fill in expenses blank seemm', // '请填写报销金额',
  'page.cerp.payslip.form.placeholder.insurance_fee': 'Please fill in insurance', // '请填写保险费',
  'page.cerp.payslip.form.placeholder.invoice_id': 'Please select invoice', // '请选择请款单',
  'page.cerp.payslip.form.placeholder.is_active': 'Please indicate if ', // '请选择是否可用',
  'page.cerp.payslip.form.placeholder.payment_method': 'Please select payment method', // '请填写付款方式',
  'page.cerp.payslip.form.placeholder.referral_fees': 'Please fill in referral fee', // '请填写推荐费',
  'page.cerp.payslip.form.placeholder.remark': 'Please fill in note', // '请填写备注',
  'page.cerp.payslip.form.placeholder.status': 'Please select tag', // '请选择状态',
  'page.cerp.payslip.form.placeholder.transaction_id': 'Please select ', // '请选择银行流水',
  'page.cerp.personreferencenumber.column.title.action': 'Action', // '操作',
  'page.cerp.personreferencenumber.column.title.adviser__full_name': 'Consultant', // '隶属顾问',
  'page.cerp.personreferencenumber.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.personreferencenumber.column.title.description': 'Description', // '描述',
  'page.cerp.personreferencenumber.column.title.end_date': 'End date', // '截止时间',
  'page.cerp.personreferencenumber.column.title.id': 'ID', // 'ID',
  'page.cerp.personreferencenumber.column.title.index': 'Index', // '序号',
  'page.cerp.personreferencenumber.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.personreferencenumber.column.title.name': 'Name', // '名称',
  'page.cerp.personreferencenumber.column.title.reference_number_type__name':
    'Reference Number Type', // '参考号类别',
  'page.cerp.personreferencenumber.column.title.appendix_json_list': 'Attachments', // '附件',
  'page.cerp.personreferencenumber.column.title.start_date': 'Start Date', // '开始时间',
  'page.cerp.personreferencenumber.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.personreferencenumber.form.label.adviser__full_name': 'Consultant Name', // '隶属顾问',
  'page.cerp.personreferencenumber.form.label.adviser_id': 'Consultant ID', // '隶属顾问',
  'page.cerp.personreferencenumber.form.label.description': 'Description', // '描述',
  'page.cerp.personreferencenumber.form.label.end_date': 'End Date', // '截止时间',
  'page.cerp.personreferencenumber.form.label.is_active': 'Is Active', // '是否可用',
  'page.cerp.personreferencenumber.form.label.name': 'Name', // '名称',
  'page.cerp.personreferencenumber.form.label.reference_number_type_id': 'Reference Number Type ID', // '参考号类别',
  'page.cerp.personreferencenumber.form.label.search_text': 'Search Text', // '模糊搜索',
  'page.cerp.personreferencenumber.form.label.start_date': 'Start Date', // '开始时间',
  'page.cerp.personreferencenumber.form.placeholder.adviser_id': 'Consultant ID', // '隶属顾问',
  'page.cerp.personreferencenumber.form.placeholder.description': 'Please fill in description', // '请填写描述',
  'page.cerp.personreferencenumber.form.placeholder.end_date': 'Please fill in end date', // '请填写截止时间',
  'page.cerp.personreferencenumber.form.placeholder.is_active': 'Please indicate if active', // '请选择是否可用',
  'page.cerp.personreferencenumber.form.placeholder.name': 'Please fill in name', // '请填写名称',
  'page.cerp.personreferencenumber.form.placeholder.reference_number_type_id':
    'Please fill in reference number type ID', // '请选择参考号类别',
  'page.cerp.personreferencenumber.form.placeholder.search_text': 'Please fill in search text', // '请填写搜索内容',
  'page.cerp.personreferencenumber.form.placeholder.start_date': 'Please fill in start date', // '请填写开始时间',
  'page.cerp.remittance.column.title.action': 'Action', // '操作',
  'page.cerp.remittance.column.title.local_solution': 'Local Solution', // '本地解决方案',
  'page.cerp.remittance.column.title.adviser__full_name': 'Consultant Name', // '顾问名称',
  'page.cerp.remittance.column.title.amount': 'Amount', // '金额',
  'page.cerp.remittance.column.title.cc_bank_account__account_number': 'CC Bank Account Number', // '付款账号',
  'page.cerp.remittance.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.remittance.column.title.description': 'Description', // '描述',
  'page.cerp.remittance.column.title.id': 'ID', // 'ID',
  'page.cerp.remittance.column.title.index': 'Index', // '序号',
  'page.cerp.remittance.column.title.invoice_no': 'Invoice Number', // '请款单',
  'page.cerp.remittance.column.title.invoice_status': 'Invoice Status', // '请款单状态',
  'page.cerp.remittance.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.remittance.column.title.payslip_no': 'Payslip Number', // '工资单',
  'page.cerp.remittance.column.title.remittance_set__remittance_name': 'Remittance Name', // '汇款凭证',
  'page.cerp.remittance.column.title.remittance_set__total_amount': 'Remittance Total Amount', // '汇款凭证总金额',
  'page.cerp.remittance.column.title.transaction_amount': 'Transaction Amount', // '付款金额',
  'page.cerp.remittance.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.remittance.form.label.amount': 'Amount', // '金额',
  'page.cerp.remittance.form.label.invoice_id': 'Invoice ID', // '请款单',
  'page.cerp.remittance.form.label.is_active': 'Is Active', // '状态',
  'page.cerp.remittance.form.label.remittance_set_id': 'Remittance ID', // '汇款凭证',
  'page.cerp.remittance.form.placeholder.amount': 'Please fill in amount', // '请填写金额',
  'page.cerp.remittance.form.placeholder.invoice_id': 'Please fill in invoice ID', // '请选择请款单',
  'page.cerp.remittance.form.placeholder.is_active': 'Please indicate if active', // '请选择状态',
  'page.cerp.remittance.form.placeholder.remittance_set_id': 'Please select remittance', // '请选择汇款凭证',
  'page.cerp.remittanceset.column.title.action': 'Action', // '操作',
  'page.cerp.remittanceset.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.remittanceset.column.title.date_received': 'Received Date', // '日期',
  'page.cerp.remittanceset.column.title.description': 'Description', // '描述',
  'page.cerp.remittanceset.column.title.current_amount': 'Current Amount', // '当前金额',
  'page.cerp.remittanceset.column.title.invoice_total_amount': 'Invoice Total Amount', // '请款单总额',
  'page.cerp.remittanceset.column.title.id': 'ID', // 'ID',
  'page.cerp.remittanceset.column.title.index': 'Index', // '序号',
  'page.cerp.remittanceset.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.remittanceset.column.title.remittance_name': 'Remittance Name', // '名称',
  'page.cerp.remittanceset.column.title.total_amount': 'Total Amount', // '总金额',
  'page.cerp.remittanceset.column.title.transaction__name': 'Bank Transaction Name', // '银行流水',
  'page.cerp.remittanceset.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.remittanceset.column.title.transaction__currency__currency': 'Transaction Currency', // '银行流水币种',
  'page.cerp.remittanceset.column.title.currency__currency': 'Currency', // '币种',
  'page.cerp.remittanceset.column.title.transaction__amount': 'Bank Transaction Amount', //'银行流水金额',
  'page.cerp.remittanceset.form.label.date_received': 'Date', // '请款日期',
  'page.cerp.remittanceset.form.label.description': 'Description', // '描述',
  'page.cerp.remittanceset.form.label.is_active': 'Is Active', // '状态',
  'page.cerp.remittanceset.form.label.remittance_name': 'Remittance Name', // '名称',
  'page.cerp.remittanceset.form.label.total_amount': 'Total Amount', // '总金额',
  'page.cerp.remittanceset.form.label.transaction_id': 'Bank Transaction ID', // '银行流水',
  'page.cerp.remittanceset.form.label.bank_charge_id': 'Bank Charge',
  'page.cerp.remittanceset.form.placeholder.date_received': 'Please fill in date', // '请填写请款日期',
  'page.cerp.remittanceset.form.placeholder.description': 'Please fill in description', // '请填描述',
  'page.cerp.remittanceset.form.placeholder.is_active': 'Please indicate if active', // '请选择状态',
  'page.cerp.remittanceset.form.placeholder.remittance_name': 'Please fill in remittance name', // '请填写名称',
  'page.cerp.remittanceset.form.placeholder.total_amount': 'Please fill in total amount', // '请填总金额',
  'page.cerp.remittanceset.form.placeholder.transaction_id': 'Please fill in transaction ID', // '请选择银行流水',
  'page.cerp.todoevent.column.title.action': 'Action', // '操作',
  'page.cerp.todoevent.column.title.content': 'Content', // '内容',
  'page.cerp.todoevent.column.title.adviser__full_name': 'Adviser Full Name', // '顾问名称',
  'page.cerp.todoevent.column.title.adviser__employee_number': 'Employee Number', // '顾问工号',
  'page.cerp.todoevent.column.title.priority': 'Priority', // '优先等级',
  'page.cerp.todoevent.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.todoevent.column.title.index': 'Index', // '序号',
  'page.cerp.todoevent.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.todoevent.column.title.is_finished': 'Is Finished', // '是否完成',
  'page.cerp.todoevent.column.title.person_name': 'Person assigned to', // '执行人',
  'page.cerp.todoevent.column.title.alarm_number': 'Alarm Number', // '提醒间隔天数',
  'page.cerp.todoevent.column.title.alarm_time': 'Alarm Time', // '提醒时间',
  'page.cerp.todoevent.column.title.person_phone': 'Phone number', // '执行人手机号',
  'page.cerp.todoevent.column.title.title': 'Title', // '标题',
  'page.cerp.todoevent.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.todoevent.form.label.adviser_id': 'Consultant ID', // '顾问',
  'page.cerp.todoevent.form.label.content': 'Content', // '内容',
  'page.cerp.todoevent.form.label.is_active': 'Is Active', // '状态',
  'page.cerp.todoevent.form.label.is_finished': 'Is Finished', // '是否完成',
  'page.cerp.todoevent.form.label.is_asc': 'Prioritization', // '优先级排序‘,
  'page.cerp.todoevent.form.label.q': 'Search Text', // '模糊搜索',
  'page.cerp.todoevent.form.label.status': 'Status', // '紧迫状态',
  'page.cerp.todoevent.form.label.title': 'Title', // '标题',
  'page.cerp.todoevent.form.placeholder.adviser_id': 'Please fill in consultant ID', // '隶属顾问',
  'page.cerp.todoevent.form.placeholder.content': 'Please fill in content', // '请填写内容',
  'page.cerp.todoevent.form.placeholder.is_active': 'Please indicate if active', // '请选择状态',
  'page.cerp.todoevent.form.placeholder.is_finished': 'Please indicate if finished', // '请选择是否完成',
  'page.cerp.todoevent.form.placeholder.q': 'Please fill in search text', // '请填写搜索内容',
  'page.cerp.todoevent.form.placeholder.status': 'Please select status', // '请选择紧迫状态',
  'page.cerp.todoevent.form.placeholder.title': 'Please fill in title', // '请填写标题'
  'router.title.adviser-manage': 'Data Management', // '数据管理'
  'router.title.cerp-adviser': 'Consultant', // '顾问'
  'router.title.basic-balance_statement': 'Balance Statement', // '余额报表'
  'router.title.cerp-bankaccount': 'Consultant Bank Account', // '顾问银行账户'
  'router.title.cerp-bankaccount1': 'Insurance Company Bank Account', // '保险公司银行账户'
  'router.title.cerp-banktransaction': 'Bank Transaction', // '银行流水'
  'router.title.cerp-contract': 'Contract', // '合同'
  'router.title.cerp-insurance': 'Insurance', // '保险'
  'router.title.cerp-beneficiary': 'Insurance Beneficiary', // '保险受益人'
  'router.title.cerp-remittanceset': 'Remittance', // '汇款凭证'
  'router.title.cerp-invoice': 'Invoice', // '请款单'
  'router.title.cerp-payslip': 'Payslip', // '工资单'
  'router.title.cerp-payslip_report': 'Charges Statement', // '工资单报表'
  'router.title.cerp-address': 'Address', // '地址管理'
  'router.title.cerp-nextofkin': 'Next of Kin', // '紧急联系人'
  'router.title.cerp-note': 'Note', // '记录'
  'router.title.cerp-personreferencenumber': 'Person Reference Number', // '个人参考号'
  'router.title.cerp-todoevent': 'To-Do List', // '待办事项'
  'router.title.cerp-wechatupdaterecord': 'WeChat Programme Data', // '微信小程序数据'
  'router.title.cerp-extra_field': 'Extra Field', // '赠扣提醒'
  'router.title.cerp-social_insurance': 'Social Insurance', // '社保菜单'
  'router.title.cerp-tiprecord': 'Tip Record', // '重要信息查看记录'
  'page.baseinfo.markdown.form.label.title': 'Title', // '公告名称'
  'page.baseinfo.markdown.form.placeholder.title': 'Please fill in Title', // '请填写公告名称'
  'page.baseinfo.tag.form.label.name': 'Name', // '名称'
  'page.baseinfo.tag.form.placeholder.name': 'Please fill in Name', // '请填写名称'
  'page.cerp.tiprecord.column.title.index': 'Index', // '序号',
  'page.cerp.tiprecord.column.title.id': 'ID', // 'ID',
  'page.cerp.tiprecord.column.title.adviser__full_name': 'Adviser Full Name', // '顾问名称',
  'page.cerp.tiprecord.column.title.adviser__employee_number': 'Employee Number', // '顾问工号',
  'page.cerp.tiprecord.column.title.tip__name': 'Tip Name', // '重要提示名称',
  'page.cerp.tiprecord.column.title.contract__start_date': 'Contract Start Date', // '合同起始日期',
  'page.cerp.tiprecord.column.title.contract__end_date': 'Contract End Date', // '合同结束日期',
  'page.cerp.tiprecord.column.title.is_active': 'Is Active', // '状态',
  'page.cerp.tiprecord.column.title.action': 'Action', // '操作',

  'page.cerp.adviser.form.label.user_email_template_id': 'User mailbox template', // '用户邮箱模板',
  'page.cerp.adviser.form.label.cc_bank_account_id': 'CC bank account', // 'CC银行账户',
  'page.cerp.adviser.form.label.end_date': 'Consultant end date', // '顾问结束日期',
  'page.cerp.adviser.form.placeholder.pay_currency_id': 'Please select payment currency', // '请选择付款币种',
  'page.cerp.adviser.form.placeholder.user_email_template_id':
    'Please select a user mailbox template', // '请选择用户邮箱模板',
  'page.cerp.adviser.form.placeholder.cc_bank_account_id': 'Please select CC bank account', // '请选择CC银行账户',
  'page.cerp.adviser.form.placeholder.end_date': 'Please select consultant end date', // '请选择顾问结束日期',
  'router.title.cerp-additional_item_abnormal_data': 'Additional item abnormal data', // '额外项异常数据',
  'page.cerp.contract.colums.column.title.cc_company__name': 'CC Company Name', // CC公司
  'page.cerp.contract.colums.column.title.insurance_payment_flag': 'Insurance Payment Way', // '保险费支付方式',
  'page.cerp.contract.colums.column.title.create_time': 'Timestamp', // '创建时间',
  'page.cerp.adviser.editModal.column.title.creater': 'Founder', // '创建人',
  'page.cerp.adviser.editModal.column.title.employee_number': 'Employee Number', // '工号',
  'page.cerp.adviser.editModal.column.title.full_name': 'Name', // '姓名',
  'page.cerp.adviser.editModal.column.title.home_address__display_name': 'Home Address', // '家庭地址',
  'page.cerp.adviser.editModal.column.title.id': 'ID', // 'ID',
  'page.cerp.adviser.editModal.column.title.index': 'index', // '序号',
  'page.cerp.adviser.editModal.column.title.is_active': 'Is Active', // '是否可用',
  'page.cerp.adviser.editModal.column.title.mobile': 'Mobile', // '手机',
  'page.cerp.adviser.editModal.column.title.nationality__name': 'Nationality ID', // '国籍',
  'page.cerp.adviser.editModal.column.title.other_name': 'Other name', // '其他姓名',
  'page.cerp.adviser.editModal.column.title.person_type__name': 'Person type ID', // '个人类别',
  'page.cerp.adviser.editModal.column.title.personal_email': 'Personal email', // '个人邮箱',
  'page.cerp.adviser.editModal.column.title.phone': 'Phone', // '电话',
  'page.cerp.adviser.editModal.column.title.qq': 'QQ', // 'QQ账号',
  'page.cerp.adviser.editModal.column.title.skypelid': 'Skype ID', // 'Skype ID',
  'page.cerp.adviser.editModal.column.title.status__name': 'Status', // '个人状态',
  'page.cerp.adviser.editModal.column.title.title': 'Salutation', // '称谓',
  'page.cerp.adviser.editModal.column.title.update_time': 'Timestamp', // '更新时间',
  'page.cerp.adviser.editModal.column.title.wechat': 'WeChat', // '微信账号',
  'page.cerp.adviser.editModal.column.title.whats_app': 'Whats app', // 'WhatsApp账号',
  'page.cerp.adviser.editModal.column.title.work_address__display_name': 'Work Address', // '工作地址',
  'page.cerp.adviser.editModal.column.title.pay_currency__currency': 'Payment Currency', // '付款币种',

  序号: 'Index',
  ID: 'ID',
  票据种类: 'Receipt Type',
  创建时间: 'Timestamp',
  修改时间: 'Timestamp',
  是否可用: 'Is Active',
  操作: 'Action',
  请款单模板: 'Payment request template',
  工资单模板: 'Payroll template',

  名称: 'name',
  请填写名称: 'Please fill in name',
  票据种类名称: 'Invoice Type Name',
  请填写票据种类名称: 'Please fill in Invoice Type Name',
  描述: 'Description',
  请填写描述: 'Please fill in Description',
  新建票据种类: 'New Invoice Type',
  修改票据种类: 'Modify Invoice Type',
  可用: 'Available',
  禁用: 'Deleted',
  全部: 'All',
  票据合同模板名称: 'Invoice Contract Template Name',
  请填写票据合同模板名称: 'Please fill in Invoice Contract Template Name',
  模板数据: 'Template Data',
  请填写模板数据: 'Please fill in Template Data',
  票据合同模板: 'Invoice Contract Template',
  新建票据合同模板: 'New Invoice Contract Template',
  修改票据合同模板: 'Modify Invoice Contract Template',
  上传附件: 'Upload attachment',
  上传票据合同模板附件: 'Upload Invoice Contract Template attachment',
  上传成功: 'Upload successful',
  票据模板名称: 'Invoice Template Name',
  请填写票据模板名称: 'Please fill in Invoice Template Name',
  自定义项数量: 'Custom Field Number',
  请填写自定义项数量: 'Please fill in custom field number',
  票据模板: 'Invoice Template',
  新建票据模板: 'New Invoice Template',
  修改票据模板: 'Modify Invoice Template',
  提示: 'Reminder',
  无对应附件: 'No matching attachment',
  无对应付款记录: 'No matching payment reocrd',
  上传票据模板附件: 'Upload Invoice Template attachment',
  发票号: 'Receipt Number',
  请填写发票号: 'Please fill in Receipt Number',
  客户类型: 'Client Type',
  请选择客户类型: 'Please select Client Type',
  客户属性: 'Client Property',
  请选择客户属性: 'Please select Client Property',
  隶属顾问: 'Associated consultant',
  顾问公司: 'Recruitment Company',
  请选择顾问公司: 'Please select Recruitment Company',
  CC公司: 'CC Company',
  合同CC公司: 'Contract CC Company',
  请选择CC公司: 'Please select CC Company',
  是否自动发送付款凭证: 'Whether to automatically send Payment Voucher',
  请选择是否自动发送付款凭证: 'Please select whether to automatically send Payment Voucher',
  是否需要增扣提醒: 'Whether to add additional deduction reminder',
  请选择是否需要增扣提醒: 'Please select whether to add additional deduction reminder',
  顾问: 'Consultant',
  个人: 'Person',
  公司: 'Company',
  其他: 'Other',
  客户管理: 'Client Management',
  地址: 'Address',
  电话: 'Telephone',
  邮箱: 'Email',
  备注: 'Note',
  修改: 'Modify',
  修改客户信息: 'Modify Client Information',
  客户名称: 'Client Name',
  请填写备注: 'Please fill in Note',
  请填写邮箱: 'Please fill in Email',
  请填写电话: 'Please fill in Telephone',
  请填写地址: 'Please fill in Address',
  请填写客户名称: 'Please fill in Client Name',
  新建成功: 'Created successfully',
  创建顾问: 'New Consultant',
  '个人邮箱、工作邮箱至少要填写一个': 'Please fill in either personal email, work email or both',
  创建顾问公司: 'New Recruitment Company',
  CC银行账户: 'CC Bank Account',
  请选择CC银行账户: 'Please select CC Bank Account',
  请填输入顾问公司名: 'Please fill in Recruitment Company Name',
  请填输入顾问公司地址: 'Please fill in Recruitment Company Address',
  请填输入顾问公司联系方式: 'Please fill in Recruitment Company Contact Details',
  请填输入VATNo: 'Please fill in VAT Number',
  创建CC公司: 'Create CC Company',
  请填写公司名称: 'Please fill in Company Name',
  请选择国家: 'Please select Country',
  新建客户: 'New Client',
  客户: 'Client',
  请选择客户: 'Please select Client',
  请选择票据合同模板: 'Please select Invoice Contract Template',
  请选择票据模板: 'Please select Invoice Template',
  请选择付款凭证邮件模板: 'Please select Payment Voucher Template',
  请填写CC公司: 'Please fill in CC Company',
  合同起始时间: 'Contract Start Date',
  合同结束时间: 'Contract End Date',
  合同状态: 'Contract Status',
  票据合同数据: 'Invoice Contract Data',
  发票模板: 'Invoice Template',
  CC邮箱: 'CC Email',
  请选择CC邮箱: 'Please select CC Email',
  合同邮件模板: 'Contract Email Template',
  发票邮件模板: 'Invoice Email Template',
  付款凭证邮件模板: 'Payment Voucher Email Template',
  附件: 'Attachment',
  邮件发送记录: 'Email Sent Record',
  邮件发送状态: 'Email Sent Status',
  发送成功: 'Sent Successfully',
  发送失败: 'Sent Failed',
  已发送: 'Sent',
  邮件发送时间: 'Email Sent Timestamp',
  付款凭证邮件发送记录: 'Payment Voucher Email Sent Record',
  付款凭证邮件发送状态: 'Payment Voucher Email Sent Status',
  付款凭证邮件发送时间: 'Payment Voucher Email Sent Timestamp',
  CC签署日期: 'CC Signed Timestamp',
  客户确认日期: 'Client Confirmed Timestamp',
  三方合同上传: 'Upload Tripartite Contract',
  是: 'Yes',
  否: 'No',
  双方合同上传: 'Upload Contract',
  修改票据合同: 'Modify Invoice Contract',
  '请选择合同起始-结束日期': 'Please select Contract Start - End Date',
  '合同起始-结束日期': 'Contract Start - End Date',
  合同邮箱模板: 'Contract Email Template',
  请选择合同邮箱模板: 'Please select Contract Email Template',
  请选择发票邮件模板: 'Please select Invoice Email Template',
  签署合同确认邮件: 'Contract Signed Confirmation Email',
  请选择签署合同确认邮件: 'Please select Contract Signed Confirmation Email',
  修改成功: 'Modify Successful',
  上传合同附件: 'Upload Contract Attachment',
  添加成功: 'Added Successful',
  预览合同PDF: 'Preview Contract PDF',
  操作成功: 'Successful',
  发送邮件: 'Send Email',
  手动发送邮件: 'Send Email Manually',
  发送付款凭证邮件: 'Send Payment Voucher Email',
  确认三方合同上传成功: 'Confirm successful Tripartite Contract Upload',
  作废三方合同: 'Cancel Tripartite Contract',
  双方合同上传确认: 'Confirm successful Contract Upload',
  作废双方合同: 'Cancel Contract',
  CC签署: 'CC Signature',
  新建发票: 'New Invoice',
  请选择票据种类: 'Please select Invoice Type',
  请选择票据合同: 'Please select Invoice Contract',
  票据合同: 'Invoice Contract',
  发票类型: 'Invoice Type',
  开票日期: 'Invoice Date',
  请选择开票日期: 'Please select Invoice Date',
  服务月份: 'Service Month',
  服务描述: 'Service Description',
  价格: 'PRICE',
  数量: 'QTY',
  请选择服务月份: 'Please select Service Month',
  总金额: 'Total amount',
  请填写总金额: 'Please fill in Total amount',
  币种: 'Currency',
  请选择币种: 'Please select currency',
  发票数据: 'Invoice Information',
  请填写发票数据: 'Please fill in Invoice Information',
  收取发票: 'Received Invoice',
  开具发票: 'Issued Invoice',
  新建票据合同: 'New Invoice Contract',
  付款日期: 'Payment Date',
  请选择付款日期: 'Please select Payment Date',
  发票清单: 'Invoice List',
  付款状态: 'Payment Status',
  未付款: 'Unpaid',
  已付款: 'Paid',
  增扣项类型: 'Additional Charge Type',
  增扣项: 'Additional Charge',
  增扣项值: 'Additional Charge Value',
  增扣项状态: 'Additional Charge Status',
  修改发票: 'Modify Invoice',
  修改发票信息: 'Modify Invoice Information',
  新增发票信息: 'New Invoice Information',
  上传发票附件: 'Upload Invoice Attachment',
  生成发票PDF: 'Generate Invoice PDF',
  预览发票PDF: 'Preview Invoice PDF',
  生成付款凭证: 'Generate Payment Voucher',
  预览付款凭证: 'Preview Payment Voucher',
  无对应PDF: 'No matching PDF',
  新建增扣项: 'New Additional Charge',
  类型: 'Type',
  请选择隶属顾问: 'Please select Associated Consultant',
  增扣类型: 'Additional Charge Type',
  自定义项描述: 'Custom Item Description',
  请填写自定义项描述: 'Please fill in Custom Item Description',
  自定义项值: 'Custom Item Value',
  请填写自定义项值: 'Please fill in Custom Item Value',
  内容: 'Content',
  请填写内容: 'Please fill in Content',
  请选择月份: 'Please select Month',
  错误: 'Error',
  合同无可选月份: 'No available Contract Month',
  请款单: 'Invoice',
  工资单: 'Payslip',
  扣减: 'Deduction',
  增补: 'Supplement',
  删除增扣项: 'Delete Additional Charge Item',
  新建付款记录: 'New Payment Record',
  发票: 'Invoice',
  请选择发票: 'Please select Invoice',
  请填写付款日期: 'Please fill in Payment Date',
  请选择银行流水: 'Please select Bank Transaction',
  银行流水: 'Bank Transaction',
  新建银行流水: 'New Bank Transaction',
  收到日期: 'Date Received',
  请填写收到日期: 'Please fill in Date Received',
  流水种类: 'Bank Transaction Type',
  请选择流水种类: 'Please select Bank Transaction Type',
  创建成功: 'Create Successful',
  付款记录: 'Payment Record',
  付款金额: 'Payment Amount',
  付款币种: 'Payment Currency',
  付款时间: 'Payment Timestamp',
  流水金额: 'Bank Transaction Amount',
  流水币种: 'Bank Transaction Currency',
  修改付款记录: 'Modify Payment Record',
  添加银行流水: 'Add Bank Transaction',
  请填写银行流水: 'Please fill in Bank Transaction',
  请选择薪资币种: 'Please select Salary Currency',
  日历年份: 'Calendar Year',
  请填写日历年份: 'Please fill in Calendar Year',
  工时年历: 'Timesheet Calendar',
  修改工时模板: 'Modify Timesheet Template',
  年份: 'Year',
  国家: 'Country',
  工时年历详情: 'Timesheet Calendar Details',
  新建工时模板: 'New Timesheet Template',
  工时日历模板: 'Timesheet Calendar Template',
  设定法定节假日: 'Set Public Holidays',
  删除: 'Delete',
  工时录入: 'Timesheet entry',
  '开始-结束时间': 'Start - End Date',
  '请填写开始-结束时间': 'Please fill in Start - End Date',
  节日名字: 'Public Holiday Name',
  请填写节日名字: 'Please fill in Public Holiday Name',
  修改法定节假日: 'Modify Public Holiday',
  日期: 'Date',
  请填写日期: 'Please fill in Date',
  删除成功: 'Delete Successful',
  请填写工时: 'Please fill in Work Hours',
  工时: 'Work Hours',
  是否删除该日法定节假日: 'Are you sure to delete this Public Holiday',
  票据管理: 'Invoice Management',

  例子: 'Example',
  是否银行账户信息齐全: 'Is bank account information complete',
  顾问待签合同: "Consultant's Pending Contract",
  顾问合同未签署测试: 'Consultant contract not signed for testing',
  待办事项: 'To Do',
  重要提示缺失: 'Important Reminder missing',
  顾问合同开始提醒: 'Consultant contract start reminder',
  顾问合同结束提示: 'Consultant contract end reminder',
  缺少三方合同: 'Missing tripartite contract',
  缺少双方合同: 'Missing contract',
  保险缺失: 'Insurance missing',
  保险受益人缺失: 'Insurance Beneficiary missing',
  保险即将过期或已过期: 'Insurance is about to expire or has expired',
  '合同截止，保险未截止': 'Contract expired, insurance not yet expired',
  '小程序/顾问端待审批': 'Mini Program/Consultant Portal pending approval',
  小程序银行账户信息更新待审批: 'Mini program bank account information update pending approval',
  顾问银行资料缺失: 'Missing consultant bank information',
  请款超过12天未收到汇款凭证:
    'Remittance not received after invoice has been sent for more than 12 days',
  中国籍工作中顾问当前合同无小程序附件:
    'There is no mini program attachment in the current contract of Chinese consultants',
  顾问合同下月到期提醒: 'Reminder of consultant contract expiration next month',
  CC待签署合同: 'CC Contract to be signed',
  收起: 'Collapse',
  展开: 'Expand',
  收起展开: 'Collapse and Expand',
  配置: 'Config',
  关闭配置: 'Turn off config',
  基本列信息: 'Basic column information',
  设为基础配置: 'Set as Basic Config',
  展示自定义列: 'Display custom columns',
  显示: 'Display',
  隐藏: 'Hidden',
  用户: 'User',
  原始: 'Original',
  导出Excel: 'Export to Excel',
  刷新: 'Refresh',
  密度: 'Density',
  默认: 'Default',
  中等: 'Medium',
  紧凑: 'Compact',
  列展示: 'Column Display',
  列设置: 'Column Settings',
  全屏: 'Full screen',
  退出全屏: 'Exit full screen',
  合计: 'Total',
  '显示/隐藏操作列': 'Show/Hide Action Column',
  标题: 'Title',
  优先等级: 'Priority level',
  顾问名称: 'Consultant Name',
  顾问工号: 'Consultant ID',
  执行人: 'Executor',
  执行人手机号: "Executor's mobile number",
  是否完成: 'Completed',
  更新时间: 'Update time',
  无: 'None',
  工作中: 'On site',
  结束: 'Off site',
  申请签证中: 'Applying for a visa',
  尚未开始: 'Not started',
  待定: 'Pending',
  新数据json: 'New data JSON',
  旧数据json: 'Old data JSON',
  未审批: 'Pending',
  通过: 'Approved',
  拒绝: 'Rejected',
  审批状态: 'Approval status',
  modal名称: 'modal name ',
  modal标记: 'modal tag ',
  新创建: 'New',
  已收到请款: 'Remittance Received',
  已作废: 'Voided',
  生成工资单: 'Generate Payslip',
  生成PDF: 'PDF Generate',
  垫付: 'Advance payment',
  pdf生成状态: 'PDF generation status',
  未开始: 'Not started',
  正在生成: 'Generating',
  失败: 'Failed',
  成功: 'Success',
  pdf生成日志: 'PDF generated log',
  工资: 'Salary',
  报销: 'Expense',
  加班: 'Overtime',
  CC报销: 'CC Expense',
  是否删除: 'Delete',
  日薪: 'Daily salary',
  月薪: 'Monthly salary',
  年薪: 'Annual salary',
  时薪: 'Hourly salary',
  周薪: 'Weekly salary',
  需要: 'Required',
  顾问支付: 'Consultant Pay',
  顾问公司支付: 'Recuitment Company Pay',
  草稿: 'Draft',
  发起合同: 'Initiate Contract',
  顾问签署: "Consultant's Pending Contract",
  签署完成: 'Signing completed',
  手动发送: 'Manual sending',
  '不需要–顾问在本地': 'No need - consultant is local',
  '不需要–顾问自己有保单': 'No need - the consultant has their own policy',
  '不需要–顾问公司保险': 'No need - recuitment company insurance',
  静态信息管理: 'Static Data Management',
  首页自定义查询看板: 'Custom Query Dashboard on Homepage',
  查询: 'Query',
  重置: 'Reset',
  订阅: 'Subscription',
  请填写: 'Please fill in ',
  请选择: 'Please select ',
  '是否删除此项记录?': 'Do you want to delete this record?',
  恢复成功: 'Recovery successful',
  保存成功: 'Saved',
  邮箱模板: 'Email template',
  查询表类型: 'Query Table Type',
  排序: 'Sort',
  待办: 'To Do',
  合同: 'Contract',
  保险: 'Insurance',
  微信小程序: 'WeChat Mini Program',
  是否定时发送邮件: 'If schedule to send',
  '自动发送邮件频率（天）': 'Automatic email sending frequency (days)',
  首页自定义查询看板管理: 'Home page custom query dashboard management',
  新建: 'New',
  请填写查询表类型: 'Please fill in the query table type',
  请选择请邮箱模板: 'Please select the email template',
  请填写排序: 'Please fill in sorting',
  查询代码: 'Query Code',
  银行账户: 'Bank account',
  银行名称: 'Bank name',
  请填写银行名称: 'Please fill in the bank name',
  银行地址: 'Bank address',
  请填写银行地址: 'Please fill in the bank address',
  账户代号: 'Account code',
  请填写账户代号: 'Please fill in the account code',
  新建CC银行账户: 'New CC Bank Account',
  修改CC银行账户: 'Modify CC Bank Account',
  币种关联: 'Currency association',
  账号: 'Account number',
  请填输入账号: 'Please enter the account number',
  添加备注: 'Add note',
  条备注: 'Notes',
  关闭: 'Close',
  修改币种关联: 'Modify currency association',
  CC公司管理: 'CC Company Management',
  新建CC公司: 'New CC Company',
  修改CC公司: 'Modify CC Company',
  上传签章模板: 'Upload signature template',
  上传请款单模板: 'Upload invoice template',
  上传工资单模板: 'Upload payslip template',
  上传logo: 'Upload logo',
  保存: 'Save',
  取消: 'Cancel',
  确定上传: 'OK to upload',
  上传: 'Upload',
  '只能上传一个模板，默认上传第一个':
    'Only one template can be uploaded, the first one will be uploaded by default,',
  CC公司合同模板: 'CC Company Contract Template',
  请款邮箱地址: 'Invoice email address',
  请选择请款邮箱地址: 'Please select the invoice email address',
  请款单邮箱模板: 'Invoice email template',
  请款时顾问提示邮箱模板: 'Consultant prompts email template for invoice',
  合同模板: 'Contract template',
  签署合同确认邮箱模板: 'Contract signing confirmation email template',
  合同到期提醒邮箱模板: 'Contract expiration reminder email template',
  工资单邮箱模板: 'Payslip email template',
  CC报销请款单邮箱模板: 'CC expense invoice email template',
  报销请款单邮箱模板: 'Expense email template',
  加班请款单邮箱模板: 'Overtime invoice email template',
  CN请款单邮箱模板: 'CN invoice email template',
  请选择CN请款单邮箱模板: 'Please select the CN invoice email template',
  请选择加班请款单邮箱模板: 'Please select the overtime invoice email template',
  请选择报销请款单邮箱模板: 'Please select the expense invoice email template',
  请选择CC报销请款单邮箱模板: 'Please select the CC expense invoice email template',
  请选择工资单邮箱模板: 'Please select a payslip email template',
  请选择签署合同确认邮箱模板: 'Please select the contract signing confirmation email template',
  请选择合同到期提醒邮箱模板: 'Please select the email template for contract expiration reminder',
  请选择合同模板: 'Please select a contract template',
  请选择请款时顾问提示邮箱模板:
    'Please select the email template prompted by the consultant when sending a invoice',
  请选择请款单邮箱模板: 'Please select the invoice email template',
  CC公司合同录入模板: 'CC Company Contract Entry Template',
  请款邮箱模板: 'Invoice email template',
  cc报销请款单邮箱模板: 'CC expense invoice email template',
  国家管理: 'Country management',
  新建国家: 'New country',
  修改国家: 'Modify country',
  ID类别: 'ID Category',
  保险公司: 'Insurance Company',
  新建保险公司: 'New Insurance Company',
  修改保险公司: 'Modify Insurance Company',
  点击添加: 'Click to add',
  理赔流程: 'Claim process',
  代码内容: 'Code content',
  新增银行账户: 'New Bank Account',
  请选择保险公司: 'Please select an insurance company',
  是否当前账号: 'Active account',
  银行英文名称: 'Bank English Name',
  请填写开户行地址: 'Please fill in the account opening bank address',
  账户持有人: 'Account holder',
  开户行地址: 'Opening Bank Address',
  请填写账号: 'Please fill in the account number',
  请填写账户持有人: 'Please fill in the account holder',
  请选择银行英文名称: 'Please select the English name of the bank',
  请选择银行名称: 'Please select bank name',
  请选择预设银行名称: 'Please select the default bank name',
  '预设银行名称(非必选)': 'Default bank name (not mandatory)',
  记录类型: 'Record type',
  记录类型管理: 'Record Type Management',
  新建记录类型: 'New record type',
  修改记录类型: 'Modify record type',
  个人类别: 'Person type',
  个人类别管理: 'Person type management',
  新建个人类别: 'New person type',
  修改个人类别: 'Modify person type',
  顾问公司管理: 'Recuitment Company Management',
  新建顾问公司: 'New Recuitment Company',
  修改顾问公司: 'Modify Recuitment Company',
  顾问公司ID: 'Recuitment Company ID',
  请填写顾问公司ID: 'Please fill in the recuitment company ID',
  顾问公司名: 'Recuitment Company Name',
  请填写顾问公司名: 'Please fill in the name of the recuitment company',
  顾问公司地址: 'Recuitment Company Address',
  请填写顾问公司地址: 'Please fill in the address of the recuitment company',
  顾问公司联系方式: 'Contact information of recuitment company',
  请填写顾问公司联系方式: 'Please fill in the contact information of the recuitment company',
  顾问公司联系人: 'Contact person of recuitment company',
  顾问公司联系人管理: 'Recuitment Company Contact Management',
  新建顾问公司联系人: 'New Recuitment Company Contact Person',
  修改顾问公司联系人: 'Modify Recuitment Company Contact Person',
  联系方式: 'Contact information',
  请填写联系方式: 'Please provide contact information',
  请填写请款单发送邮箱: 'Please fill in the invoice send to email',
  请款单发送邮箱: 'Invoice send to email',
  参考号类别: 'Reference Number Type',
  参考号类别管理: 'Reference Number Type Management',
  新建参考号类别: 'New reference number type',
  修改参考号类别: 'Modify reference number type',
  管理费计算方式: 'Management fee calculation method',
  修改管理费计算方式: 'Modify management fee calculation method',
  CC邮箱管理: 'CC mailbox management',
  修改CC邮箱: 'Modify CC mailbox',
  文本: 'Text',
  html: 'HTML',
  修改邮箱模板: 'Modify email template',
  图片展示: 'Image display',
  未上传图片: 'Image not uploaded',
  上传图片: 'Upload Image',
  确认删除图片: 'Confirm deletion of image',
  删除图片: 'Delete image',
  '只能上传一张图片，默认上传第一张':
    'Only one image can be uploaded, the first one will be uploaded by default,',
  收件箱地址管理: 'Inbox Address Management',
  数据变动管理: 'Data Change Management',
  数据变动: 'Data change',
  确定: 'OK',
  选择: 'Select',
  修改合同模板: 'Modify Contract Template',
  未上传合同模板: 'Contract template not uploaded',
  上传模板: 'Upload Template',
  银行管理: 'Bank Management',
  终端用户: 'End client',
  待办优先等级: 'To Do Priority',
  新建待办优先等级: 'New to-do priority level',
  修改待办优先等级: 'Modify to-do priority level',
  客服问题管理: 'Customer Service Issue Management',
  分组名称: 'Group Name',
  问题分组管理: 'Problem Group Management',
  新建分组: 'New Group',
  修改分组: 'Modify grouping',
  问题列表: 'Issue List',
  新建客服问题: 'New customer service issue',
  修改客服问题: 'Modify customer service issue',
  问题: 'Question',
  请填写问题: 'Please fill in the question',
  答案: 'Answer',
  隶属问题分组: 'Subordinate problem grouping',
  请选择隶属问题分组: 'Please select the question group to which you belong',
  请填写答案: 'Please fill in the answer',
  汇率: 'Exchange rate',
  修改汇率: 'Modify exchange rate',
  合同币种: 'Contract Currency',
  请选择对应币种: 'Please select the corresponding currency',
  本地币种: 'Local currency',
  请选择本地币种: 'Please select local currency',
  微信小程序主页: 'WeChat Mini Program Home Page',
  修改微信小程序主页: 'Modify WeChat Mini Program Home Page',
  图片: 'Image',
  更换图片: 'Change image',
  修改文字: 'Modify Text',
  请填写文字: 'Please fill in the text',
  文字: 'Text',
  标签: 'Label',
  请填写标签名称: 'Please fill in the label name',
  标签管理: 'Label Management',
  新建标签: 'New label',
  公告与重要信息: 'Announcement and Important Information',
  请填写标题: 'Please fill in the title',
  请选择标签: 'Please select the label',
  公司公告: 'Company Announcement',
  重要信息: 'Important Information',
  已读人员: 'Read user',
  已读数: 'Number of read',
  未读人员: 'Unread user',
  未读数: 'Number of unread',
  '新建公告（重要信息）': 'New Announcement (Important Information)',
  上传PDF: 'Upload PDF',
  详情: 'Details',
  公告阅读记录: 'Announcement Reading Record',
  公告内容: 'Announcement content',
  '是否删除?': 'Do you want to delete it?',
  重要提示: 'Important reminder',
  新建重要提示: 'New Important Information',
  修改重要提示: 'Modify Important Information',
  模板: 'Template',
  提示内容: 'Prompt content',
  请填写提示内容: 'Please fill in the prompt content',
  入职流程提示: 'Onboarding process prompt',
  请填写每月保费: 'Please fill in the monthly premium',
  每月保费: 'Monthly premium',
  保费成本: 'Premium cost',
  请填写保费成本: 'Please fill in the premium cost',
  保险类型管理: 'Insurance Type Management',
  新建保险类型: 'New insurance type',
  年: 'Year',
  正序: 'Positive sequence',
  倒序: 'Reverse order',
  离职: 'Resignation',
  潜在雇员: 'Potential employee',
  顾问管理: 'Consultant Management',
  新建顾问: 'New Consultant',
  修改顾问: 'Modify Consultant',
  请先删除顾问结束日期: 'Please delete consultant end date first',
  删除顾问结束日期: 'Delete consultant end date',
  是否删除顾问结束日期: 'Whether to delete consultant end date',
  小程序解绑: 'Mini program unbinding',
  是否小程序解绑: 'Whether to unbind the mini program',
  创建账号: 'Create account',
  用户邮箱模板: 'User email template',
  请选择用户邮箱模板: 'Please select a user email template',
  用户名: 'User Name',
  删除账号: 'Delete account',
  是否删除账号: 'Whether to Delete account',
  确认发送邮件: 'Confirm sending email',
  恢复账号: 'Restore account',
  恢复账号成功: 'Account recovery successful',
  重置密码: 'Reset password',
  重置成功: 'Reset successful',
  新顾问: 'New consultant',
  '-无-': 'None',
  详情页: 'Details page',
  已绑定微信小程序: 'Unbound WeChat Mini Program',
  未绑定微信小程序: 'Not yet bounded WeChat Mini Program',
  新建待办事项: 'New to-do list',
  删除结束时间: 'Delete End Time',
  称谓: 'Title',
  手机: 'Mobile phone',
  微信账号: 'WeChat account',
  WhatsApp: 'WhatsApp',
  个人状态: 'Personal status',
  国籍: 'Nationality',
  个人邮箱: 'Personal email',
  QQ账号: 'QQ account',
  保险信息: 'Insurance Information',
  出生日期: 'Date of Birth',
  工作邮箱: 'Work email',
  其他名字: 'Other names',
  顾问结束日期: 'Consultant End Date',
  顾问账号名: 'Consultant account name',
  顾问账号状态: 'Consultant account status',
  cc银行账户: 'CC Bank Account',
  家庭地址: 'Home address',
  工作地址: 'Work address',
  当前合同负责人: 'Current Contract Manager',
  展开当前合同: 'Expand current contract',
  职务: 'Position',
  项目名称: 'Project Name',
  工作地点: 'Work location',
  合同起始结束日期: 'Contract start and end date',
  基本单位薪资: 'Base Unit Salary',
  薪资币种: 'Salary Currency',
  薪资种类: 'Salary type',
  管理费支付方式: 'Management fee payment method',
  cc管理费: 'CC management fee',
  保险费支付方式: 'Insurance premium payment method',
  顾问保险费: 'Consultant Insurance Fee',
  顾问公司保险费: 'Recuitment company insurance premium',
  银行转账费支付方式: 'Bank transfer fee payment method',
  银行转账费: 'Bank transfer fee',
  本地发薪工资: 'Local salary',
  是否运用本地解决方案: 'Local Solution（ Yes / No）',
  本地管理费: 'Local management fee',
  本地扣减费: 'Local deduction fee',
  增扣提醒: 'Additional deduction reminder',
  年假剩余: 'Annual leave remaining',
  天: 'Day',
  已锁定: 'Locked',
  普通工时录入: 'Regular working hours input',
  加班工时录入: 'Overtime hours input',
  年假工时录入: 'Annual leave working hours input',
  录入: 'Enter',
  顾问工时: 'Consultant work hours',
  未查到当前顾问信息: 'No current consultant information found',
  '顾问工时（锁定后：月工时、月使用年假将不可调整）':
    'Consultant working hours (Once locked, monthly working hours and monthly annual leave cannot be adjusted)',
  '本地管理费率（%）': 'Local management rate (%)',
  'cc管理费率（%）': 'CC Management Rate (%)',
  '不需要-顾问公司支付': 'No need - Recuitment company to pay',
  '结束，不需要': 'End, no need for',
  '年假剩余天数为0，不可再增加！': 'The balance of annual leave is 0, cannot be applied',
  顾问信息: 'Consultant Information',
  正常: 'Normal',
  受益人: 'Beneficiaries',
  银行账户信息: 'Bank account information',
  个人参考号: 'Person reference number',
  紧急联系人: 'Emergency contact person',
  新增合同: 'New Contract',
  保险类型: 'Insurance type',
  姓名: 'Name',
  新建工作地址: 'New work address',
  新建家庭地址: 'New home address',
  新建地址: 'New Address',
  请选择地址: 'Please select the address',
  请填写隶属顾问: 'Please fill in the associated consultant',
  街道: 'Street',
  请填写街道: 'Please fill in the street',
  城市: 'City',
  请填写城市: 'Please fill in the city',
  '州/省': 'State/Province',
  '请填写州/省': 'Please fill in the state/province',
  邮编: 'Post Code',
  请填写邮编: 'Please fill in the post code',
  地址类型: 'Address type',
  修改工作地址: 'Modify work address',
  修改家庭地址: 'Modify home address',
  修改顾问信息: 'Modify consultant information',
  请填写用户名: 'Please fill in the username',
  是否恢复账号: 'Restore account',
  是否重置密码: 'Reset password',
  解绑成功: 'Unbind successful',
  提醒时间: 'Reminder time',
  提醒间隔天数: 'Reminder interval in days',
  请填写提醒间隔天数: 'Please fill in the reminder interval in days',
  请填写优先等级: 'Please fill in the priority level',
  完成: 'Complete',
  未完成: 'Incomplete',
  新合同: 'New Contract',
  修改合同: 'Modify Contract',
  浏览合同: 'View Contract',
  请选择当前合同负责人: 'Please select the current contract manager',
  是否当前合同: 'Current Contract',
  请填写合同起始结束日期: 'Please fill in the start and end dates of the contract',
  请填写本地发薪工资: 'Please fill in the local salary',
  请填写基本单位薪资: 'Please fill in the base unit salary',
  请填写住宿费率: 'Please fill in the accommodation rate',
  住宿费率: 'Accommodation rate',
  请填写cc管理费: 'Please fill in the CC management fee',
  请填写本地管理费: 'Please fill in the local management fee',
  顾问转账费: 'Consultant bank transfer fee',
  请填写顾问转账费: 'Please fill in the consultant bank transfer fee',
  请选择保险类型: 'Please select insurance type',
  提醒天数: 'Reminder Days',
  请填写提醒天数: 'Please fill in the reminder days',
  请选择终端用户: 'Please select the end user',
  男: 'Male',
  女: 'Female',
  加班费率: 'Overtime Rate',
  请填写加班费率: 'Please fill in the overtime rate',
  请选择工作地点: 'Please select a work location',
  请填写cc管理费率: 'Please fill in the CC management fee rate',
  请填写本地管理费率: 'Please fill in the local management fee rate',
  请填写银行转账费: 'Please fill in the bank transfer fee',
  顾问公司转账费: 'Recuitment company bank transfer fee',
  请填写顾问公司转账费: 'Please fill in the recuitment company transfer fee',
  合同补充条款: 'Supplementary clauses to the contract',
  请填写合同补充条款: 'Please fill in the supplementary clauses of the contract',
  请填写职务: 'Please fill in the position',
  合同发送抄送邮箱: 'Contract sending cc email',
  请选择合同发送抄送邮箱: 'Please select the email address for sending a cc to the contract',
  新建收件箱: 'New inbox',
  邮箱名称: 'Email Name',
  请填写邮箱名称: 'Please fill in the email name',
  邮箱地址: 'Email address',
  请填写邮箱地址: 'Please fill in your email address',
  请选择薪资种类: 'Please select salary type',
  个税代缴: 'Personal income tax deduction',
  请填写本地扣减费: 'Please fill in the local deduction fee',
  请填写顾问保险费: "Please fill in the consultant's insurance premium",
  全时约当数: 'FTE (Full Time Equivalent)',
  请填写全时约当数: 'Please fill in the FTE number',
  请填写项目名称: 'Please fill in the project name',
  请款抄送邮箱地址: 'Invoice cc email address',
  请选择顾问公司联系人: 'Please select the contact person for the recuitment company',
  请选择请款抄送邮箱地址: 'Please select the invoice cc email address',
  工时确认邮箱模板: 'Work hour confirmation email template',
  请选择工时确认邮箱模板: 'Please select the work hour confirmation email template',
  本地发薪数值: 'Local salary value',
  请填写本地发薪数值: 'Please fill in the local salary value',
  社保代缴: 'Social security payment deduction',
  本地发薪币种: 'Local salary currency',
  请选择本地发薪币种: 'Please select local salary currency',
  请选择管理费计算方式: 'Please select the management fee calculation method',
  管理费描述: 'Management fee description',
  请填写管理费描述: 'Please fill in the management fee description',
  年假天数: 'Annual leave days',
  请填写年假天数: 'Please fill in the number of annual leave days',
  请填写顾问公司保险费: 'Please fill in the insurance premium of the recuitment company',
  转为当前合同: 'Convert to current contract',
  是否转为当前合同: 'Whether to convert to current contract',
  上传小程序附件: 'Upload Mini Program Attachment',
  创建合同草稿: 'Create Contract Draft',
  确认创建合同草稿: 'Confirm creation of contract draft',
  请选择是否运用本地解决方案: 'Please choose whether to use local solutions',
  合同预览: 'Contract Preview',
  发送合同: 'Send Contract',
  没有可预览的合同: 'No contracts to preview',
  确认发送当前合同: 'Confirm sending current contract',
  手动发送合同: 'Manual sending contract',
  确认手动发送当前合同: 'Confirm manual sending of current contract',
  手动发送当前合同成功: 'Successfully sent current contract manually',
  三方合同上传确认: 'Confirmation of Tripartite Contract Upload',
  确认已上传三方合同: 'Confirm that the tripartite contract has been uploaded',
  确认已上传双方合同: 'Confirm that the contract has been uploaded',
  三方合同作废: 'Revocation of tripartite contract',
  确认双方合同上传成功: 'Confirm successful upload of contract',
  确认作废双方合同: 'Confirmation of invalidation of contract',
  签署合同: 'Signing contract',
  双方合同作废: 'The contract is invalid',
  确认签署合同: 'Confirm signing of contract',
  签署成功: 'Signed successfully',
  创建顾问全部工时: 'Create all work hours',
  确认创建顾问全部工时: 'Confirm creation of all work hours',
  创建顾问全部工时成功: 'Successfully created all work hours',
  更新顾问月工时: 'Update monthly work hours',
  工时月份: 'Work Month',
  请选择工时月份: 'Please select the working month',
  顾问当月工时已锁定: "Consultant's monthly work hours have been locked",
  终止合同: 'Termination of Contract',
  终止日期: 'End date',
  请选择终止日期: 'Please select the termination date',
  终止合同成功: 'Contract terminated successfully',
  删除合同终止日期: 'Delete contract termination date',
  是否删除合同终止日期: 'Whether to delete contract termination date',
  复制: 'Copy',
  复制新增合同: 'Copy new contract',
  '无当前合同，请新增': 'No current contract, please add ',
  '是否复制当前的合同？   如果放弃创建合同请选择【新建】，然后【取消】':
    'Copy the current contract? If you want to give up creating the contract, please select "New" and then "Cancel",',
  保险单号: 'Insurance policy number',
  请填写保险单号: 'Please fill in the insurance policy number',
  起保日期时间: 'Starting date and time of insurance',
  请填写起保日期时间: 'Please fill in the start date and time of insurance',
  停保日期时间: 'Insurance suspension date and time',
  请填写停保日期时间: 'Please fill in the date and time of suspension of insurance',
  当前保险: 'Current insurance',
  请填写当前保险: 'Please fill in the current insurance',
  新建保险: 'New Insurance',
  修改保险: 'Modify Insurance',
  保险管理: 'Insurance Management',
  保单: 'Insurance policy',
  其他附件: 'Other attachments',
  请填写起停保日期时间: 'Please fill in the start and end date and time of the Insurance',
  '起保-停保日期时间': 'Insurance start and end dates and times',
  新建受益人: 'New beneficiary',
  请选择受益人: 'Please select beneficiary',
  请填写姓名: 'Please fill in name',
  性别: 'Gender',
  请选择证件类别: 'Please select the document type',
  证件类别: 'Document type',
  请填写出生日期: 'Please fill in the date of birth',
  证件号: 'ID number',
  请填写证件号: 'Please fill in the ID number',
  请填写证件有效期: 'Please fill in the validity period of the document',
  证件有效期: 'Document validity period',
  请填输入姓名: 'Please enter name',
  请填输入性别: 'Please enter gender',
  请填输入出生日期: 'Please enter the date of birth',
  保单上传: 'Policy Upload',
  请填输入证件号: 'Please enter ID number',
  其他附件上传: 'Upload other attachments',
  '当前保险,不可删除': 'Current insurance, cannot be deleted',
  确认删除保险: 'Confirm deletion of insurance',
  是否复制当前的保险: 'Whether to copy current insurance',
  请填输入起停保日期时间: 'Please enter the start and end date and time of the insurance',
  请填输入当前保险: 'Please enter the current insurance',
  请填输入每月保费: 'Please enter the monthly premium',
  新增保险: 'New insurance',
  '无当前保险，请新增': 'No current insurance, please add ',
  本人: 'Me',
  配偶: 'Spouse',
  子女: 'Children',
  父母: 'Parent',
  修改受益人: 'Modify beneficiary',
  证件类别名: 'Document type',
  受益比例: 'Benefit ratio',
  与被保险人关系: 'Relationship with the insured',
  新增受益人: 'New beneficiary',
  请选择与被保险人关系: 'Please select the relationship with the insured',
  请填写受益比例: 'Please fill in the benefit ratio',
  修改银行账户: 'Modify bank account',
  请选择银行: 'Please select a bank',
  开户时向银行提供的住址: 'The address provided to the bank when opening the account',
  请填写开户时向银行提供的住址:
    'Please fill in the address provided to the bank when opening the account',
  请填输入银行名称: 'Please enter the bank name',
  请填输入账户持有人: 'Please enter the account holder',
  请填输入开户行地址: 'Please enter the account opening bank address',
  请填输入是否当前账号: 'Please enter whether it is a current account',
  月份: 'Month',
  月工时: 'Monthly work hours',
  当月使用年假: 'Annual leave used in the current month',
  年假余额: 'Annual leave balance',
  是否锁定: 'If locked',
  合同起始日期: 'Contract start date',
  合同结束日期: 'Contract end date',
  PDF生成日志: 'PDF generation log',
  锁定: 'Lock',
  锁定成功: 'Locked successfully',
  取消锁定: 'Unlock',
  是否取消锁定: 'Whether to unlock',
  取消锁定成功: 'Unlock successful',
  生成顾问月工时PDF: 'Generate Consultant Monthly Work Hours PDF',
  是否生成顾问月工时PDF: 'Whether to Generate Consultant Monthly Work Hours PDF',
  发送顾问月工时邮件: 'Send consultant monthly working hours email',
  是否发送顾问月工时邮件: "Whether to send the consultant's monthly working hours email",
  修改顾问工时: 'Modify Work Hours',
  请填写月份: 'Please fill in the month',
  当月工时数: 'Current month work hours',
  请填写当月工时数: "Please fill in the current month's work hours",
  当月年假天数: 'Annual leave days of the current month',
  请填写当月年假天数: 'Please fill in the number of annual leave days for the current month',
  请款单号: 'Invoice number',
  工号: 'Employee ID',
  状态: 'Status',
  报销已入账: 'Expense has been recorded',
  邮件发送日志: 'Email sending log',
  请款类型: 'Invoice type',
  邮件更新时间: 'Email update time',
  汇款凭证: 'Remittance',
  汇款凭证项金额: 'Remittance item amount',
  请款起始日期: 'Invoice start date',
  提交日期: 'Submission date',
  普通工时单位: 'Normal work hours unit',
  基本工资总额: 'Total basic salary',
  宿款单位: 'Accommodation unit',
  住宿总额: 'Total Accommodation Amount',
  加班单位: 'Overtime unit',
  加班总额: 'Total Overtime Amount',
  管理费: 'Management fee',
  保险费: 'Insurance premium',
  计算公式备注: 'Calculation Formula Remarks',
  '付款周期（天）': 'Payment cycle (days)',
  CC收款账户: 'CC receiving account',
  创建人: 'Creator',
  修改人: 'Modified by',
  预览: 'Preview',
  工时证明文件: 'Work hour proof file',
  是否生成请款单PDF: 'Whether to generate invoice PDF',
  生成请款单PDF成功: 'Successfully generated invoice PDF',
  新建工资单: 'New Payslip',
  双方合同没有确认上传: 'Contract has not been uploaded',
  新建垫付工资单: 'Create a new prepaid payslip',
  工时证明文件上传: 'Upload proof of work hours file',
  有工时证明文件一并发出: 'Send out work hour proof documents together',
  是否手动发送: 'Whether to manually send',
  是否发送: 'Whether to send',
  重新发送: 'Resend',
  是否重新发送: 'Whether to resend',
  重新发送成功: 'Resend successful',
  新增汇款凭证项: 'New remittance item',
  请选择汇款凭证: 'Please select remittance',
  请填描述: 'Please fill in the description',
  金额: 'Amount',
  请填总金额: 'Please fill in the total amount',
  修改请款单管理费: 'Modify invoice management fee',
  请填写请款单号: 'Please fill in the invoice number',
  请填写管理费: 'Please fill in the management fee',
  重新生成工资单: 'Regenerate Payslip',
  是否删除请款单: 'Whether to delete invoice',
  是否恢复请款单: 'Whether to restore invoice',
  新建请款单: 'New invoice',
  请款日期: 'Invoice date',
  请填写请款起始日期: 'Please fill in the start date of the invoice',
  请选择请款类型: 'Please select invoice type',
  请填写普通工时单位: 'Please fill in the regular work hours unit',
  请填写基本工资总额: 'Please fill in the total basic salary amount',
  请填写计算公式备注: 'Please fill in the calculation formula remarks',
  请选择合同: 'Please select the contract',
  请填写服务月份: 'Please fill in the service month',
  请填写加班单位: 'Please fill in the overtime unit',
  请填写加班总额: 'Please fill in the total overtime amount',
  CC银行收款账户: 'CC Bank Receiving Account',
  付款周期: 'Payment cycle',
  请填写付款周期: 'Please fill in the payment period',
  请填写保险费: 'Please fill in the insurance premium',
  请选择银行收款账户: 'Please select a bank receiving account',
  请填写宿款单位: 'Please fill in the accommodation unit',
  请填写住宿总额: 'Please fill in the total accommodation amount',
  请款回执是否发送顾问: 'Is the invoice copied to the consultant,',
  请选择CC银行收款账户: 'Please select CC bank receiving account',
  服务月份有误: 'Service month error',
  无对应合同: 'No corresponding contract',
  显示到工资单: 'Display on paylisp',
  请款单自定义项最多6个: 'Maximum of 6 custom items for invoice',
  自定义项达到最大数量: 'The maximum number has been reached',
  '当前已有请款单,是否创建另一个?':
    'There is currently an existing invoice, would you like to create another one?',
  '顾问无CC公司当前合同，请创建':
    'The consultant does not have a current contract with CC company. Please create one',
  '其他已删除相关联的汇款凭证项、工资单、等信息不会恢复':
    'Other deleted related remittance items, payslip, and other information will not be restored',
  '可能会删除相关联的汇款凭证项、工资单、等信息':
    'Related remittance items, payslip, and other information may be deleted,',
  已确认: 'Confirmed',
  未收到款项: 'Unreceived payment',
  收到款项: 'Received payment',
  应付款金额: 'Accounts payable amount',
  工资单银行流水: 'Payslip Bank Transaction',
  付款月份: 'Payment month',
  所属remittance: 'Belonging remittance',
  核验: 'Verification',
  CC付款账户: 'CC payment account',
  推荐费: 'Recommendation fee',
  合同薪资币种: 'Contract Salary Currency',
  合并付款状态: 'Merge payment status',
  待办未完成: 'To do item not completed',
  同下次薪资合并: 'Merge with next salary',
  暂且不付: 'Not to pay for now',
  分开支付: 'Separate payments',
  合并所有历史付款: 'Merge all historical payments',
  是否有效: 'Is it valid',
  有效: 'Valid',
  无效: 'Invalid',
  添加额外项: 'Add extra item',
  请填写推荐费: 'Please fill in the recommendation fee',
  请选择CC付款账户: 'Please select CC payment account',
  请选择合同薪资币种: 'Please select the contract salary currency',
  邮件是否自动发送: 'Is the email automatically sent?',
  请填写邮件发送时间: 'Please fill in the email sending time',
  '无对应合同，请创建!': 'There is no corresponding contract, please create it!',
  请选择付款币种: 'Please select payment currency',
  额外项描述: 'Additional item description',
  额外项值: 'Extra value',
  工资单自定义项最多9个: 'Up to 9 custom items for payslip',
  知道了: 'Got it',
  顾问公司名称: 'Recuitment company name',
  请款截止日期: 'Payment deadline',
  汇款金额: 'Remittance amount',
  工资单号: 'Payslip number',
  基本薪资单位: 'Basic salary unit',
  住宿单位: 'Accommodation unit',
  '工资单 # ': 'Payslip #',
  '请款单 # ': 'Invoice #',
  '本地管理费对应汇率（本地：合同）':
    'Exchange rate corresponding to local management fee (local: contract)',
  '邮件发送时间（n天后）': 'Email sending time (n days later)',
  '付款（银行流水）': 'Payment (bank transaction)',
  付款: 'Payment',
  请填输入名称: 'Please enter the name',
  收入: 'Income',
  支出: 'Expenditure',
  付款成功: 'Payment successful',
  取消付款: 'Cancel payment',
  是否取消付款: 'Whether to cancel payment',
  取消付款成功: 'Payment cancelled successfully',
  '0付款': '0 Payment',
  '0付款成功': '0 payment successful',
  是否生成工资单PDF: 'Generate Payslip PDF',
  生成工资单PDF成功: 'Successfully generated payslip PDF',
  是否发送邮件: 'Whether to send email',
  发送邮件成功: 'Email sent successfully',
  重新发送邮件: 'Resend Email',
  是否重新发送邮件: 'Whether to resend the email',
  重新发送邮件成功: 'Resend email successful',
  确认收到: 'Received',
  是否确认收到工资单: 'Whether received payslip',
  工资单确认成功: 'Payslip received',
  修改工资单管理费: 'Modify Payroll Management Fee',
  请选择合并付款状态: 'Please select the merged payment status',
  修改合并付款状态: 'Modify merged payment status',
  修改付款月份: 'Modify payment month',
  请填写付款月份: 'Please fill in the payment month',
  开始时间: 'Start time',
  截止时间: 'End time',
  修改个人参考号: 'Modify person reference number',
  '开始-截止时间': 'Start - End time',
  修改付款月份成功: 'Successfully modified payment month',
  请选择参考号类别: 'Please select reference number type',
  新增个人参考号: 'Add person reference number',
  新增成功: 'Successfully added',
  关系: 'Relationship',
  请填写关系: 'Please fill in the relationship',
  联系电话: 'Contact phone number',
  请填写联系电话: 'Please fill in the contact phone number',
  联系邮箱: 'Contact email',
  请填写联系邮箱: 'Please fill in the contact email',
  请填输入关系: 'Please fill in the input relationship',
  记录: 'Record',
  请填写记录对象ID: 'Please fill in the record object ID',
  记录对象ID: 'Record object ID',
  请选择记录类型: 'Please select record type',
  请填写记录: 'Please fill in the record',
  备注类型: 'Note type',
  请选择优先等级: 'Please select priority level',
  请填写提醒时间: 'Please fill in the reminder time',
  修改待办事项: 'Modify to-do list',
  请填输入标题: 'Please fill in the title',
  普通: 'Normal',
  紧急: 'Urgent',
  新建增扣提醒: 'New deduction reminder',
  请选择类型: 'Please select type',
  余额表: 'Balance Table',
  应付: 'Payable',
  总额: 'Total amount',
  可用余额: 'Available balance',
  真实余额: 'Real balance',
  时间区间: 'Time interval',
  请选择合同本地发薪币种: 'Please select the local salary currency for the contract',
  合同本地发薪币种: 'Contract local salary currency',
  工资单报表: 'Payslip report',
  银行账户信息管理: 'Bank account information management',
  请填写顾问工号: "Please fill in the consultant's employee number",
  是否未添加CC银行账户: 'Whether CC bank account not added',
  是否未添加币种: 'Whether currency not added',
  请填输入流水种类: 'Please enter the type of transaction',
  银行流水管理: 'Bank transaction management',
  修改银行流水: 'Modify bank transaction',
  无对应项: 'No corresponding item',
  合同管理: 'Contract Management',
  创建合同草稿成功: 'Successfully created contract draft',
  发送当前合同成功: 'Successfully sent current contract',
  受益人管理: 'Beneficiary management',
  新建汇款凭证: 'New remittance',
  修改汇款凭证: 'Modify remittance',
  汇款凭证项: 'Remittance item',
  批量创建工资单: 'Batch payslip creation',
  当前已生成的工资单不会重新创建: 'The current payslip will not be recreated',
  工资单批量创建成功: 'Payslip batch creation successful',
  未选择汇款凭证项: 'No remittance item selected',
  请选择需要创建工资单的汇款凭证项:
    'Please select the remittance item that needed for the creation of the payslip',
  汇款收到日期: 'Date remittance received on',
  请填写汇款收到日期: 'Please fill in the remittance receipt date',
  银行手续费流水: 'Bank fee transaction',
  请填写银行手续费流水: 'Please fill in the bank fee transaction',
  请填输入总金额: 'Please enter the total amount',
  添加手续费银行流水: 'Add bank transaction for handling fees',
  跳转工资单: 'Go to Payslip',
  汇款凭证项批量导入: 'Batch import of remittance items',
  全选: 'Select All',
  选中: 'Selected',
  请款单列表: 'List of invoices',
  未找到相关请款单: 'No relevant invoice found',
  查找请款单: 'Search for invoice',
  创建汇款凭证项: 'Create remittance item',
  合同信息: 'Contract Information',
  请款单总额: 'Total amount of invoice',
  汇款凭证名称: 'Remittance name',
  请款单状态: 'Invoice status',
  是否已关联汇款凭证项: 'Is there an associated remittance item',
  工资单展示: 'Show payslip',
  批量生成PDF: 'Batch Generate PDF',
  批量发送邮件: 'Batch sending of emails',
  查询请款单: 'Invoice search',
  PDF生成失败: 'PDF generation failed',
  确定生成PDF: 'OK to generate PDF',
  工资单批量生成PDF成功: 'Batch generation of PDF for payslip succeeded',
  汇款凭证项创建成功: 'Remittance item successfully created',
  PDF生成成功: 'PDF generated successfully',
  邮件发送成功: 'Email sent successfully',
  确定批量发送邮件: 'Confirm bulk email sending',
  批量发送邮件成功: 'Batch email successfully sent',
  是否删除工资单: 'Whether to delete payslip',
  修改工资单管理费成功: 'Successfully modified management fee on payslip',
  '无对应请款单，无法创建': 'No corresponding invoice, unable to create ',
  '汇款凭证项金额之和与选中的请款单总金额之和有出入，是否继续':
    'There is a discrepancy between the total amount of the remittance and the total amount of the selected invoice. Do you want to proceed?',
  '未选择，或选择多个': 'Not selected, or multiple selected,',
  '批量创建汇款凭证项/工资单': 'Batch creation of remittance items/payslip',
  '银行手续费是负数，是否继续创建':
    'The bank handling fee is negative. Do you want to procced to create?',
  汇款凭证项批量创建: 'Batch creation of remittance items',
  是否删除此项记录: 'Do you want to delete this record?',
  批量手动发邮件: 'Batch manual email sending',
  批量下载PDF: 'Batch Download PDF',
  请选择需要付款的工资单: 'Please select the payslip that needs to be paid',
  是否确认批量付款: 'Confirm bulk payment',
  请选择需要下载PDF的请款单: 'Please select the invoice that needs to be downloaded as a PDF',
  是否确认批量下载PDF: 'Confirm to bulk download PDF',
  请选择需要生成PDF的工资单: 'Please select the payslip that needs to generate a PDF',
  是否确认生成PDF: 'Whether to generate PDF',
  邮件发送失败: 'Sending email failed',
  请选择需要发送邮件的请款单: 'Please select the invoice that needs to be sent by email',
  是否确认发送邮件: 'Are you sure you want to send the email,',
  服务结束月份: 'Service end month',
  请填写服务结束月份: 'Please fill in the end month of the service',
  服务开始月份: 'Service start month',
  请填写服务开始月份: 'Please fill in the service start month',
  请选择银行账户: 'Please select a bank account',
  未创建汇款凭证项: 'No remittance voucher item created',
  请选择未创建汇款凭证项: 'Please select the item for which no remittance voucher has been created',
  全部数据: 'All data',
  请款单管理: 'Invoice Management',
  修改请款单: 'Modify invoice',
  查看备注: 'View Notes',
  备注记录: 'Note Record',
  修改请款单管理费成功: 'Successfully modified management fee on invoice',
  请款单批量导入: 'Invoice bulk import',
  可选顾问: 'Optional Consultant',
  未找到相关顾问: 'No relevant consultants found',
  查找顾问: 'Find consultant',
  合同日期: 'Contract date',
  银行转账支付方式: 'Bank transfer payment method',
  查询顾问: 'Find consultant',
  取消成功: 'Cancel successful',
  批量创建请款单: 'Batch creation of invoices',
  请款单批量创建: 'Batch creation of invoices',
  批量手动发送: 'Batch manual sending',
  批量创建成功: 'Batch creation successful',
  批量生成PDF成功: 'Batch PDF generation successful',
  批量发邮件成功: 'Batch email successfully sent',
  批量手动发邮件成功: 'Batch manual email successfully sent',
  '无可用合同，请添加': 'No available contracts, please add ',
  '没有CC银行账户，请添加': 'No CC Bank Account, please add ',
  '有误，请修改上传导入文件': 'Error, please modify the import file',
  '当前已有请款单,是否创建另一个':
    'There is currently an existing invoice, would you like to create another one?',
  '请款起始日期不能为空，请修改上传导入文件':
    'The start date of the invoice cannot be empty. Please modify the import file,',
  '服务月份不能为空，请修改上传导入文件':
    'The service month cannot be empty. Please modify the import file,',
  '该顾问无当前合同，请单独创建请款单':
    'The consultant does not have a current contract. Please create invoice individually',
  是否确认批量0付款: 'Confirm batch 0 payment',
  请选择需要下载PDF的工资单: 'Please select the payroll that needs to be downloaded as a PDF',
  工资单已经付款: 'Payroll already paid',
  是否确认将符合条件的工资单生成PDF: 'Are you sure to generate PDF for the eligible payslip?',
  请选择需要发送邮件的工资单: 'Please select the payslip that needs to be sent via email',
  请填写汇款凭证流水日期: 'Please fill in the remittance transaction date',
  汇款凭证流水日期: 'Remittance transaction date',
  工资单管理: 'Payslip Management',
  请款月份: 'Payment month',
  请款金额: 'Request amount',
  是否0付款: 'Whether to use 0 payment',
  请选择付款月份: 'Please select payment month',
  批量发邮件: 'Batch email sending',
  批量0付款: 'Batch 0 Payment',
  批量付款: 'Batch payment',
  地址管理: 'Address Management',
  修改地址: 'Modify address',
  紧急联系人管理: 'Emergency Contact Management',
  新建紧急联系人: 'New emergency contact',
  请填输入记录: 'Please fill in the input record',
  请填输入描述: 'Please fill in the description',
  修改紧急联系人: 'Modify emergency contact person',
  记录管理: 'Record Management',
  新建记录: 'New Record',
  修改记录: 'Modify Record',
  个人参考号管理: 'Person reference number management',
  新建个人参考号: 'New person reference number',
  社保菜单: 'Social Security Menu',
  入职日期: 'Date of Employment',
  起保月份: 'Starting month of insurance',
  缴纳城市: 'Payment City',
  户籍类型: 'registered residence registration type ',
  外地农村: 'Non local villiage',
  外地城镇: 'Non local city',
  本地农村: 'Local villiage',
  本地城镇: 'Local city',
  缴费项目: 'Payment Item',
  请选择户籍类型: 'Please select registered residence type',
  重要提示阅读记录: 'Important Notice Viewing Record',
  待办事项管理: 'To Do Management',
  待办事项批量导入: 'Batch import of to-do items',
  间隔天数: 'Interval days',
  待办事项批量导入预览: 'Preview of batch import to-do items',
  model标记: 'Model tag',
  请填写model_flag: 'Please fill in model flag',
  model名称: 'Model Name',
  请填写model名称: 'Please fill in the model name',
  请选择审批状态: 'Please select approval status',
  请选择是否可用: 'Please select whether it is available',
  微信小程序数据变更: 'WeChat Mini Program Data Change',
  是否审批通过: 'Approved',
  是否审批拒绝: 'Whether to reject',
  增扣提醒管理: 'Deduction reminder management',
  请选择顾问: 'Please select consultant',
  增扣提醒批量导入: 'Batch import of deduction reminder',
  待增扣提醒批量导入预览: 'Preview of batch import pending deduction reminder',
  时间范围: 'Time range',
  额外项异常数据: 'Extra item exception data',
  错误类型: 'Error type',
  额外项数值: 'Additional item value',
  额外项名称: 'Additional item name',
  额外项隶属顾问: 'Additional item belongs to consultant',
  额外项隶属工号: 'Additional item belongs to job number',
  权限管理: 'Permission Management',
  菜单管理: 'Menu Management',
  新建菜单: 'New Menu',
  表格斑马纹: 'Table Zebra Pattern',
  开: 'On',
  关: 'Off',
  PC端: 'PC end',
  菜单: 'Menu',
  树: 'Tree',
  路由: 'Routing',
  隶属组织列表: 'List of affiliated organizations',
  平台: 'Platform',
  索引: 'Index',
  是否菜单: 'YN menu',
  对应接口: 'Corresponding interface',
  目标组织: 'Target organization',
  请选择目标组织: 'Please select the target organization',
  菜单名称: 'Menu Name',
  请填写菜单名称: 'Please fill in the menu name',
  请选择状态: 'Please select status',
  请填写路由: 'Please fill in the route',
  请选择平台: 'Please select platform',
  菜单索引: 'Menu Index',
  请填写菜单索引: 'Please fill in the menu index',
  父级菜单: 'Parent menu',
  请选择父级菜单: 'Please select the parent menu',
  是否为菜单: 'Whether a menu',
  隶属组织: 'Affiliated organization',
  请选择组织: 'Please select organization',
  接口: 'Interface',
  菜单名称必须填写: 'The menu name must be filled in with ',
  新增菜单成功: 'New menu successfully added',
  修改菜单成功: 'Successfully modified menu',
  选择多个API接口: 'Select multiple API interfaces',
  接口名称: 'Interface Name',
  请填写接口名称: 'Please fill in the interface name',
  绝对路径url: 'Absolute path URL',
  接口管理: 'Interface Management',
  未绑定菜单的接口: 'Interface for unbound menu',
  路径: 'Path',
  是否需要授权: 'Do I need authorization,',
  请选择是否授权: 'Please choose whether to authorize',
  请填写接口路径: 'Please fill in the interface path',
  绝对路径: 'Absolute path',
  请输入接口名称: 'Please enter the interface name',
  请输入绝对路径: 'Please enter an absolute path',
  接口列表: 'Interface List',
  接口对应菜单: 'Interface corresponding menu',
  新建接口: 'New interface',
  设为需要授权: 'Set as requiring authorization',
  是否设为需要授权: 'Is it set to require authorization,',
  导入接口: 'Import Interface',
  导入api接口json文件: 'Import API interface JSON file',
  接口JSON: 'Interface JSON',
  请填写账号名称: 'Please fill in the account name',
  导入成功: 'Import successful',
  设为无需授权: 'Set as no authorization required',
  是否设为无需授权: 'Is it set as unauthorized,',
  角色描述: 'Role Description',
  请填写角色描述: 'Please fill in the role description',
  请填写角色标识: 'Please fill in the role identifier',
  角色标识: 'Role ID',
  角色名称: 'Role Name',
  请填写角色名称: 'Please fill in the role name',
  角色名称必须填写: 'Role name must be filled in with',
  角色管理: 'Role Management',
  新建角色: 'New Role',
  修改用户: 'Modify user',
  修改角色: 'Modify Role',
  修改权限: 'Modify permissions',
  是否确认删除: 'Are you sure you want to delete it,',
  请输入角色名称: 'Please enter the role name',
  请选择隶属组织: 'Please select the affiliated organization',
  创建角色: 'Create Role',
  删除角色成功: 'Successfully deleted role',
  恢复角色成功: 'Successfully restored role',
  新增角色成功: 'Successfully added role',
  修改角色成功: 'Successfully modified role',
  修改用户成功: 'User modification successful',
  修改用户角色: 'Modify user role',
  CC公司权限: 'CC Company Permissions',
  加载中: 'Loading',
  CC用户: 'CC User',
  请选择CC用户: 'Please select CC user',
  用户名称: 'User Name',
  请填写用户名称: 'Please fill in the user name',
  创建: 'Create',
  创建CC用户权限: 'Create CC User Permissions',
  修改CC用户权限: 'Modify CC User Permissions',
  人员组织: 'Personnel organization',
  用户管理: 'User Management',
  部门管理: 'Department Management',
  角色: 'Role',
  请选择角色: 'Please select a role',
  手机号: 'Phone number',
  请填手机号: 'Please fill in your phone number',
  真实姓名: 'Real name',
  请填真实姓名: 'Please fill in your real name',
  请填职务: 'Please fill in the position',
  电子邮箱: 'E-mail',
  请填电子邮箱: 'Please fill in your email address',
  所属部门: 'Department',
  请输入手机号: 'Please enter your phone number',
  请选择所属部门: 'Please select the department you belong to',
  请输入真实姓名: 'Please enter your real name',
  用户ID: 'User ID',
  '手机号（用户名）': 'Phone number (username)',
  '请填写手机号（用户名）': 'Please fill in your phone number (username)',
  部门: 'Department',
  新建部门: 'New Department',
  部门名称: 'Department Name',
  请填写部门名称: 'Please fill in the department name',
  隶属部门: 'Department',
  客服: 'Customer service',
  客服人员: 'Customer service representative',
  客服名称: 'Customer service name',
  请填写客服名称: 'Please fill in the customer service name',
  模板邮箱: 'Template email',
  领班: 'Team leader',
  离线: 'Offline',
  上班: 'Work',
  下班: 'Off duty',
  转客服: 'Transfer to customer service',
  接入: 'Access',
  分配: 'Allocation',
  发送图片: 'Send Image',
  发送链接: 'Send link',
  请输入: 'Please enter ',
  发送: 'Send',
  未接入: 'Not connected',
  我的: 'My',
  所有: 'All',
  历史: 'History',
  机器人: 'Robot',
  无更多信息加载: 'No more information to load',
  不可发送空白消息: 'Cannot send blank messages',
  当前不可发送链接: 'Currently unable to send link',
  当前账号无客服权限: 'Current account does not have customer service privileges',
  链接发送: 'Link sending',
  链接: 'Link',
  请填写链接内容: 'Please fill in the link content',
  当前不可发送图片: 'Currently unable to send images',
  图片发送: 'Image sending',
  确认关闭会话: 'Confirm closing session',
  在线客服: 'Online customer service',
  选择客服: 'Select customer service',
  离线客服: 'Offline customer service',
  转给: 'Transferred to',
  转客服成功: 'Successfully transferred to customer service',
  只可选择一位客服进行转接: 'Only one customer service representative can be selected for transfer',
  只可选择一位客服进行接入: 'Only one customer service representative can be selected for access',
  请选择一位客服进行转接: 'Please select a customer service representative for transfer',
  分配客服接入: 'Assign customer service access',
  分配成功: 'Allocation successful',
  确认接入会话: 'Confirm session access',
  接入成功: 'Access successful',
  '请写http/https开头的链接': 'Please write a link starting with http/https',
  '当前处在‘下班’状态，请上班后操作。':
    'Currently in off duty status, pleae take action after starting work',
  '点击左侧会话,开启聊天': 'Click on the left conversation to start a chat',
  你是想问如下问题吗: 'Are you asking the following question,',
  '未找到相关问题，请直接转人工': 'No related issues found, please transfer to manual service',
  已读: 'Read',
  未读: 'Unread',
  点击加载更多: 'Click to load more',
  请选择称谓: 'Please choose the title',
  名: 'First name',
  请填写名: 'Please fill in your first name',
  姓: 'Last name',
  请填写姓: 'Please fill in last name',
  请填写其他名字: 'Please fill in another name',
  请选择国籍: 'Please select nationality',
  请填写手机: 'Please fill in your phone number',
  请填写个人邮箱: 'Please fill in your personal email address',
  请填写工作邮箱: 'Please fill in your work email',
  请填写微信账号: 'Please fill in your WeChat account',
  请填写WhatsApp账号: 'Please fill in WhatsApp account',
  WhatsApp账号: 'WhatsApp account',
  请填写QQ账号: 'Please fill in QQ account',
  请选择个人类别: 'Please select person type',
  请选择家庭地址: 'Please select your home address',
  请选择工作地址: 'Please select a work address',
  请选择个人状态: 'Please select personal status',
  其他姓名: 'Other names',
  '请填写Skype ID': 'Please fill in Skype ID',
  选择隶属顾问: 'Select Affiliated Consultant',
  账户名: 'Account Name',
  项目主键: 'Project primary key',
  选择顾问: 'Select Consultant',
  在线: 'Online',
  无推送: 'No notification',
  客服未接入消息: 'Customer service not allocated message',
  未读消息: 'Unread message',
  新增顾问: 'New consultant',
  模糊搜索: 'Fuzzy search',
  全部公司: 'All companies',
  修改密码: 'Change password',
  切换用户: 'Switch users',
  退出登录: 'Log out',
  有: 'have',
  '条 额外项异常数据': 'Exception data for additional items',
  账号密码登录: 'Account password login',
  账户或密码错误: 'Account or password error',
  请输入账户: 'Please enter account',
  请输入密码: 'Please enter password',
  自动登录: 'Automatic login',
  请输入正确的手机号: 'Please enter the correct phone number',
  请输入验证码: 'Please enter the verification code',
  请输入帐户名或邮箱地址: 'Please enter your account name or email address',
  欢迎: 'Welcome',
  欢迎回来: 'Welcome back',
  登录过期: 'Login expired',
  请重新登录: 'Please log in again',
  服务器端错误: 'Server side error',
  选择银行: 'Choose bank',
  手动发送成功: 'Manual sent',
  默认只能上传第一个文件: 'By default, only the first file can be uploaded',
  版本号: 'Version',
  数据ID: 'Object Id',
  请填写数据ID: 'Please fill in Object Id',
  导出: 'Download',
  修改标签: 'Modify tag',

  顾问公司账号: 'Client account',
  新建顾问公司账号: 'New client account',
  修改顾问公司账号: 'Modify client account',
  登录账号: 'Username',
  请填写登录账号: 'Please fill in username',
  账号状态: 'Account status',
  账号提示邮件: 'Account reminder email',
  是否重置: 'Confirm to reset',
  账号密码: 'Account password',
  是否恢复账号可用: 'Confirm to reinstate account',
  禁用账号: 'Disabled account',
  最低管理费: 'Minimum management fee',
  请填写最低管理费: 'Please fill the minimum management fee',
  上传本地合同: 'Upload local contract',
  本地合同附件: 'Local contract attachment',
  阅读时间: 'Timestamp',
  '当前已有一个工资类型请款单,是否创建另一个?':
    'There is a salary type invoice for this month, are you sure to create another one?',
  '工资类型请款单已有2个，不可继续创建':
    'There are two scary type invoices for this month, no more can be created.',
  '已过期超60天，不可请款':
    'Contract has expired over 60 days ago, no invoice can be created, please contact the company directly.',
  添加: 'Add',
  网址: 'Website',
  请填写网址: 'Please fill in Website',
};
