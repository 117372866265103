
import { defineComponent, reactive, ref, watch } from 'vue';
import { message, notification } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  CheckOutlined,
  CloseCircleFilled,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { update_receipt } from '@/api/receipt-model';
import moment from 'moment';

export default defineComponent({
  name: 'UpdateReceiptModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const { t } = useI18n();
    const dataSource: any = ref([]);
    const amount = ref(props.obj.amount);
    const max_index = ref(0);
    const logo = ref(props.obj.cc_company__logo);
    watch(
      () => dataSource.value,
      () => {
        amount.value = 0;
        dataSource.value.forEach((item: any) => {
          if (item.amount) {
            amount.value += parseFloat(item.amount);
          }
        });
      },
      { deep: true },
    );
    if (props.obj.customfield_list.length > 0) {
      dataSource.value = props.obj.customfield_list.map((item: any, i: number) => {
        return {
          ...item,
          index: i + 1,
        };
      });
      max_index.value = dataSource.value.length;
    } else {
      dataSource.value.push({
        index: 1,
        service_month: moment(props.obj.service_month).format('YYYY-MM'),
        service: null,
        price: null,
        qty: null,
        amount: null,
      });
      max_index.value = dataSource.value.length;
    }
    const confirmLoading = ref<boolean>(false);
    const columns: any = reactive([
      {
        title: t('服务月份'),
        dataIndex: 'service_month',
        key: 'service_month',
        width: 150,
      },
      {
        title: t('服务描述'),
        dataIndex: 'service',
        key: 'service',
        width: '300px',
      },
      {
        title: t('价格'),
        dataIndex: 'price',
        key: 'price',
        width: '150px',
      },
      {
        title: t('数量'),
        dataIndex: 'qty',
        key: 'qty',
        width: '110px',
      },
      {
        title: t('金额'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        key: 'action',
        width: '80px',
      },
    ]);

    const handleCancel = () => {
      emit('cancel');
    };
    const handleOk = () => {
      const list: any = dataSource.value.filter((item: any) => {
        return item.amount > 0;
      });
      if (list.length == 0) {
        message.warn(t('请填写发票数据'));
        return;
      }
      confirmLoading.value = true;
      return new Promise(function (resolve) {
        update_receipt({
          org_id: current_org.id,
          receipt_id: props.obj.id,
          customfield_list: JSON.stringify(list),
          amount: amount.value,
        })
          .then((res: any) => {
            if (res.message) {
              message.error(res.message);
            } else {
              message.success(t('修改成功'));
            }
            emit('ok');
            resolve(null);
          })
          .finally(() => {
            confirmLoading.value = false;
          });
      });
    };
    const customHeaderCell = () => {
      return { style: { background: '#011F60', color: 'white' } };
    };
    const handleChangeCell = (e: any, key: any, record: any) => {
      console.log('handleChangeCell');
      record[key] = e.target.value;
      dataSource.value = [].concat(...dataSource.value);
    };
    const handleChangeCellNumber = (value: any, key: any, record: any) => {
      console.log('handleChangeCellNumber');
      record[key] = value;

      if (key == 'price' || key == 'qty') {
        record['amount'] = parseFloat((record['price'] * record['qty']).toFixed(2));
      }
      dataSource.value = [].concat(...dataSource.value);
    };
    const handleAddItem = () => {
      if (dataSource.value.length >= 10) {
        notification.error({
          message: t('提示'),
          description: t('自定义项达到最大数量'),
        });
        return;
      }
      dataSource.value.push({
        index: max_index.value + 1,
        service_month: moment(props.obj.service_month).format('YYYY-MM'),
        service: null,
        price: null,
        qty: null,
        amount: null,
      });
      dataSource.value = [].concat(...dataSource.value);
      max_index.value += 1;
    };
    const handleDeleteItem = (item_index: number) => {
      console.log('delete');
      dataSource.value = dataSource.value.filter(
        (item: any, index: number) => index !== item_index,
      );
      dataSource.value = [].concat(...dataSource.value);
    };
    return {
      message,
      t,
      amount,
      confirmLoading,
      handleCancel,
      handleOk,
      customHeaderCell,
      columns,
      dataSource,
      logo,
      handleChangeCell,
      handleChangeCellNumber,
      moment,
      handleDeleteItem,
      handleAddItem,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
    EditOutlined,
    CheckOutlined,
  },
});
