<template>
  <a-modal
    class="update-receipt-modal"
    centered="true"
    :maskClosable="false"
    width="1000px"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
    :cancelText="t('取消')"
    :okText="t('保存')"
    :confirmLoading="confirmLoading"
    :title="title"
  >
    <div class="top-content">
      <a-row :gutter="24">
        <a-col :span="12">
          <!--          <img src="@/assets/logo.png" style="width: 220px" alt="logo" />-->
          <img :src="logo" style="width: 220px" alt="logo" />
        </a-col>
        <a-col :span="12">
          <div style="text-align: right">
            <div>{{ obj.cc_company__name }}</div>
            <div>{{ obj.cc_company__address }}</div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <div style="text-align: right">
            <div>E: {{ obj.cc_company__email }}</div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <div>Invoice to:</div>
          <div style="font-size: 11px">{{ obj.customer__adviser__full_name }}</div>
        </a-col>
        <a-col :span="12">
          <div style="text-align: right">
            <div>W: {{ obj.cc_company__website }}</div>
          </div>
        </a-col>
      </a-row>

      <div class="invoice-text">INVOICE</div>
      <a-row :gutter="24" style="padding-bottom: 20px">
        <a-col :span="5"></a-col>
        <a-col :span="5"></a-col>
        <a-col :span="5">
          <div class="cell">
            <div>Invoice Date:</div>
            <div>{{ obj.receipt_date }}</div>
          </div>
        </a-col>
        <a-col :span="5">
          <div class="cell">
            <div>Payment Terms:</div>
            <div>{{ obj.cc_company__payment_term }} DAYS</div>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="cell">
            <div>Due Date:</div>
            <div>{{ obj.due_date }}</div>
          </div>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="5">
          <div class="cell">
            <div>Invoice No.:</div>
            <div>{{ obj.receipt_number }}</div>
          </div>
        </a-col>
        <a-col :span="5"></a-col>
        <a-col :span="5"></a-col>
        <a-col :span="5">
          <div class="cell">
            <div>Currency:</div>
            <div>{{ obj.currency__currency }}</div>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="cell">
            <div>Total Due:</div>
            <div>{{ obj.currency__symbol }} {{ amount.toFixed(2) }}</div>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="receipt-table">
      <a-button style="float: right" @click="handleAddItem">{{ t('添加') }}</a-button>
      <s-table
        :columns="columns"
        :data-source="dataSource"
        stripe
        size="small"
        :custom-header-cell="customHeaderCell"
        :pagination="false"
        :scroll="{ y: 400 }"
        :rowKey="'index'"
      >
        <template #bodyCell="{ column, index, text, record }">
          <template v-if="column.dataIndex == 'service_month'">
            <a-input
              :value="record[column.dataIndex]"
              @change="value => handleChangeCell(value, column.dataIndex, record)"
            />
          </template>
          <template v-else-if="column.dataIndex == 'service'">
            <a-input
              :value="record[column.dataIndex]"
              @change="value => handleChangeCell(value, column.dataIndex, record)"
            />
          </template>
          <template v-else-if="column.dataIndex == 'price'">
            <div style="display: flex">
              <div style="margin-top: 5px">{{ obj.currency__symbol }}</div>
              <a-input-number
                :value="record[column.dataIndex]"
                @change="value => handleChangeCellNumber(value, column.dataIndex, record)"
              />
            </div>
          </template>
          <template v-else-if="column.dataIndex == 'qty'">
            <a-input-number
              :value="record[column.dataIndex]"
              @change="value => handleChangeCellNumber(value, column.dataIndex, record)"
            />
          </template>
          <template v-else-if="column.dataIndex == 'amount'">
            {{ obj.currency__symbol }} {{ column.content ? column.content(record, text) : text }}
          </template>
          <template v-else-if="column.dataIndex == 'action'">
            <a @click="handleDeleteItem(index)">{{ t('删除') }}</a>
          </template>
          <template v-else>
            <span :style="column.color ? column.color(record, text) : null">
              {{ column.content ? column.content(record, text) : text }}
            </span>
          </template>
        </template>
      </s-table>
    </div>

    <div class="footer-content">
      <a-row>
        <a-col :span="12">
          <div class="bank-details-text">
            <a-typography class="typography-text">
              <a-typography-paragraph content="BANK DETAILS " />
              <a-typography-paragraph
                v-if="obj?.cc_bank_account_id"
                :content="` Bank Name: ${obj.cc_bank_account__account_name}`"
              />
              <a-typography-paragraph
                v-if="obj?.cc_bank_account__bank_address"
                :content="` Bank Address: ${obj?.cc_bank_account__bank_address}`"
              />
              <div v-for="item in obj.account_data_list">
                <a-typography-paragraph
                  :content="`Account Number (${item.currency}): ${item.account_number}`"
                />
              </div>

              <a-typography-paragraph
                v-if="obj?.cc_bank_account__account_name"
                :content="`Name of Account Holder: ${obj.cc_bank_account__account_name}`"
              />
              <a-typography-paragraph
                v-if="obj?.cc_bank_account__swift_code"
                :content="`SWIFT Code (Mandatory for all transfers): ${obj.cc_bank_account__swift_code}`"
              />
              <a-typography-paragraph
                v-if="obj?.cc_bank_account__sort_code"
                :content="`Sort Code: ${obj.cc_bank_account__sort_code}`"
              />
            </a-typography>
          </div>
        </a-col>
        <a-col :span="12">
          <a-card
            :title="`TOTAL ${obj.currency__symbol} ${amount.toFixed(2)}`"
            :headStyle="{ background: '#011F60', color: 'white' }"
            :bordered="false"
          >
            <div>REMARK</div>
            <div>{{ obj.remark }}</div>
          </a-card>
        </a-col>
      </a-row>
    </div>

    <div class="footer-text">
      <div>THANK YOU FOR YOUR BUSINESS</div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue';
import { message, notification } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  CheckOutlined,
  CloseCircleFilled,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { update_receipt } from '@/api/receipt-model';
import moment from 'moment';

export default defineComponent({
  name: 'UpdateReceiptModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const { t } = useI18n();
    const dataSource: any = ref([]);
    const amount = ref(props.obj.amount);
    const max_index = ref(0);
    const logo = ref(props.obj.cc_company__logo);
    watch(
      () => dataSource.value,
      () => {
        amount.value = 0;
        dataSource.value.forEach((item: any) => {
          if (item.amount) {
            amount.value += parseFloat(item.amount);
          }
        });
      },
      { deep: true },
    );
    if (props.obj.customfield_list.length > 0) {
      dataSource.value = props.obj.customfield_list.map((item: any, i: number) => {
        return {
          ...item,
          index: i + 1,
        };
      });
      max_index.value = dataSource.value.length;
    } else {
      dataSource.value.push({
        index: 1,
        service_month: moment(props.obj.service_month).format('YYYY-MM'),
        service: null,
        price: null,
        qty: null,
        amount: null,
      });
      max_index.value = dataSource.value.length;
    }
    const confirmLoading = ref<boolean>(false);
    const columns: any = reactive([
      {
        title: t('服务月份'),
        dataIndex: 'service_month',
        key: 'service_month',
        width: 150,
      },
      {
        title: t('服务描述'),
        dataIndex: 'service',
        key: 'service',
        width: '300px',
      },
      {
        title: t('价格'),
        dataIndex: 'price',
        key: 'price',
        width: '150px',
      },
      {
        title: t('数量'),
        dataIndex: 'qty',
        key: 'qty',
        width: '110px',
      },
      {
        title: t('金额'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        key: 'action',
        width: '80px',
      },
    ]);

    const handleCancel = () => {
      emit('cancel');
    };
    const handleOk = () => {
      const list: any = dataSource.value.filter((item: any) => {
        return item.amount > 0;
      });
      if (list.length == 0) {
        message.warn(t('请填写发票数据'));
        return;
      }
      confirmLoading.value = true;
      return new Promise(function (resolve) {
        update_receipt({
          org_id: current_org.id,
          receipt_id: props.obj.id,
          customfield_list: JSON.stringify(list),
          amount: amount.value,
        })
          .then((res: any) => {
            if (res.message) {
              message.error(res.message);
            } else {
              message.success(t('修改成功'));
            }
            emit('ok');
            resolve(null);
          })
          .finally(() => {
            confirmLoading.value = false;
          });
      });
    };
    const customHeaderCell = () => {
      return { style: { background: '#011F60', color: 'white' } };
    };
    const handleChangeCell = (e: any, key: any, record: any) => {
      console.log('handleChangeCell');
      record[key] = e.target.value;
      dataSource.value = [].concat(...dataSource.value);
    };
    const handleChangeCellNumber = (value: any, key: any, record: any) => {
      console.log('handleChangeCellNumber');
      record[key] = value;

      if (key == 'price' || key == 'qty') {
        record['amount'] = parseFloat((record['price'] * record['qty']).toFixed(2));
      }
      dataSource.value = [].concat(...dataSource.value);
    };
    const handleAddItem = () => {
      if (dataSource.value.length >= 10) {
        notification.error({
          message: t('提示'),
          description: t('自定义项达到最大数量'),
        });
        return;
      }
      dataSource.value.push({
        index: max_index.value + 1,
        service_month: moment(props.obj.service_month).format('YYYY-MM'),
        service: null,
        price: null,
        qty: null,
        amount: null,
      });
      dataSource.value = [].concat(...dataSource.value);
      max_index.value += 1;
    };
    const handleDeleteItem = (item_index: number) => {
      console.log('delete');
      dataSource.value = dataSource.value.filter(
        (item: any, index: number) => index !== item_index,
      );
      dataSource.value = [].concat(...dataSource.value);
    };
    return {
      message,
      t,
      amount,
      confirmLoading,
      handleCancel,
      handleOk,
      customHeaderCell,
      columns,
      dataSource,
      logo,
      handleChangeCell,
      handleChangeCellNumber,
      moment,
      handleDeleteItem,
      handleAddItem,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
    EditOutlined,
    CheckOutlined,
  },
});
</script>
<style>
.update-receipt-modal .ant-modal-content .ant-modal-body {
  max-height: 100vh;
  padding: 1px;
  overflow: auto;

  .top-content {
    margin: 30px 60px 30px 30px;

    .invoice-text {
      margin-top: 0;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 350;
      font-size: 40px;
      text-align: right;
    }

    .cell {
      padding-top: 10px;
      border-top: solid 1px black;
    }
  }

  .receipt-table {
    margin: 0 30px 20px 0;
  }

  .footer-content {
    margin: 0 30px 0 30px;

    .ant-card {
      .ant-card-head {
        height: 38px;
        min-height: 38px;
        text-align: center;

        .ant-card-head-title {
          padding: 8px 0;
        }
      }
    }

    .bank-details-text {
      margin: 10px 0 0 20px;
      border-left: solid 2px black;

      .typography-text {
        margin-left: 5px;

        .ant-typography {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-text {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }
}
</style>
