import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_receipt_type(params: Record<string, any>) {
  return request.post('/receipt/create_receipt_type', params);
}

export async function update_receipt_type(params: Record<string, any>) {
  return request.post('/receipt/update_receipt_type', params);
}

export async function delete_receipt_type(params: Record<string, any>) {
  return request.post('/receipt/delete_receipt_type', params);
}

export async function recover_receipt_type(params: Record<string, any>) {
  return request.post('/receipt/recover_receipt_type', params);
}

export async function create_receipt(params: Record<string, any>) {
  return request.post('/receipt/create_receipt', params);
}

export async function update_receipt(params: Record<string, any>) {
  return request.post('/receipt/update_receipt', params);
}

export async function delete_receipt(params: Record<string, any>) {
  return request.post('/receipt/delete_receipt', params);
}

export async function recover_receipt(params: Record<string, any>) {
  return request.post('/receipt/recover_receipt', params);
}

export async function create_receipt_contract(params: Record<string, any>) {
  return request.post('/receipt/create_receipt_contract', params);
}

export async function update_receipt_contract(params: Record<string, any>) {
  return request.post('/receipt/update_receipt_contract', params);
}

export async function delete_receipt_contract(params: Record<string, any>) {
  return request.post('/receipt/delete_receipt_contract', params);
}

export async function recover_receipt_contract(params: Record<string, any>) {
  return request.post('/receipt/recover_receipt_contract', params);
}

export async function create_receipt_contract_template(params: Record<string, any>) {
  return request.post('/receipt/create_receipt_contract_template', params);
}

export async function delete_receipt_contract_template(params: Record<string, any>) {
  return request.post('/receipt/delete_receipt_contract_template', params);
}

export async function recover_receipt_contract_template(params: Record<string, any>) {
  return request.post('/receipt/recover_receipt_contract_template', params);
}

export async function update_receipt_contract_template(params: Record<string, any>) {
  return request.post('/receipt/update_receipt_contract_template', params);
}

export async function upload_receipt_contract_files(params: Record<string, any>) {
  return request.post('/receipt/upload_receipt_contract_files', params);
}

export async function set_receipt_contract_upload_status(params: Record<string, any>) {
  return request.post('/receipt/set_receipt_contract_upload_status', params);
}

export async function cc_sign_receipt_contract(params: Record<string, any>) {
  return request.post('/receipt/cc_sign_receipt_contract', params);
}

export async function view_receipt_contract_pdf(params: Record<string, any>) {
  return request.post('/receipt/view_receipt_contract_pdf', params);
}

export async function send_receipt_contract_email(params: Record<string, any>) {
  return request.post('/receipt/send_receipt_contract_email', params);
}

export async function send_receipt_email(params: Record<string, any>) {
  return request.post('/receipt/send_receipt_email', params);
}

export async function upload_receipt_template_file(params: Record<string, any>) {
  return request.post('/receipt/upload_receipt_template_file', params);
}

export async function upload_receipt_contract_template_file(params: Record<string, any>) {
  return request.post('/receipt/upload_receipt_contract_template_file', params);
}

export async function recover_payment_record(params: Record<string, any>) {
  return request.post('/receipt/recover_payment_record', params);
}

export async function send_receipt_contract_email_by_manul(params: Record<string, any>) {
  return request.post('/receipt/send_receipt_contract_email_by_manul', params);
}

export async function update_payment_record(params: Record<string, any>) {
  return request.post('/receipt/update_payment_record', params);
}

export async function create_payment_record(params: Record<string, any>) {
  return request.post('/receipt/create_payment_record', params);
}

export async function delete_payment_record(params: Record<string, any>) {
  return request.post('/receipt/delete_payment_record', params);
}

export async function create_receipt_contract_pdf(params: Record<string, any>) {
  return request.post('/receipt/create_receipt_contract_pdf', params);
}

export async function create_customer(params: Record<string, any>) {
  return request.post('/receipt/create_customer', params);
}

export async function delete_customer(params: Record<string, any>) {
  return request.post('/receipt/delete_customer', params);
}

export async function recover_customer(params: Record<string, any>) {
  return request.post('/receipt/recover_customer', params);
}

export async function update_customer(params: Record<string, any>) {
  return request.post('/receipt/update_customer', params);
}

export async function create_receipt_template(params: Record<string, any>) {
  return request.post('/receipt/create_receipt_template', params);
}

export async function delete_receipt_template(params: Record<string, any>) {
  return request.post('/receipt/delete_receipt_template', params);
}

export async function recover_receipt_template(params: Record<string, any>) {
  return request.post('/receipt/recover_receipt_template', params);
}

export async function update_receipt_template(params: Record<string, any>) {
  return request.post('/receipt/update_receipt_template', params);
}

export async function upload_receipt_files(params: Record<string, any>) {
  return request.post('/receipt/upload_receipt_files', params);
}

export async function create_receipt_pdf(params: Record<string, any>) {
  return request.post('/receipt/create_receipt_pdf', params);
}

export async function delete_receipt_extra(params: Record<string, any>) {
  return request.post('/receipt/delete_receipt_extra', params);
}

export async function view_receipt_pdf(params: Record<string, any>) {
  return request.post('/receipt/view_receipt_pdf', params);
}

export async function query_receipt_type_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/receipt/query_receipt_type_list', params);
}

export async function query_payment_record_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/receipt/query_payment_record_list', params);
}

export async function query_receipt_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/query_receipt_list', params);
}

export async function query_receipt_contract_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/receipt/query_receipt_contract_list', params);
}

export async function query_receipt_contract_template_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/receipt/query_receipt_contract_template_list', params);
}

export async function query_customer_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/receipt/query_customer_list', params);
}

export async function query_receipt_template_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/receipt/query_receipt_template_list', params);
}

export async function create_receipt_paid_pdf(params: Record<string, any>) {
  return request.post('/receipt/create_receipt_paid_pdf', params);
}

export async function view_receipt_paid_pdf(params: Record<string, any>) {
  return request.post('/receipt/view_receipt_paid_pdf', params);
}

export async function send_receipt_payment_email(params: Record<string, any>) {
  return request.post('/receipt/send_receipt_payment_email', params);
}
