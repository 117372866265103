<template>
  <a-modal
    v-if="is_show"
    :maskClosable="false"
    width="1050px"
    :visible="visible"
    :closable="false"
    :confirmLoading="confirmLoading"
    :title="t('预览')"
  >
    <template #footer>
      <a-button style="margin-right: 3px" type="primary" @click="close">{{ t('知道了') }}</a-button>
    </template>
    <a-descriptions
      v-if="type == 'invoice'"
      size="small"
      :column="3"
      :title="t('请款单 # ') + obj.invoice_no"
    >
      <a-descriptions-item :label="t('顾问')">{{ obj.adviser__full_name }}</a-descriptions-item>
      <a-descriptions-item :label="t('服务月份')">{{ obj.service_month }}</a-descriptions-item>
      <!--      <a-descriptions-item label="请款单号">{{ obj.invoice_no }}</a-descriptions-item>-->
      <a-descriptions-item label=""></a-descriptions-item>

      <a-descriptions-item :label="t('请款类型')">
        {{
          obj.invoice_type === 1
            ? t('工资')
            : obj.invoice_type === 2
              ? t('报销')
              : obj.invoice_type === 3
                ? 'credit note'
                : obj.invoice_type === 4
                  ? t('加班')
                  : obj.invoice_type === 5
                    ? t('CC报销')
                    : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('顾问公司名称')">
        {{ obj.contract__recuitment_company__company_name }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('币种')">{{ obj.contract__currency__currency }}</a-descriptions-item>

      <a-descriptions-item :label="t('请款起始日期')">
        {{ getDate(obj.invoice_start_date) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('请款截止日期')">
        {{ getDate(obj.invoice_end_date) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('付款周期（天）')">{{ obj.payment_term }}</a-descriptions-item>

      <a-descriptions-item :label="t('合同起始日期')">
        {{ getDate(obj.contract__start_date) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('合同结束日期')">
        {{ getDate(obj.contract__end_date) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('状态')">
        {{
          obj.status === 1
            ? t('新创建')
            : obj.status === 2
              ? t('已发送')
              : obj.status === 3
                ? t('已收到请款')
                : obj.status === 4
                  ? t('已作废')
                  : obj.status === 5
                    ? t('生成工资单')
                    : obj.status === 6
                      ? t('生成PDF')
                      : obj.status === 7
                        ? t('垫付')
                        : ''
        }}
      </a-descriptions-item>
    </a-descriptions>
    <a-divider style="margin-bottom: 15px" v-if="type == 'invoice'" />
    <a-descriptions v-if="type == 'invoice'" size="small" :column="3">
      <a-descriptions-item :label="t('基本单位薪资')">{{ obj.contract__basic_rate }}</a-descriptions-item>
      <a-descriptions-item :label="t('普通工时单位')">{{ obj.work_unit }}</a-descriptions-item>
      <a-descriptions-item :label="t('基本工资总额')">{{ money(obj.basic_amount) }}</a-descriptions-item>
      <a-descriptions-item :label="t('加班费率')">{{ obj.overtime_rate }}</a-descriptions-item>
      <a-descriptions-item :label="t('加班单位')">
        {{ obj.overtime }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('加班总额')">{{ money(obj.overtime_amount) }}</a-descriptions-item>
      <a-descriptions-item :label="t('住宿费率')">{{ obj.contract__accommodation_rate }}</a-descriptions-item>
      <a-descriptions-item :label="t('宿款单位')">{{ obj.accommodation_unit }}</a-descriptions-item>
      <a-descriptions-item :label="t('住宿总额')">
        {{ money(obj.accommodation_amount) }}
      </a-descriptions-item>

      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('管理费')">{{ money(obj.management_fee) }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('保险费')">{{ money(obj.insurance_fee) }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('银行转账费')">
        {{ money(obj.bank_charge_cost) }}
      </a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('总金额')">{{ money(obj.total_amount) }}</a-descriptions-item>

      <a-descriptions-item :label="t('汇款金额')">
        {{ money(obj.remittance__amount) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('备注')">{{ obj.remark }}</a-descriptions-item>
      <a-descriptions-item
        v-for="obj in obj.customfield_list"
        :key="obj.custom_field_name"
        :label="obj.custom_field_name"
      >
        {{ obj.custom_field_value }}
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions
      v-if="type == 'payslip'"
      size="small"
      :column="3"
      :title="t('工资单 # ') + obj.payslip_no"
    >
      <a-descriptions-item :label="t('顾问')">{{ obj.adviser__full_name }}</a-descriptions-item>
      <a-descriptions-item :label="t('服务月份')">
        {{ getDate(obj.invoice__service_month) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('工资单号')">{{ obj.payslip_no }}</a-descriptions-item>
      <a-descriptions-item :label="t('请款类型')">
        {{
          obj.invoice__invoice_type === 1
            ? t('工资')
            : obj.invoice__invoice_type === 2
              ? t('报销')
              : obj.invoice__invoice_type === 3
                ? 'credit note'
                : obj.invoice__invoice_type === 4
                  ? t('加班')
                  : obj.invoice__invoice_type === 5
                    ? t('CC报销')
                    : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('币种')">
        {{ obj.invoice__contract__currency__currency }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('汇率')">{{ obj.invoice__exchange_rate }}</a-descriptions-item>
    </a-descriptions>
    <a-divider v-if="type == 'payslip'" style="margin-bottom: 15px" />
    <a-descriptions v-if="type == 'payslip'" size="small" :column="3">
      <a-descriptions-item :label="t('基本薪资单位')">
        {{ obj.invoice__contract__basic_rate }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('工时')">{{ obj.invoice__work_unit }}</a-descriptions-item>
      <a-descriptions-item :label="t('基本工资总额')">
        {{ money(obj.invoice__basic_amount) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('住宿费率')">
        {{ money(obj.invoice__contract__accommodation_rate) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('住宿单位')">
        {{ money(obj.invoice__accommodation_unit) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('住宿总额')">
        {{ money(obj.invoice__accommodation_amount) }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('加班费率')">{{ money(obj.invoice__overtime) }}</a-descriptions-item>
      <a-descriptions-item :label="t('加班单位')">
        {{ obj.invoice__contract__overtime_rate }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('加班总额')">
        {{ money(obj.invoice__overtime_amount) }}
      </a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('管理费')">{{ money(obj.management_fee) }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('银行转账费')">
        {{ money(obj.bank_charge_cost) }}
      </a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('保险费')">{{ money(obj.insurance_fee) }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('推荐费')">{{ money(obj.referral_fees) }}</a-descriptions-item>
      <a-descriptions-item :label="t('备注')">{{ obj.remark }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item :label="t('总金额')">{{ money(obj.total_amount) }}</a-descriptions-item>
      <a-descriptions-item
        v-for="obj in obj.customfield_list"
        :key="obj.custom_field_name"
        :label="obj.custom_field_name"
      >
        {{ obj.custom_field_value }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions v-if="type == 'contract'" size="small" :column="2">
      <a-descriptions-item :label="t('职务')">{{ obj.consultant }}</a-descriptions-item>
      <a-descriptions-item :label="t('顾问公司')">{{ obj.recuitment_company__company_name }}</a-descriptions-item>
      <a-descriptions-item :label="t('项目名称')">{{ obj.project_name }}</a-descriptions-item>
      <a-descriptions-item :label="t('顾问公司联系人')">{{ obj.recuitment_company_person__name }}</a-descriptions-item>
      <a-descriptions-item :label="t('工作地点')">{{ obj.work_location__name }}</a-descriptions-item>
      <a-descriptions-item :label="t('合同起始结束日期')">{{ getDate(obj.start_date) }}-{{
          getDate(obj.end_date)
        }}
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions v-if="type == 'contract'" size="small" :column="4">
      <a-descriptions-item :label="t('基本单位薪资')">{{ obj.basic_rate }}</a-descriptions-item>
      <a-descriptions-item :label="t('薪资种类')">
        {{
          obj.rate_type == 0 ? t('日薪') :
            obj.rate_type == 1 ? t('月薪') :
              obj.rate_type == 2 ? t('年薪') :
                obj.rate_type == 3 ? t('时薪') :
                  obj.rate_type == 4 ? t('周薪') : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('薪资币种')">{{ obj.currency__currency }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>

      <a-descriptions-item :label="t('管理费支付方式')">{{
          obj.management_fee_payment_flag == 0 ? t('顾问支付') :
            obj.management_fee_payment_flag == 1 ? t('顾问公司支付') : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('cc管理费')">{{ obj.cc_management_fee }}</a-descriptions-item>
      <a-descriptions-item :label="t('cc管理费率（%）')">{{ obj.cc_management_fee_rate }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>

      <a-descriptions-item :label="t('保险费支付方式')">{{
          obj.insurance_payment_flag == -1 ? t('无') :
            obj.insurance_payment_flag == 0 ? t('顾问支付') :
              obj.insurance_payment_flag == 1 ? t('顾问公司支付') : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('保险类型')">{{
          obj.insurance_type == 0 ? t('需要') :
            obj.insurance_type == 1 ? t('不需要–顾问在本地') :
              obj.insurance_type == 2 ? t('不需要–顾问自己有保单') :
                obj.insurance_type == 3 ? t('不需要–顾问公司保险') : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('顾问保险费')">{{ obj.insurance_fee }}</a-descriptions-item>
      <a-descriptions-item :label="t('顾问公司保险费')">{{ obj.c_insurance_fee }}</a-descriptions-item>

      <a-descriptions-item :label="t('银行转账费支付方式')">{{
          obj.is_bank_charge_payment ? t('顾问支付') : t('顾问公司支付')
        }}
      </a-descriptions-item>
      <a-descriptions-item :label="t('银行转账费')">{{ obj.bank_charge }}</a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>
      <a-descriptions-item label=""></a-descriptions-item>

      <a-descriptions-item :label="t('本地发薪工资')">{{ obj.local_management_fee_fixed }}</a-descriptions-item>
      <a-descriptions-item :label="t('本地管理费')">{{ obj.local_management_fee }}</a-descriptions-item>
      <a-descriptions-item :label="t('本地管理费率（%）')">{{ obj.local_management_fee_rate }}</a-descriptions-item>
      <a-descriptions-item :label="t('本地扣减费')">{{ obj.local_deduct_fee }}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions v-if="type == 'contract'" size="small" :column="1">
      <a-descriptions-item :label="t('备注')"><p style="white-space: pre-line">{{ obj.remark }}</p>
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons-vue';
import numerals from '@/utils/numeral';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PreviewModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const { t } = useI18n();
    const is_show = ref(true);
    const close = () => {
      is_show.value = false;
    };
    const getDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };

    const money = (money: any) => {
      return numerals.money(money);
    };
    return {
      money,
      getDate,
      close,
      t,
      is_show,
      message,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
  },
});
</script>
