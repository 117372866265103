import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { reactive, ref } from 'vue';
import {
  create_extra_field,
  get_cc_company_by_adviser_id,
  get_extra_month,
  update_extra_field,
} from '@/api/cerp-model';
import { message, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = ref([]);
      get_extra_month({
        org_id: current_org?.id,
        adviser_id: record.adviser_id,
        type: record.type,
        cc_company_id: record.cc_company_id,
      })
        .then((res: any) => {
          if (res.data.length > 0) {
            list.value = res.data;
          }else{
            notification.error({
              message: t('错误'),
              description:
                t('合同无可选月份'),
            });
          }
        });
      const editModal = reactive({
        visible: true,
        title: t('修改'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'radio',
              name: 'type',
              label: '类型',
              label_i18n: '类型',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'type',
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: 'CC公司',
              placeholder_i18n: '请选择CC公司',
              disabled: false,
              placeholder: '请选择CC公司',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'input',
              name: 'custom_field_name',
              label: '自定义项描述',
              label_i18n: '自定义项描述',
              placeholder_i18n: '请填写自定义项描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项描述',
            },
            {
              type: 'input',
              name: 'custom_field_value',
              label: '自定义项值',
              label_i18n: '自定义项值',
              placeholder_i18n: '请填写自定义项值',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项值',
            },
            {
              type: 'input',
              name: 'content',
              label: '内容',
              label_i18n: '内容',
              placeholder_i18n: '请填写内容',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写内容',
            },
            {
              type: 'monthpicker',
              name: 'month',
              label: '月份',
              label_i18n: '月份',
              placeholder_i18n: '请选择月份',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM',
              placeholder: '请选择月份',
              disabledTime: (date: any) => {
                return list.value.indexOf(date.format('YYYY-MM')) < 0;
              },
            },
            {
              type: 'radio',
              name: 'is_finished',
              label: '状态',
              label_i18n: '状态',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'is_finished',
            },
            {
              type: 'textarea',
              name: 'note',
              label: '备注',
              label_i18n: '备注',
              placeholder_i18n: '请填写备注',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: record.adviser_id,
            adviser__full_name: record.adviser__full_name,
            custom_field_name: record.custom_field_name,
            custom_field_value: record.custom_field_value,
            is_finished: record.is_finished,
            content: record.content,
            note: record.note,
            type: record.type,
            month: record.month,
            extra_field_id: record.id,
            cc_company_id: record.cc_company_id,
          }),
          watch: {
            adviser_id: (data: any) => {
              if(data.adviser_id && (data.type == 0 || data.type == 1)){
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                })
                  .then((res: any) => {
                    if(res.data.length > 0){
                      list.value = res.data;
                    }else{
                      notification.error({
                        message: t('错误'),
                        description:
                          t('合同无可选月份'),
                      });
                    }
                  });
              }
            },
            type: (data: any) => {
              if(data.adviser_id && (data.type == 0 || data.type == 1)){
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                })
                  .then((res: any) => {
                    if(res.data.length > 0){
                      list.value = res.data;
                    } else {
                      notification.error({
                        message: t('错误'),
                        description:
                          t('合同无可选月份'),
                      });
                    }
                  });
              }
            },
            cc_company_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                })
                  .then((res: any) => {
                    if (res.data.length > 0) {
                      list.value = res.data;
                    } else {
                      notification.error({
                        message: t('错误'),
                        description:
                          t('合同无可选月份'),
                      });
                    }
                  });
              }
            },
          }
        },
        options: {
          type: [
            { value: 0, name: t('请款单') },
            { value: 1, name: t('工资单') },
          ],
          is_finished: [
            { value: true, name: t('完成') },
            { value: false, name: t('未完成') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_extra_field({
              ...payload,
            })
              .then((res: any) => {
                Object.assign(record, res);
                message.success(t('修改成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '新建增扣提醒',
    clazz: 'primary',
    cb: (record: any, dataSource: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = ref([]);
      const company_list: any = ref([]);
      const editModal = reactive({
        visible: true,
        title: t('新建增扣提醒'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'radio',
              name: 'type',
              label: '类型',
              label_i18n: '类型',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'type',
            },
            {
              type: 'select',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: 'CC公司',
              placeholder_i18n: '请选择CC公司',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择CC公司',
              datasource: 'cc_company_id',
            },
            {
              type: 'radio',
              name: 'type1',
              label: '增扣类型',
              label_i18n: '增扣类型',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'type1',
            },
            {
              type: 'input',
              name: 'custom_field_name',
              label: '自定义项描述',
              label_i18n: '自定义项描述',
              placeholder_i18n: '请填写自定义项描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项描述',
            },
            {
              type: 'input',
              name: 'custom_field_value',
              label: '自定义项值',
              label_i18n: '自定义项值',
              placeholder_i18n: '请填写自定义项值',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项值',
            },
            {
              type: 'input',
              name: 'content',
              label: '内容',
              label_i18n: '内容',
              placeholder_i18n: '请填写内容',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写内容',
            },
            {
              type: 'monthpicker',
              name: 'month',
              label: '月份',
              label_i18n: '月份',
              placeholder_i18n: '请选择月份',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM',
              placeholder: '请选择月份',
              disabledTime: (date: any) => {
                return list.value.indexOf(date.format('YYYY-MM')) < 0;
              },
            },
            {
              type: 'textarea',
              name: 'note',
              label: '备注',
              label_i18n: '备注',
              placeholder_i18n: '请填写备注',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
            },
          ],
          rules: {
            type: [{ required: true, message: t('请选择类型') }],
            type1: [{ required: true, message: t('请选择增扣类型') }],
            custom_field_name: [{ required: true, message: t('请填写自定义项描述') }],
            custom_field_value: [{ required: true, message: t('请填写自定义项值') }],
            month: [{ required: true, message: t('请选择月份') }],
            cc_company_id: [{ required: true, message: t('请选择CC公司') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: record.id,
            adviser__full_name: record.name,
            custom_field_name: null,
            custom_field_value: null,
            is_finished: false,
            content: null,
            note: null,
            type: null,
            month: null,
            cc_company_id: null,
          }),
          watch: {
            adviser_id: (data: any) => {
              if(data.adviser_id && (data.type == 0 || data.type == 1)){
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                })
                  .then((res: any) => {
                    if(res.data.length > 0){
                      list.value = res.data;
                    }else{
                      notification.error({
                        message: t('错误'),
                        description:
                          t('合同无可选月份'),
                      });
                    }
                  });
              }
            },
            type: (data: any) => {
              if(data.adviser_id && (data.type == 0 || data.type == 1)){
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                })
                  .then((res: any) => {
                    if (res.data.length > 0) {
                      list.value = res.data;
                    } else {
                      notification.error({
                        message: t('错误'),
                        description:
                          t('合同无可选月份'),
                      });
                    }
                  });
                company_list.value.length = 0;
                get_cc_company_by_adviser_id({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    res.data.forEach((item: any) => {
                      company_list.value.push({
                        name: item.name,
                        value: item.id,
                      });
                    });
                  }
                });
              }
            },
            cc_company_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                })
                  .then((res: any) => {
                    if (res.data.length > 0) {
                      list.value = res.data;
                    } else {
                      notification.error({
                        message: t('错误'),
                        description:
                          t('合同无可选月份'),
                      });
                    }
                  });
              }
            },
            type1: (data: any) => {
              if (data.type1) {
                data.custom_field_value = '';
              } else {
                data.custom_field_value = '-';
              }
            },
          }
        },
        options: {
          type: [
            { value: 0, name: t('请款单') },
            { value: 1, name: t('工资单') },
          ],
          type1: [
            { value: 0, name: t('扣减') },
            { value: 1, name: t('增补') },
          ],
          cc_company_id: company_list.value,
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_extra_field({
              ...payload,
            })
              .then(() => {
                message.success(t('创建成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  // {
  //   name: '增扣提醒批量导入',
  //   clazz: 'primary',
  //   cb: (record: any) => {
  //     const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  //     const loading = ref(false);
  //     let n: any = 0;
  //     const editModal = reactive({
  //       visible: true,
  //       title: '增扣提醒批量导入',
  //       group: '增扣提醒',
  //       type: 'picture',
  //       component: 'upload-image-modal',
  //       loading: loading,
  //       ok: (payload: any) => {
  //         return new Promise(resolve => {
  //           loading.value = true;
  //
  //           import_extra_field({
  //             nsfile_id: payload[0],
  //             org_id: current_org?.id,
  //           })
  //             .then((res: any) => {
  //               if(res && res.err_list && res.err_list.length > 0){
  //                 res.err_list.map((item: any) => {
  //                   item.id = n++;
  //                 })
  //               }
  //               if(res && res.ok_list && res.ok_list.length > 0){
  //                 res.ok_list.map((item: any) => {
  //                   item.id = n++;
  //                 })
  //               }
  //               console.log('res',res)
  //               const editModal1 = reactive({
  //                 visible: true,
  //                 title: '待增扣提醒批量导入预览',
  //                 component: 'TodoeventModal',
  //                 column: [
  //                   {
  //                     title: '顾问',
  //                     width: 300,
  //                     dataIndex: 'adviser',
  //                     checked: true,
  //                   },
  //                   {
  //                     title: '自定义项描述',
  //                     width: 300,
  //                     dataIndex: 'custom_field_name',
  //                     checked: true,
  //                   },
  //                   {
  //                     title: '自定义项值',
  //                     width: 150,
  //                     dataIndex: 'custom_field_value',
  //                     checked: true,
  //                   },
  //                   {
  //                     title: '类型',
  //                     width: 150,
  //                     dataIndex: 'type',
  //                     checked: true,
  //                   },
  //                   {
  //                     title: '内容',
  //                     width: 300,
  //                     dataIndex: 'content',
  //                     checked: true,
  //                   },
  //                   {
  //                     title: '月份',
  //                     width: 120,
  //                     dataIndex: 'month',
  //                     checked: true,
  //                   },
  //                   {
  //                     title: '备注',
  //                     width: 200,
  //                     dataIndex: 'note',
  //                     checked: true,
  //                   },
  //                 ],
  //                 flag: 'extra_field',
  //                 obj: res,
  //                 ok: (payload: any) => {
  //                   return new Promise(resolve1 => {
  //                     message.success(t('添加成功'));
  //                     event_obj.emit('refresh');
  //                     resolve1(null);
  //                     resolve(null);
  //
  //                   });
  //                 },
  //               });
  //               modalBox(editModal1);
  //             })
  //             .finally(() => {
  //               loading.value = false;
  //             });
  //         });
  //       },
  //     });
  //     modalBox(editModal);
  //   },
  // },
];
