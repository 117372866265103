
import { computed, createVNode, defineComponent, onActivated, onDeactivated, reactive, ref, watch } from 'vue';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { TableColumn } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message, Modal } from 'ant-design-vue';
import getPageConfig from '@/components/page-model2/index';
import { Record } from '@/components/page-model/typing';
import { set_custom_data_by_flag } from '@/api/sys-model';
import { GET_CUSTOME_DATA_USER } from '@/store/modules/user/actions';
import moment from 'moment';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PageModel2',
  props: {
    modalType: {
      type: String,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    column_flag: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    rowkey: {
      type: String,
      required: false,
      default: () => 'id',
    },
    rowheight: {
      type: [String, Number],
      required:false,
    },
    virtual: {
      type: Boolean,
      default: () => false,
    },
    position: {
      type: Array,
      default: () => ['bottomRight'],
    },
    height: {
      type: Number,
      required: false,
      default: ()=>300,
    },
    height2: {
      type: Number,
      required: false,
      default: ()=>0,
    },
    is_show_all_column: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_show_action: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_show_excel: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_show_refresh: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    is_show_change_column: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    is_show_fullscreen: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    data_change_timeline: {
      type: Number,
      default: () => 0,
    },
    headerComponentObj: {
      type: Object,
      default: () => {},
    },
    is_pagination: {
      type: Boolean,
      default: () => true,
    },
    is_search: {
      type: Boolean,
      default: () => true,
    },
    is_show_toolbar: {
      type: Boolean,
      default: () => true,
    },
    is_custom_pagination: {
      type: Boolean,
      default: () => false,
    },
    is_month: {
      type: Boolean,
      default: () => false,
    },
    is_show_table_btn: {
      type: Boolean,
      default: () => false,
    },
    is_tooltip_show: {
      type: Boolean,
      default: () => true,
    },
    outer_columns: {
      type: Array || Boolean,
      default: () => null,
      required: false,
    },
  },
  emits: ['ok', 'onLoad', 'refresh'],
  setup(props, { emit }) {
    const confirmLoading = ref<boolean>(false);
    const store = useStore();
    const { t } = useI18n();
    const is_show_table = ref(true);
    const current_org = store.getters['user/current_org'];
    const custome_data_user = store.getters['user/custome_data_user'];
    const checkAll = ref<boolean>(false);
    const checkAction = ref<boolean>(false);
    const field = {
      column_flag: 'column_' + props.column_flag,
    };
    const filter_map: any = ref({});
    const sort_fields: any =ref([]) ;
    const selected_id = ref(0);
    const select_model: any = reactive({
      org_id: current_org?.id,
      work_month: moment().format('YYYY-MM-DD'),
    });
    const is_virtual = ref(false);
    const elSearch:any = ref();
    const elToolbar:any = ref();
    const sizeChangeCount = ref(0);
    let formModalUpdate = false; // 判断显示创建、修改
    const tableHeight = computed(()=>{
      if(sizeChangeCount.value>=0){
        if(props.height2==0){
          return props.height;
        }else{
          let h:number = props.height2;
          if(elSearch.value){
            h  -= elSearch.value.offsetHeight;
          }
          if(elToolbar.value){
            h  -= elToolbar.value.offsetHeight;
          }
          if(props.is_pagination){
            h  -= 60;
          }
          return h;
        }
      }
    });
    // onMounted(() => {
    //   window.onresize = function () {
    //     tableHeight.y = window.innerHeight - 280;
    //   };
    // });
    // 时间区间格式转化 数组-->字符串
    const getRangePicker = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string].join(',');
        }
      }
      return obj;
    };
    // 操作列 按钮分为下拉和普通
    const column_normal_btn_list: any = computed(() => {
      return btn_list?.filter((item: any) => item.type == undefined || item.type == 'btn');
    });
    const column_dropdown_btn_dict: any = computed(() => {
      const l = btn_list?.filter((item: any) => item.type == 'dropdown');
      if (l && l.length > 0) {
        return { dropdown_btn: l[0], btn_list: l.slice(1) };
      } else {
        return null;
      }
    });

    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      column_list_change,
      slotList,
      titleList,
      api_fields,
      btn_list,
      head_btn_list,
      event_obj,
      init_request,
      selectedRowKeys,
      rowSelection,
      headerComponent,
      expandedRowRender,
      expandedRowRenderComponent,
      customCell,
      customRow,
      customHeaderCell,
      processData,
      processColumn,
      pageSize,
    } = getPageConfig(props.modalType);

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }
    // event_obj?.on('refresh', () => {
    //   reload();
    //   emit('ok', null);
    // });
    const event_obj_refresh = () => {
      reload();
      emit('ok', null);
    };
    event_obj?.on('refresh', event_obj_refresh);

    onActivated(() => {
      reload();
      event_obj?.off('refresh', event_obj_refresh);
      // 先解监听，再监听，防止重复
      event_obj?.on('refresh', event_obj_refresh);
    });
    onDeactivated(() => {
      event_obj?.off('refresh', event_obj_refresh);
    });
    let tmpBaseColumns = null;
    if (custome_data_user && custome_data_user[field.column_flag]) {
      baseColumns.map((item: any, index: number) => {
        if (custome_data_user[field.column_flag][item.dataIndex]) {
          item.sort_num = index;
          item.sort_num = custome_data_user[field.column_flag][item.dataIndex]['index'];
          item.checked = custome_data_user[field.column_flag][item.dataIndex]['checked'];
        }
      });
      tmpBaseColumns = baseColumns.sort((a: any, b: any) => a.sort_num - b.sort_num);
    }
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      column_change,
      replace_column,
    } = useTableDynamicColumns(
      props.outer_columns ? props.outer_columns as TableColumn[] : baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
      field.column_flag,
      column_list_change,
    );

    watch(
      () => dynamicColumnItems,
      () => {
        const column_data_json: any = {};
        dynamicColumnItems.value.map((item, index) => {
          column_data_json[item.key] = { key: item.key, index: index, checked: item.checked };
        });

        set_custom_data_by_flag({
          org_id: current_org?.id,
          flag: field.column_flag,
          data: JSON.stringify(column_data_json),
        }).then(function () {
          store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, { org_id: Number(current_org?.id) });
        });
      },
      { deep: true },
    );

    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    // const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);

    const {
      stripe,
      reload: reload_data,
      setPageInfo,
      context: state,
    } = useFetchData(
      queryData,
      {
        current: 1,
        pageSize: pageSize?pageSize:50,
        tableSize: 'small', // 'default' | 'middle' | 'small'
        ...props.search,
        init_request: !(init_request === false),
        requestParams: {
          ...searchConfig.model,
          ...props.search.requestParams,
        },
      },
      {
        onLoad: (dataSource: any[]) => {
          emit('onLoad', dataSource);
          if (props.mode == 'radio' && state.dataSource.length > 0) {
            emit('ok', state.dataSource[0]);
            selected_id.value = state.dataSource[0][props.rowkey];
          }
          if (state.dataSource.length > 1000) {
            is_virtual.value = true;
          }
        },
      },
      processData,
      processColumn,
      replace_column,
    );
    const reload = () => {
      let is_ok = true;
      if (searchConfig.need_fields) {
        for (let k of searchConfig.need_fields) {
          if (state.requestParams) {
            if (state.requestParams[k] === null || state.requestParams[k] === undefined) {
              is_ok = false;
            }
          }
        }
      }
      if (is_ok) {
        reload_data();
      }
    };
    const custom = (record: any, index: any) => {
      if (customRow) {
        const res = customRow(record, index);
        if (res.onClick == undefined) {
          res.onClick = () => {
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          };
        } else {
          res.onClick = (event: any) => {
            res.onClick(event);
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          };
        }
        return res;
      } else {
        return {
          onClick: () => {
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          },
        };
      }
    };
    const setRowClassName = (record: any) => {
      return record[props.rowkey] === selected_id.value ? 'mytable clickRowStyl6' : ''; //赋予点击行样式
    };
    let sortFieldList:Array<string> = [];
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
      sortFieldList.length = 0;
      filter_map.value= {};
      sort_fields.value = [];
      for (let key in filters) {
        filter_map.value[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      if(sorter instanceof Array){
        sortFieldList = sortFieldList.filter((field:string)=>{
          return sorter.find((item:any)=>field==item.column.dataIndex)!=undefined;
        });
        sorter.map((item:any)=>{
          if(!sortFieldList.includes(item.column.dataIndex)){
            sortFieldList.push(item.column.dataIndex)
          }
        });
        sort_fields.value = sortFieldList.map((field:string)=>{
          if(sorter.find((item:any)=>field==item.column.dataIndex).order=='descend'){
            return `-${field}`;
          }else{
            return field;
          }
        });
      }else if(sorter != undefined && sorter.column != undefined){
        sortFieldList.length = 0;
        sortFieldList.push(sorter.column.dataIndex);

        if(sorter.order == 'ascend'){
          sort_fields.value.push(sorter.column.dataIndex);
        }
        if(sorter.order == 'descend'){
          sort_fields.value.push('-' + sorter.column.dataIndex);
        }
      }else{
        sortFieldList.length = 0;
      }
      setPageInfo({
        ...searchConfig.model,
        ...props.search.requestParams,
        ...select_model,
        current: pagination?.current,
        pageSize: props.is_custom_pagination ? select_model.pageSize : pagination?.pageSize,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
        ...filter_map.value,
        sort_fields: sort_fields.value,
      });
      reload();
    };
    const handleColumnShowChange = () => {
      setPageInfo({
        ...searchConfig.model,
        ...props.search.requestParams,
        ...select_model,
        current: state.current,
        pageSize: state.pageSize,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
        ...filter_map.value,
        sort_fields: sort_fields.value,
      });
      reload();
    };
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      Modal.confirm({
        title: () => t('是否删除此项记录?'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            removeData({
              org_id: current_org.id,
              ...getObjList(api_fields.delete, record),
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              state.dataSource = ([] as any).concat(state.dataSource);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      } else {
        updateData({
          ...data,
          org_id: current_org.id,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      }
    };
    const summaryColumns = computed(() => {
      return dynamicColumns.value
        .filter((item: any) => {
          if (item.checked === true) {
            return true;
          } else if (item.checked === undefined) {
            return true;
          }
          return false;
        })
        .map((item: any, index: number) => {
          const obj = { index: index, columnKey:item.dataIndex, summary: item.summary, color: item.color, content:item.content };
          return obj;
        });
    });
    watch(
      () => select_model,
      () => {
        setPageInfo({
          current: 1,
          ...props.search.requestParams,
          ...searchConfig.model,
          ...getRangePicker(api_fields.rangepicker, searchConfig.model),
          ...select_model,
        });
        reload();
      },
      { deep: true },
    );
    watch(
      () => props.search.requestParams,
      () => {
        setPageInfo({
          current: 1,
          ...searchConfig.model,
          ...getRangePicker(api_fields.rangepicker, searchConfig.model),
          ...props.search.requestParams,
          ...select_model,
        });
        reload();
      },
      { deep: true },
    );
    // 字段对应错误————>转化正确
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(0, 5);
      }
      return time;
    };

    // 列’默认‘ ’全部‘ 展示的切换
    let old_list: any[] = []; // 定义old_list记录默认数据
    const summaryColumnsShow = computed(() => {
      return dynamicColumns.value.filter(item => item.summary).length > 0;
    });
    return {
      getDateTime,
      getTime,
      getMonth,
      getDate,
      getRangePicker,
      column_change,
      columnAction,
      checkAll,
      column_dropdown_btn_dict,
      column_normal_btn_list,
      checkAction,
      slotList,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handleAdd,
      handlerOk,
      confirmLoading,
      currentRecord,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      handleColumnShowChange,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      api_fields,
      btn_list,
      head_btn_list,
      t,

      headerComponent,
      configState,
      select_model,
      update_column_org_config,
      switch_column_config,
      update_column_data,
      is_virtual,
      is_show_table,
      custom,
      customCell: customCell ? customCell : () => {},
      customHeaderCell: customHeaderCell ? customHeaderCell : () => {},
      setRowClassName,
      resizeColumn,
      expandedRowRender,
      expandedRowRenderComponent,
      summaryColumnsShow,
      summaryColumns,
      rowSelection: rowSelection ? rowSelection : null,
      selectedRowKeys: selectedRowKeys ? selectedRowKeys : null,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    DeleteOutlined,
    Draggable,
    DragContainer,
    FormModal,
  },
});
