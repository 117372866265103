import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_c_c_bank_accounts_c_c(params: Record<string, any>) {
  return request.post('/baseinfo/create_c_c_bank_accounts_c_c', params);
}

export async function update_c_c_bank_accounts_c_c(params: Record<string, any>) {
  return request.post('/baseinfo/update_c_c_bank_accounts_c_c', params);
}

export async function create_c_c_bank_number_curreny(params: Record<string, any>) {
  return request.post('/baseinfo/create_c_c_bank_number_curreny', params);
}

export async function recover_work_hour_template(params: Record<string, any>) {
  return request.post('/baseinfo/recover_work_hour_template', params);
}

export async function delete_work_hour_template(params: Record<string, any>) {
  return request.post('/baseinfo/delete_work_hour_template', params);
}

export async function update_work_hour_template(params: Record<string, any>) {
  return request.post('/baseinfo/update_work_hour_template', params);
}

export async function create_work_hour_template(params: Record<string, any>) {
  return request.post('/baseinfo/create_work_hour_template', params);
}

export async function get_work_hour_template_calendar(params: Record<string, any>) {
  return request.post('/baseinfo/get_work_hour_template_calendar', params);
}

export async function create_work_hour_template_month_data(params: Record<string, any>) {
  return request.post('/baseinfo/create_work_hour_template_month_data', params);
}

export async function update_work_hour_template_month_data(params: Record<string, any>) {
  return request.post('/baseinfo/update_work_hour_template_month_data', params);
}

export async function delete_work_hour_template_month_data(params: Record<string, any>) {
  return request.post('/baseinfo/delete_work_hour_template_month_data', params);
}

export async function query_work_hour_template_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_work_hour_template_list', params);
}

export async function query_insurance_type_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_insurance_type_list', params);
}

export async function create_insurance_type(params: Record<string, any>) {
  return request.post('/baseinfo/create_insurance_type', params);
}

export async function update_insurance_type(params: Record<string, any>) {
  return request.post('/baseinfo/update_insurance_type', params);
}

export async function delete_insurance_type(params: Record<string, any>) {
  return request.post('/baseinfo/delete_insurance_type', params);
}

export async function recover_insurance_type(params: Record<string, any>) {
  return request.post('/baseinfo/recover_insurance_type', params);
}

export async function delete_tip(params: Record<string, any>) {
  return request.post('/baseinfo/delete_tip', params);
}

export async function recover_tip(params: Record<string, any>) {
  return request.post('/baseinfo/recover_tip', params);
}

export async function create_tip(params: Record<string, any>) {
  return request.post('/baseinfo/create_tip', params);
}

export async function update_tip(params: Record<string, any>) {
  return request.post('/baseinfo/update_tip', params);
}

export async function create_to_do_event_priority(params: Record<string, any>) {
  return request.post('/baseinfo/create_to_do_event_priority', params);
}

export async function update_to_do_event_priority(params: Record<string, any>) {
  return request.post('/baseinfo/update_to_do_event_priority', params);
}

export async function delete_to_do_event_priority(params: Record<string, any>) {
  return request.post('/baseinfo/delete_to_do_event_priority', params);
}

export async function recover_to_do_event_priority(params: Record<string, any>) {
  return request.post('/baseinfo/recover_to_do_event_priority', params);
}

export async function delete_email_template_image(params: Record<string, any>) {
  return request.post('/baseinfo/delete_email_template_image', params);
}

export async function update_c_c_bank_number_curreny(params: Record<string, any>) {
  return request.post('/baseinfo/update_c_c_bank_number_curreny', params);
}

export async function delete_c_c_bank_number_curreny(params: Record<string, any>) {
  return request.post('/baseinfo/delete_c_c_bank_number_curreny', params);
}

export async function recover_c_c_bank_number_curreny(params: Record<string, any>) {
  return request.post('/baseinfo/recover_c_c_bank_number_curreny', params);
}

export async function create_email_address(params: Record<string, any>) {
  return request.post('/baseinfo/create_email_address', params);
}

export async function view_contract_template_pdf(params: Record<string, any>) {
  return request.post('/baseinfo/view_contract_template_pdf', params);
}

export async function update_email_address(params: Record<string, any>) {
  return request.post('/baseinfo/update_email_address', params);
}

export async function delete_email_address(params: Record<string, any>) {
  return request.post('/baseinfo/delete_email_address', params);
}

export async function recover_email_address(params: Record<string, any>) {
  return request.post('/baseinfo/recover_email_address', params);
}

export async function create_end_client(params: Record<string, any>) {
  return request.post('/baseinfo/create_end_client', params);
}

export async function view_contract_page(params: Record<string, any>) {
  return request.post('/baseinfo/view_contract_page', params);
}

export async function create_paymentmethod(params: Record<string, any>) {
  return request.post('/baseinfo/create_paymentmethod', params);
}

export async function update_paymentmethod(params: Record<string, any>) {
  return request.post('/baseinfo/update_paymentmethod', params);
}

export async function delete_paymentmethod(params: Record<string, any>) {
  return request.post('/baseinfo/delete_paymentmethod', params);
}

export async function recover_paymentmethod(params: Record<string, any>) {
  return request.post('/baseinfo/recover_paymentmethod', params);
}

export async function update_end_client(params: Record<string, any>) {
  return request.post('/baseinfo/update_end_client', params);
}

export async function view_contract_pdf(params: Record<string, any>) {
  return request.post('/baseinfo/view_contract_pdf', params);
}

export async function delete_end_client(params: Record<string, any>) {
  return request.post('/baseinfo/delete_end_client', params);
}

export async function recover_end_client(params: Record<string, any>) {
  return request.post('/baseinfo/recover_end_client', params);
}

export async function update_bank_manage(params: Record<string, any>) {
  return request.post('/baseinfo/update_bank_manage', params);
}

export async function create_notice(params: Record<string, any>) {
  return request.post('/baseinfo/create_notice', params);
}

export async function update_notice(params: Record<string, any>) {
  return request.post('/baseinfo/update_notice', params);
}

export async function update_notice_tags(params: Record<string, any>) {
  return request.post('/baseinfo/update_notice_tags', params);
}

export async function delete_notice(params: Record<string, any>) {
  return request.post('/baseinfo/delete_notice', params);
}

export async function recover_notice(params: Record<string, any>) {
  return request.post('/baseinfo/recover_notice', params);
}

export async function delete_bank_manage(params: Record<string, any>) {
  return request.post('/baseinfo/delete_bank_manage', params);
}

export async function get_exchange_rate(params: Record<string, any>) {
  return request.post('/baseinfo/get_exchange_rate', params);
}

export async function recover_bank_manage(params: Record<string, any>) {
  return request.post('/baseinfo/recover_bank_manage', params);
}

export async function create_bank_manage(params: Record<string, any>) {
  return request.post('/baseinfo/create_bank_manage', params);
}

export async function update_work_hour_template_month_holiday(params: Record<string, any>) {
  return request.post('/baseinfo/update_work_hour_template_month_holiday', params);
}

export async function delete_c_c_bank_accounts_c_c(params: Record<string, any>) {
  return request.post('/baseinfo/delete_c_c_bank_accounts_c_c', params);
}

export async function recover_c_c_bank_accounts_c_c(params: Record<string, any>) {
  return request.post('/baseinfo/recover_c_c_bank_accounts_c_c', params);
}

export async function query_c_c_bank_accounts_c_c_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_c_c_bank_accounts_c_c_list', params);
}

export async function query_tip_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_tip_list', params);
}

export async function query_tag_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_tag_list', params);
}

export async function query_notice_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_notice_list', params);
}

export async function query_to_do_event_priority_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_to_do_event_priority_list', params);
}

export async function create_country(params: Record<string, any>) {
  return request.post('/baseinfo/create_country', params);
}

export async function create_tag(params: Record<string, any>) {
  return request.post('/baseinfo/create_tag', params);
}

export async function update_tag(params: Record<string, any>) {
  return request.post('/baseinfo/update_tag', params);
}

export async function delete_tag(params: Record<string, any>) {
  return request.post('/baseinfo/delete_tag', params);
}

export async function recover_tag(params: Record<string, any>) {
  return request.post('/baseinfo/recover_tag', params);
}

export async function update_country(params: Record<string, any>) {
  return request.post('/baseinfo/update_country', params);
}

export async function create_contract_template(params: Record<string, any>) {
  return request.post('/baseinfo/create_contract_template', params);
}

export async function update_contract_template(params: Record<string, any>) {
  return request.post('/baseinfo/update_contract_template', params);
}

export async function delete_contract_template(params: Record<string, any>) {
  return request.post('/baseinfo/delete_contract_template', params);
}

export async function recover_contract_template(params: Record<string, any>) {
  return request.post('/baseinfo/recover_contract_template', params);
}

export async function delete_country(params: Record<string, any>) {
  return request.post('/baseinfo/delete_country', params);
}

export async function recover_country(params: Record<string, any>) {
  return request.post('/baseinfo/recover_country', params);
}

export async function recover_managementfeeformula(params: Record<string, any>) {
  return request.post('/baseinfo/recover_managementfeeformula', params);
}

export async function delete_managementfeeformula(params: Record<string, any>) {
  return request.post('/baseinfo/delete_managementfeeformula', params);
}

export async function update_managementfeeformula(params: Record<string, any>) {
  return request.post('/baseinfo/update_managementfeeformula', params);
}

export async function create_managementfeeformula(params: Record<string, any>) {
  return request.post('/baseinfo/create_managementfeeformula', params);
}

export async function recover_c_c_email(params: Record<string, any>) {
  return request.post('/baseinfo/recover_c_c_email', params);
}

export async function delete_c_c_email(params: Record<string, any>) {
  return request.post('/baseinfo/delete_c_c_email', params);
}

export async function update_c_c_email(params: Record<string, any>) {
  return request.post('/baseinfo/update_c_c_email', params);
}

export async function create_c_c_email(params: Record<string, any>) {
  return request.post('/baseinfo/create_c_c_email', params);
}

export async function recover_email_template(params: Record<string, any>) {
  return request.post('/baseinfo/recover_email_template', params);
}

export async function delete_email_template(params: Record<string, any>) {
  return request.post('/baseinfo/delete_email_template', params);
}

export async function update_email_template(params: Record<string, any>) {
  return request.post('/baseinfo/update_email_template', params);
}

export async function create_email_template(params: Record<string, any>) {
  return request.post('/baseinfo/create_email_template', params);
}

export async function delete_exchange_rate(params: Record<string, any>) {
  return request.post('/baseinfo/delete_exchange_rate', params);
}

export async function create_cc_company(params: Record<string, any>) {
  return request.post('/baseinfo/create_cc_company', params);
}

export async function update_cc_company(params: Record<string, any>) {
  return request.post('/baseinfo/update_cc_company', params);
}

export async function upload_cc_company_invoice_template(params: Record<string, any>) {
  return request.post('/baseinfo/upload_cc_company_invoice_template', params);
}

export async function upload_cc_company_payslip_template(params: Record<string, any>) {
  return request.post('/baseinfo/upload_cc_company_payslip_template', params);
}

export async function upload_cc_company_logo(params: Record<string, any>) {
  return request.post('/baseinfo/upload_cc_company_logo', params);
}

export async function recover_cc_company(params: Record<string, any>) {
  return request.post('/baseinfo/recover_cc_company', params);
}

export async function delete_cc_company(params: Record<string, any>) {
  return request.post('/baseinfo/delete_cc_company', params);
}

export async function create_person_to_company(params: Record<string, any>) {
  return request.post('/baseinfo/create_person_to_company', params);
}

export async function update_person_to_company(params: Record<string, any>) {
  return request.post('/baseinfo/update_person_to_company', params);
}

export async function delete_person_to_company(params: Record<string, any>) {
  return request.post('/baseinfo/delete_person_to_company', params);
}

export async function recover_person_to_company(params: Record<string, any>) {
  return request.post('/baseinfo/recover_person_to_company', params);
}

export async function query_person_to_company_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_person_to_company_list', params);
}

export async function update_c_c_company_template(params: Record<string, any>) {
  return request.post('/baseinfo/update_c_c_company_template', params);
}

export async function delete_c_c_company_template(params: Record<string, any>) {
  return request.post('/baseinfo/delete_c_c_company_template', params);
}

export async function recover_c_c_company_template(params: Record<string, any>) {
  return request.post('/baseinfo/recover_c_c_company_template', params);
}

export async function create_c_c_company_template(params: Record<string, any>) {
  return request.post('/baseinfo/create_c_c_company_template', params);
}

export async function update_exchange_rate(params: Record<string, any>) {
  return request.post('/baseinfo/update_exchange_rate', params);
}

export async function create_exchange_rate(params: Record<string, any>) {
  return request.post('/baseinfo/create_exchange_rate', params);
}

export async function query_c_c_bank_number_curreny_by_cc_account_list(
  params: Record<string, any>,
) {
  return request.post('/baseinfo/query_c_c_bank_number_curreny_by_cc_account_list', params);
}

export async function recover_exchange_rate(params: Record<string, any>) {
  return request.post('/baseinfo/recover_exchange_rate', params);
}

export async function query_country_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_country_by_select_list', params);
}

export async function query_exchange_rate_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_exchange_rate_list', params);
}

export async function query_c_c_bank_number_curreny_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_c_c_bank_number_curreny_list', params);
}

export async function query_bank_manage_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_bank_manage_list', params);
}

export async function query_c_c_company_template_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_c_c_company_template_list', params);
}

export async function query_cc_company_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_cc_company_list', params);
}

export async function query_email_address_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_email_address_list', params);
}

export async function query_end_client_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_end_client_list', params);
}

export async function query_country_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_country_list', params);
}

export async function query_contract_template_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_contract_template_list', params);
}

export async function query_paymentmethod_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_paymentmethod_list', params);
}

export async function query_process_tip_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_process_tip_list', params);
}

export async function query_managementfeeformula_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_managementfeeformula_list', params);
}

export async function query_c_c_email_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_c_c_email_list', params);
}

export async function query_email_template_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_email_template_list', params);
}

export async function create_id_type(params: Record<string, any>) {
  return request.post('/baseinfo/create_id_type', params);
}

export async function create_process_tip(params: Record<string, any>) {
  return request.post('/baseinfo/create_process_tip', params);
}

export async function update_process_tip(params: Record<string, any>) {
  return request.post('/baseinfo/update_process_tip', params);
}

export async function delete_process_tip(params: Record<string, any>) {
  return request.post('/baseinfo/delete_process_tip', params);
}

export async function recover_process_tip(params: Record<string, any>) {
  return request.post('/baseinfo/recover_process_tip', params);
}

export async function update_id_type(params: Record<string, any>) {
  return request.post('/baseinfo/update_id_type', params);
}

export async function delete_id_type(params: Record<string, any>) {
  return request.post('/baseinfo/delete_id_type', params);
}

export async function recover_id_type(params: Record<string, any>) {
  return request.post('/baseinfo/recover_id_type', params);
}

export async function query_id_type_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_id_type_by_select_list', params);
}

export async function query_id_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_id_type_list', params);
}

export async function create_insurance_company(params: Record<string, any>) {
  return request.post('/baseinfo/create_insurance_company', params);
}

export async function update_insurance_company(params: Record<string, any>) {
  return request.post('/baseinfo/update_insurance_company', params);
}

export async function delete_insurance_company(params: Record<string, any>) {
  return request.post('/baseinfo/delete_insurance_company', params);
}

export async function recover_insurance_company(params: Record<string, any>) {
  return request.post('/baseinfo/recover_insurance_company', params);
}

export async function query_insurance_company_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_insurance_company_by_select_list', params);
}

export async function query_insurance_company_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_insurance_company_list', params);
}

export async function create_invoice_type(params: Record<string, any>) {
  return request.post('/baseinfo/create_invoice_type', params);
}

export async function update_invoice_type(params: Record<string, any>) {
  return request.post('/baseinfo/update_invoice_type', params);
}

export async function delete_invoice_type(params: Record<string, any>) {
  return request.post('/baseinfo/delete_invoice_type', params);
}

export async function recover_invoice_type(params: Record<string, any>) {
  return request.post('/baseinfo/recover_invoice_type', params);
}

export async function query_invoice_type_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_invoice_type_by_select_list', params);
}

export async function query_invoice_type_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_invoice_type_list', params);
}

export async function create_note_type(params: Record<string, any>) {
  return request.post('/baseinfo/create_note_type', params);
}

export async function update_note_type(params: Record<string, any>) {
  return request.post('/baseinfo/update_note_type', params);
}

export async function delete_note_type(params: Record<string, any>) {
  return request.post('/baseinfo/delete_note_type', params);
}

export async function recover_note_type(params: Record<string, any>) {
  return request.post('/baseinfo/recover_note_type', params);
}

export async function query_note_type_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_note_type_by_select_list', params);
}

export async function query_note_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_note_type_list', params);
}

export async function create_person_type(params: Record<string, any>) {
  return request.post('/baseinfo/create_person_type', params);
}

export async function update_person_type(params: Record<string, any>) {
  return request.post('/baseinfo/update_person_type', params);
}

export async function delete_person_type(params: Record<string, any>) {
  return request.post('/baseinfo/delete_person_type', params);
}

export async function recover_person_type(params: Record<string, any>) {
  return request.post('/baseinfo/recover_person_type', params);
}

export async function query_person_type_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_person_type_by_select_list', params);
}

export async function query_person_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_person_type_list', params);
}

export async function create_recuitment_company(params: Record<string, any>) {
  return request.post('/baseinfo/create_recuitment_company', params);
}

export async function update_recuitment_company(params: Record<string, any>) {
  return request.post('/baseinfo/update_recuitment_company', params);
}

export async function delete_recuitment_company(params: Record<string, any>) {
  return request.post('/baseinfo/delete_recuitment_company', params);
}

export async function recover_recuitment_company(params: Record<string, any>) {
  return request.post('/baseinfo/recover_recuitment_company', params);
}

export async function query_recuitment_company_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_recuitment_company_list', params);
}

export async function create_reference_number_type(params: Record<string, any>) {
  return request.post('/baseinfo/create_reference_number_type', params);
}

export async function update_reference_number_type(params: Record<string, any>) {
  return request.post('/baseinfo/update_reference_number_type', params);
}

export async function delete_reference_number_type(params: Record<string, any>) {
  return request.post('/baseinfo/delete_reference_number_type', params);
}

export async function recover_reference_number_type(params: Record<string, any>) {
  return request.post('/baseinfo/recover_reference_number_type', params);
}

export async function query_reference_number_type_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_reference_number_type_by_select_list', params);
}

export async function query_reference_number_type_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_reference_number_type_list', params);
}

export async function create_status(params: Record<string, any>) {
  return request.post('/baseinfo/create_status', params);
}

export async function update_status(params: Record<string, any>) {
  return request.post('/baseinfo/update_status', params);
}

export async function delete_status(params: Record<string, any>) {
  return request.post('/baseinfo/delete_status', params);
}

export async function recover_status(params: Record<string, any>) {
  return request.post('/baseinfo/recover_status', params);
}

export async function query_status_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_status_by_select_list', params);
}

export async function query_status_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_status_list', params);
}

export async function query_recuitment_company_person_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_recuitment_company_person_list', params);
}

export async function create_recuitment_company_person(params: Record<string, any>) {
  return request.post('/baseinfo/create_recuitment_company_person', params);
}

export async function update_recuitment_company_person(params: Record<string, any>) {
  return request.post('/baseinfo/update_recuitment_company_person', params);
}

export async function delete_recuitment_company_person(params: Record<string, any>) {
  return request.post('/baseinfo/delete_recuitment_company_person', params);
}

export async function recover_recuitment_company_person(params: Record<string, any>) {
  return request.post('/baseinfo/recover_recuitment_company_person', params);
}

export async function query_recuitment_company_person_by_select_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_recuitment_company_person_by_select_list', params);
}

export async function create_question_group(params: Record<string, any>) {
  return request.post('/baseinfo/create_question_group', params);
}

export async function update_question_group(params: Record<string, any>) {
  return request.post('/baseinfo/update_question_group', params);
}

export async function delete_question_group(params: Record<string, any>) {
  return request.post('/baseinfo/delete_question_group', params);
}

export async function recover_question_group(params: Record<string, any>) {
  return request.post('/baseinfo/recover_question_group', params);
}

export async function query_question_group_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/baseinfo/query_question_group_list', params);
}

export async function create_question(params: Record<string, any>) {
  return request.post('/baseinfo/create_question', params);
}

export async function update_question(params: Record<string, any>) {
  return request.post('/baseinfo/update_question', params);
}

export async function delete_question(params: Record<string, any>) {
  return request.post('/baseinfo/delete_question', params);
}

export async function recover_question(params: Record<string, any>) {
  return request.post('/baseinfo/recover_question', params);
}

export async function query_question_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/baseinfo/query_question_list', params);
}

export async function create_recuitment_company_user(params: Record<string, any>) {
  return request.post('/baseinfo/create_recuitment_company_user', params);
}

export async function update_recuitment_company_user(params: Record<string, any>) {
  return request.post('/baseinfo/update_recuitment_company_user', params);
}

export async function recover_recuitment_company_user(params: Record<string, any>) {
  return request.post('/baseinfo/recover_recuitment_company_user', params);
}

export async function delete_recuitment_company_user(params: Record<string, any>) {
  return request.post('/baseinfo/delete_recuitment_company_user', params);
}

export async function create_recuitment_company_user_account(params: Record<string, any>) {
  return request.post('/baseinfo/create_recuitment_company_user_account', params);
}

export async function send_recuitment_company_user_account_email(params: Record<string, any>) {
  return request.post('/baseinfo/send_recuitment_company_user_account_email', params);
}

export async function delete_recuitment_company_user_account(params: Record<string, any>) {
  return request.post('/baseinfo/delete_recuitment_company_user_account', params);
}

export async function reset_recuitment_company_account_password(params: Record<string, any>) {
  return request.post('/baseinfo/reset_recuitment_company_account_password', params);
}

export async function retrieve_recuitment_company_account_password(params: Record<string, any>) {
  return request.post('/baseinfo/retrieve_recuitment_company_account_password', params);
}

export async function disable_recuitment_company_account(params: Record<string, any>) {
  return request.post('/baseinfo/disable_recuitment_company_account', params);
}

export async function enable_recuitment_company_account(params: Record<string, any>) {
  return request.post('/baseinfo/enable_recuitment_company_account', params);
}

export async function get_recuitment_company_account_of_pc_login(params: Record<string, any>) {
  return request.post('/baseinfo/get_recuitment_company_account_of_pc_login', params);
}

export async function query_recuitment_company_user_list(params: Record<string, any>) {
  return request.post('/baseinfo/query_recuitment_company_user_list', params);
}

export async function get_cc_bank_account(params: Record<string, any>) {
  return request.post('/baseinfo/get_cc_bank_account', params);
}
